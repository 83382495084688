export interface ENV {
  API_KEY_ENDPOINT: string;
  API_KEY_UPLOAD_ENDPOINT: string;
  API_KEY_AUTH_ENDPOINT: string;
  API_KEY_PROJECT_ENDPOINT: string;
  AUTH_KEY_ENDPOINT: string;
  NODE_ENV: string;
  API_KEY_GOOGLE_PLACE_ENDPOINT: string;
}

export const envConfig: ENV = {
  API_KEY_ENDPOINT: process.env.REACT_APP_APIKEY_ENDPOINT ?? "",
  API_KEY_UPLOAD_ENDPOINT: process.env.REACT_APP_APIKEY_UPLOAD_ENDPOINT ?? "",
  API_KEY_AUTH_ENDPOINT: process.env.REACT_APP_APIKEY_AUTH_ENDPOINT ?? "",
  API_KEY_GOOGLE_PLACE_ENDPOINT:
    process.env.REACT_APP_APIKEY_GOOGLE_PLACE_ENDPOINT ?? "",
  API_KEY_PROJECT_ENDPOINT: process.env.REACT_APP_APIKEY_PROJECT_ENDPOINT ?? "",
  AUTH_KEY_ENDPOINT: process.env.REACT_APP_AUTHKEY_ENDPOINT ?? "",
  NODE_ENV: process.env.REACT_APP_CUSTOM_NODE_ENV ?? "",
};
