import { EPlans, Roles } from "../../models/common/models.enum";
import {
  EUserType,
  IActionClearUser,
  IActionGetUser,
  IActionSetUserState,
  IActionUser,
  IReduxUserState
} from "../../models/redux/user";
import { IUser } from "../../models/user";

export const setUserState = (data: IUser): IActionSetUserState => ({
  type: EUserType.SET_USER_STATE,
  payload: { data }
});

export const getUser = (): IActionGetUser => ({
  type: EUserType.GET_USER,
  payload: {}
});

export const clearUser = (): IActionClearUser => ({
  type: EUserType.CLEAR_USER,
  payload: {}
});

const defaultState: IReduxUserState = {
  data: {
    userId: "",
    phoneNumbers: "",
    email: "",
    companyName: "",
    isVerify: false,
    status: false,
    role: Roles.Customer,
    avatar: null,
    planPackage: EPlans.NONE
  }
};

export default function userReducer(
  state: IReduxUserState = defaultState,
  action: IActionUser
): IReduxUserState {
  switch (action.type) {
    case EUserType.SET_USER_STATE:
      return {
        data: action.payload.data
      };
    case EUserType.CLEAR_USER:
      return {
        data: defaultState.data
      };
    default:
      return state;
  }
}
