import { Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  FullWidthTabsTime,
  ImageCustom,
} from "../../../../../../../components";
import { IValue } from "../../../../../../../models/common/models.type";
import OverviewCommonCaption from "../../../../OverviewCommonCaption";
import OverviewCommonSubTitle from "../../../../OverviewCommonSubTitle";
import { TechnicalContentsWrapper } from "./styles";
import IconInfor from "../../../../../../../assets/images/domai-audit/overview-section/icon_infor_fill_grey.svg";
import TechnicalOverviewTab from "../TechnicalTab/TechnicalOverviewTab";
import TechnicalIssueTab from "../TechnicalTab/TechnicalIssueTab";
import moment from "moment";
import { DATETIME_FORMAT } from "../../../../../../../config/constants";
import CommonHandle from "../../../../../../../common/handles/common.handles";
import { useSelector } from "react-redux";
import { IStateApp } from "../../../../../../../models/redux";
import { IGetReportsRes } from "../../../../../../../models/report";
import { ISEOErrorSummaryRes } from "../../../../../../../models/seo";
import {
  IGetHeadingErrorRes,
  IGetImageErrorRes,
  IGetInformationAbilityRes,
} from "../../../../../../../models/audit-report";
import AuditReportService from "../../../../../../../services/api/audit.report.service";
import { ESectionOfAuditReport } from "../../../../../../../models/common/models.enum";

interface IDataClassify extends IValue {
  infor: string;
  keyScroll: string;
}

export default function TechnicalContents() {
  const myRef = useRef<any>();

  const dataReport = useSelector<IStateApp, IGetReportsRes>(
    appState => appState.project?.dataReport
  );

  const [technicalActiveTab, setTechnicalActiveTab] = useState<number>(0);
  const [key, setKey] = useState<string | null>(null);
  const [scores, setScores] = useState<{
    error: number;
    warning: number;
    notes: number;
  }>({ error: 0, warning: 0, notes: 0 });
  const [resDataSummary, setResDataSummary] =
    useState<ISEOErrorSummaryRes | null>(null);
  const [resDataHeading, setResDataHeading] =
    useState<IGetHeadingErrorRes | null>(null);
  const [resDataImage, setResDataImage] = useState<IGetImageErrorRes[] | null>(
    null
  );
  const [resDataAbility, setResDataAbility] =
    useState<IGetInformationAbilityRes | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingAbility, setIsLoadingAbility] = useState<boolean>(false);

  const handleNavigationIssue = (key: string) => {
    setTechnicalActiveTab(1);
    setKey(key);
    let timer1 = setTimeout(() => myRef.current?.childFunction(), 500);
    return () => {
      clearTimeout(timer1);
    };
  };

  useEffect(() => {
    getDataOfPage();
  }, [dataReport.thirdPartyId]);

  const getDataOfPage = async () => {
    if (!!dataReport.thirdPartyId) {
      setIsLoading(true);
      setIsLoadingAbility(true);
      const res = await AuditReportService.getSEOErrorSummary(
        dataReport.thirdPartyId
      );

      const resHeading = await AuditReportService.getAuditReportHeadingError(
        dataReport.thirdPartyId
      );
      const params = {
        limit: 30,
        id: dataReport.thirdPartyId,
        filters: [
          {
            key: "resource_type",
            value: "image ",
            operator: "=",
            type: "field",
          },
          {
            key: "",
            value: "and",
            operator: "",
            type: "operator",
          },
          {
            key: "meta.alternative_text",
            value: "",
            operator: "=",
            type: "field",
          },
        ],
      };
      const resImage = await AuditReportService.getDomainAuditResources(params);

      if (!res.isError && !!res.data.tasks[0].result) {
        setResDataSummary(res.data.tasks[0].result[0]);
        setIsLoading(false);
      } else if (resDataSummary !== null) {
        setResDataSummary(null);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
      if (!resHeading.isError && !!resHeading.data) {
        setResDataHeading(resHeading.data);
      }
      if (!resImage.isError && !!resImage.data) {
        setResDataImage(resImage.data);
      }
      const resInformationAbility =
        await AuditReportService.getAuditReportSection(
          ESectionOfAuditReport.SEOWEBSITEOVERVIEW,
          dataReport.thirdPartyId
        );
      if (!resInformationAbility.isError && !!resInformationAbility.data) {
        setResDataAbility(resInformationAbility.data);
        setIsLoadingAbility(false);
      } else {
        setIsLoadingAbility(false);
      }
    }
  };

  const dataClassify: IDataClassify[] = [
    {
      title: "NGHIÊM TRỌNG",
      value: scores.error,
      infor: "Các vấn đề có ảnh hưởng nghiêm trọng đến điểm SEO website.",
      keyScroll: "technical-error",
    },
    {
      title: "CẢNH BÁO",
      value: scores.warning,
      infor: "Các vấn đề có mức ảnh hưởng trung bình đến điểm SEO website.",
      keyScroll: "technical-warning",
    },
    {
      title: "CẦN CHÚ Ý",
      value: scores.notes,
      infor:
        "Một số vấn đề cần chú ý trên website. Mặc dù các vấn đề này không có nhiều ảnh hưởng đến điểm SEO tổng thể, nhưng cần xem xét lại nếu các vấn đề này xuất hiện trên các trang mong muốn thúc đẩy SEO.",
      keyScroll: "technical-notes",
    },
  ];
  console.log(resDataImage);
  return (
    <TechnicalContentsWrapper>
      <div className="technical-wrapper">
        <OverviewCommonSubTitle
          title="Technical"
          className="SEO-subtitle"
          description="Technical SEO là tập hợp tất cả các tối ưu về mặt kỹ thuật nhằm đảm bảo website dễ dàng được các công cụ tìm kiếm thu thập dữ liệu và xếp hạng cáo trên trang kết quả tìm kiếm."
        />
        <div className="technical-box">
          <OverviewCommonCaption
            className="technical-caption"
            width="420px"
            caption="Overall"
            description="Bản tóm tắt về tình hình kỹ thuật hiện tại của website, các vấn đề đang gặp phải, số lượng và các đề xuất cải thiện cụ thể."
          />
          <ul className="technical-classify flex-around-align-center">
            {dataClassify.map(classify => (
              <li key={classify.title} className="flex-column-center">
                <div className="classify-title flex-center-align-bottom">
                  <p className="fit-one-line">{classify.title}</p>
                  <Tooltip title={classify.infor} placement="top">
                    <div>
                      <ImageCustom
                        src={IconInfor}
                        alt="Icon-Infor"
                        width={"14px"}
                        height={"14px"}
                        className={`tooltip-icon`}
                      />
                    </div>
                  </Tooltip>
                </div>
                <button
                  className="classify-scores"
                  onClick={() => {
                    if (classify.value > 0) {
                      handleNavigationIssue(classify.keyScroll);
                    }
                  }}
                >
                  {CommonHandle.formatNumberCurrency(classify.value)}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="tabs-wrapper">
        <FullWidthTabsTime
          className="tabs"
          time={moment(Date.now()).format(DATETIME_FORMAT)}
          tabs={[
            {
              lable: {
                text: "Tổng quan",
              },
              value: 0,
              content: (
                <TechnicalOverviewTab
                  onNavigateIssue={(key: string) => {
                    handleNavigationIssue(key);
                  }}
                  onShowScores={data => {
                    setScores({
                      error: data.errorCount,
                      warning: data.warningCount,
                      notes: data.notesCount,
                    });
                  }}
                  dataSummary={resDataSummary}
                  dataAbility={resDataAbility}
                  dataHeading={resDataHeading}
                  dataImage={resDataImage}
                  isLoading={isLoading}
                  isLoadingAbility={isLoadingAbility}
                />
              ),
            },
            {
              lable: {
                text: "Tất cả vấn đề",
              },
              value: 1,
              content: (
                <TechnicalIssueTab
                  ref={myRef}
                  keyFilter={key}
                  dataSummary={resDataSummary}
                  dataHeading={resDataHeading}
                  dataImage={resDataImage}
                />
              ),
            },
          ]}
          value={technicalActiveTab}
          onChange={tab => setTechnicalActiveTab(tab)}
        />
      </div>
    </TechnicalContentsWrapper>
  );
}
