const AUTH_PATH = "/authen";
const USER_PATH = "/account";
const AUDIT_PATH = "/audit";
const TOOLS_PATH = "/tools";
const COMPRESS_PATH = "/uploads";
const OUTLINE_PATH = "/tools/outline";
const PROJECT_PATH = "/project";
const REPORT_PATH = "/audit";
const REGION_PATH = "/region";

const AUTH = {
  LOGIN_WITH_PASSWORD: {
    url: `${AUTH_PATH}/sigin`,
  },
  ADMIN_LOGIN_WITH_PASSWORD: {
    url: `${AUTH_PATH}/adminlogin`,
  },
};

const USER = {
  CREATE: {
    url: `${USER_PATH}/create`,
  },
  GET_SIGNLE: {
    url: `${USER_PATH}/getsingle`,
  },
  UPDATE_PASSWORD: {
    url: `${USER_PATH}/Update`,
  },
  GET_USERS: {
    url: `${USER_PATH}/gets`,
  },
  UPDATE_BY_OWNER: {
    url: `${USER_PATH}/updatebyowner`,
  },
  UPDATE_STATUS: {
    url: `${USER_PATH}/UpdateIsDelete`,
  },
};

const AUDIT = {
  POST_START_AUDIT: {
    url: `${AUDIT_PATH}/task`,
  },
  CANCEL_TASK: {
    url: `${AUDIT_PATH}/task/cancel`,
  },
  GET_STATUS_TASK: {
    url: `${AUDIT_PATH}/task/status`,
  },
  GET_DETAIL_TASK: {
    url: `${AUDIT_PATH}/task/detail`,
  },
  GET_LIST_TASK: {
    url: `${AUDIT_PATH}/task/gets`,
  },
  GET_LIST_SITEMAPS: {
    url: `${AUDIT_PATH}/task/sitemaps`,
  },
};

const KEYWORD_GROUP = {
  GROUP_KEYWORD: {
    url: `${TOOLS_PATH}/group-keywords`,
  },
  GROUP_KEYWORD_BY_EXCEL: {
    url: `${TOOLS_PATH}/group-keywords-by-excel`,
  },
  GROUP_KEYWORD_DOWNLOAD_EXCEL_TEMPLATE: {
    url: `${TOOLS_PATH}/download-group-keywords-template`,
  },
  GROUP_KEYWORD_DOWNLOAD_EXCEL: {
    url: `${TOOLS_PATH}/download-excel-group-keywords`,
  },
  GOOGLE_PAGE_SPEED: {
    url: `${TOOLS_PATH}/get-page-speed`,
  },
};

const COMPRESS_PHOTO = {
  COMPRESS_PHOTO: {
    url: `${COMPRESS_PATH}/uploadnew`,
  },
};

const OUTLINE = {
  GET_TOP_5_HEADING: {
    url: `${OUTLINE_PATH}/top-5-heading-of-search-keyword`,
  },
  GET_LIST_PROJECT: {
    url: `${OUTLINE_PATH}/projects`,
  },
  CREATE_PROJECT: {
    url: `${OUTLINE_PATH}/project`,
  },
  GET_LIST_POST: {
    url: `${OUTLINE_PATH}/posts`,
  },
  CREATE_POST: {
    url: `${OUTLINE_PATH}/post`,
  },
  UPDATE_POST_NAME: {
    url: `${OUTLINE_PATH}/post/name`,
  },
};

const REGION = {
  GET_ALL_COUNTRIES: {
    url: `${REGION_PATH}/countries/all`,
  },
  GET_ALL_LANGUAGE: {
    url: `${REGION_PATH}/languages/all`,
  },
};

const PROJECT = {
  CREATE_PROJECT: {
    url: `${PROJECT_PATH}/create`,
  },
  UPDATE_PROJECT: {
    url: `${PROJECT_PATH}`,
  },
  DELETE_PROJECT: {
    url: `${PROJECT_PATH}`,
  },
  GET_PROJECT_LIST: {
    url: `${PROJECT_PATH}`,
  },
  GET_PROJECT_DETAIL: {
    url: `${PROJECT_PATH}`,
  },
};

const REPORT = {
  CREATE_DOMAIN_AUDIT: {
    url: `${REPORT_PATH}/createDomainAudit`,
  },
  CREATE_DOMAIN_COMPETITIVE: {
    url: `${REPORT_PATH}/createDomainAuditCompetitive`,
  },
  CREATE_PAGE_AUDIT: {
    url: `${REPORT_PATH}/createPageAudit`,
  },
  CREATE_PAGE_COMPETITIVE: {
    url: `${REPORT_PATH}/createPageAuditCompetitive`,
  },
  GET_REPORTS: {
    url: `${REPORT_PATH}/gets`,
  },
  GET_REPORT: {
    url: `${REPORT_PATH}/get`,
  },
  DELETE_REPORT: {
    url: `${REPORT_PATH}`,
  },
};

const REPORT_AUDIT = {
  GET_DOMAIN_AUDIT_OVERVIEW: {
    url: `${AUDIT_PATH}/domainOverview`,
  },
  GET_DOMAIN_ORGANIC_KEYWORD: {
    url: `${AUDIT_PATH}/domainOrganicSearchKeyword`,
  },
  GET_DOMAIN_ORGANIC_SEARCH: {
    url: `${AUDIT_PATH}/competitorsOrganicSearch`,
  },
  GET_DOMAIN_ORGANIC_ACCESS: {
    url: `${AUDIT_PATH}/domainOrganicPage`,
  },
  GET_DOMAIN_BACKLINK_OVERVIEW: {
    url: `${AUDIT_PATH}/backLinkOverView`,
  },
  GET_DOMAIN_BACKLINK_OVERVIEW_TABLE: {
    url: `${AUDIT_PATH}/backLinks`,
  },
  GET_DOMAIN_BACKLINK_ANCHOR: {
    url: `${AUDIT_PATH}/backLinksAnchor`,
  },
  GET_DOMAIN_BACKLINKAUTHORIZE_SCORE_PROFILE: {
    url: `${AUDIT_PATH}/backLinkAuthorizeScoreProfile`,
  },
  GET_SEO_ERROR_SUMMARY: {
    url: `${AUDIT_PATH}/summary`,
  },
  GET_ERROR_FROM_PAGES: {
    url: `${AUDIT_PATH}/pages`,
  },
  GET_ERROR_FROM_LINKS: {
    url: `${AUDIT_PATH}/links`,
  },
  GET_RESOURCES: {
    url: `${AUDIT_PATH}/resources`,
  },
  GET_REFFERING_DOMAINS: {
    url: `${AUDIT_PATH}/referringDomains`,
  },
  GET_REFFERING_IPS: {
    url: `${AUDIT_PATH}/referringIPs`,
  },
  GET_INFORMATION_ABILITY: {
    url: `${AUDIT_PATH}/infomationAbility`,
  },
  GET_HEADING_ERROR: {
    url: `${AUDIT_PATH}/pagesErrorWaring`,
  },
  GET_AUDIT_REPORT_SECTION: {
    url: `/Section`,
  },
};

const ApiEndpoints = {
  AUTH,
  USER,
  AUDIT,
  KEYWORD_GROUP,
  COMPRESS_PHOTO,
  OUTLINE,
  REGION,
  PROJECT,
  REPORT,
  REPORT_AUDIT,
};

export default ApiEndpoints;
