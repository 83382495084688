import { Divider } from "@mui/material";
import React, { useState } from "react";
import { DEFAULT_BASE_PAGING_RES } from "../../../../common/constants/value.constant";
import CommonHandle from "../../../../common/handles/common.handles";
import {
  BarChartCustom,
  BubbleChartCustom,
  FastFilterList,
  IndexNoPercentBox,
  PieChartDonutShape,
  ProgressBar,
} from "../../../../components";
import {
  ITableNavDetailNoPaginationHeader,
  TableNavDetailNoPagination,
} from "../../../../components/Table/TableNavDetailNoPagination";
import { IGetDomainOrganicKeywordRes } from "../../../../models/audit-report";
import {
  EIntentsPositions,
  ETypeLinkCheck,
} from "../../../../models/common/models.enum";
import { IValue } from "../../../../models/common/models.type";
import variableStyles from "../../../../theme/variable-styles";
import OverviewCommonCaption from "../../../DomainAudit/components/OverviewCommonCaption";
import IconChart from "../../../../assets/images/common/icon_chart_line_green.svg";
import { DomainOverviewCheckWrapper } from "./styles";
import CheckToolTitle from "../../components/CheckToolTitle";

const data: {
  datasets: {
    label: string;
    data: {
      x: number;
      y: number;
      r: number;
    }[];
    backgroundColor: string;
    borderColor?: string;
  }[];
} = {
  datasets: [
    {
      label: "Dataset 1",
      data: [{ x: 10, y: 30, r: 10 }],
      borderColor: variableStyles.Success500,
      backgroundColor: variableStyles.Success100,
    },
    {
      label: "Dataset 2",
      data: [{ x: 30, y: 10, r: 8 }],
      borderColor: variableStyles.Error500,
      backgroundColor: variableStyles.Error100,
    },
  ],
};
export default function DomainOverviewCheck() {
  const [locationFilter, setLocationFilter] = useState<string>("all");

  const dataFastFilter: IValue[] = [
    { value: "0", title: "Title" },
    { value: "1", title: "Url" },
    { value: "2", title: "H1" },
    { value: "3", title: "H2" },
    { value: "4", title: "Meta Description" },
    { value: "5", title: "Onsite" },
    { value: "6", title: "Image" },
  ];

  const indexNoPercentData: any[] = [
    {
      title: "Điểm uy tín",
      value: CommonHandle.nFormatter(30),
      infor: "Điểm uy tín",
    },
    {
      title: "Organic traffic",
      value: CommonHandle.nFormatter(120000),
      infor: "Organic traffic",
    },
    {
      title: "Organic keyword",
      value: CommonHandle.nFormatter(32000),
      infor: "Organic keyword",
    },
    {
      title: "Backlink",
      value: CommonHandle.nFormatter(21000),
      infor: "Backlink",
    },
    {
      title: "Reffering domain",
      value: CommonHandle.nFormatter(5000),
      infor: "Reffering domain",
    },
  ];

  const renderIntents = (value: string) => {
    switch (value) {
      case EIntentsPositions.Commercial:
        return "C";
      case EIntentsPositions.Informational:
        return "I";
      case EIntentsPositions.Navigational:
        return "N";
      default:
        return "T";
    }
  };

  const headersTable: ITableNavDetailNoPaginationHeader<any>[] = [
    {
      field: "keyword",
      title: `Từ khoá`,
      width: "120px",
      isSticky: true,
    },
    {
      field: "intents",
      title: `Intent`,
      width: "80px",
      className: "table-cell-intents-tag",
      handleItem: (item: IGetDomainOrganicKeywordRes) => {
        const arrayIntents = item.intents?.split(",");
        return (
          <div className="flex-start-align-center intents-tags">
            {arrayIntents.map(array => {
              const character = renderIntents(array);
              return (
                <p className={`${character} intents-tag`} key={array}>
                  {character}
                </p>
              );
            })}
          </div>
        );
      },
    },
    {
      field: "position",
      title: `Vị trí`,
      width: "70px",
    },
    {
      field: "searchVolume",
      title: `Volume`,
      width: "80px",
    },
    {
      field: "keywordDifficulty",
      title: `KD`,
      width: "50px",
    },
    {
      field: "cpc",
      title: `CPC`,
      width: "50px",
    },
    {
      field: "traffic",
      title: `Traffic`,
      width: "80px",
    },
    {
      field: "url",
      title: `URL`,
      width: "180px",
      className: "table-cell-link",
      isLink: true,
    },
  ];

  const headersOrganicTable: ITableNavDetailNoPaginationHeader<any>[] = [
    {
      field: "domain",
      title: `Domain`,
      width: "110px",
      isSticky: true,
      className: "table-cell-link",
      isLink: true,
    },
    {
      field: "competitorRelevance",
      title: `Competitor Relevence`,
      width: "140px",
      className: "table-cell-progress",
      handleItem: (item: any) => {
        return (
          <div>
            <p className="progress-percent">{item.competitorRelevance || 0}%</p>
            <ProgressBar
              percent={item.competitorRelevance || 0}
              className="progress-bar"
              type={"normal"}
              height={"4px"}
            />
          </div>
        );
      },
    },
    {
      field: "organicTraffic",
      title: `Organic Traffic`,
      width: "100px",
    },
    {
      field: "organicKeywords",
      title: `Organic keywords`,
      width: "120px",
    },
    {
      field: "commonKeywords",
      title: `Common keywords`,
      width: "140px",
    },
  ];

  const headersTopPageTable: ITableNavDetailNoPaginationHeader<any>[] = [
    {
      field: "url",
      title: `Website`,
      className: "table-cell-link",
      handleItem: (item: any) => {
        return (
          <a className="fit-one-line" target="_blank" rel="noopener noreferrer">
            {item.url}
          </a>
        );
      },
    },
    {
      field: "organicTraffic",
      title: `Number keywords`,
      width: "100px",
    },
    {
      field: "organicKeywords",
      title: `Traffic`,
      width: "120px",
    },
    {
      field: "commonKeywords",
      title: `%Traffic`,
      width: "140px",
    },
    {
      field: "commonKeywords",
      title: `Top key`,
      width: "140px",
    },
    {
      field: "commonKeywords",
      title: `Volume`,
      width: "140px",
    },
    {
      field: "commonKeywords",
      title: `Position`,
      width: "140px",
    },
  ];

  const backlinkTypesData: {
    title: string;
    percent: number;
  }[] = [
    { title: "Text", percent: 55 },
    { title: "Image", percent: 10 },
    { title: "Form", percent: 30 },
    { title: "Frame", percent: 15 },
  ];
  return (
    <DomainOverviewCheckWrapper>
      <CheckToolTitle
        title="Domain overview"
        domain="www.mikotech.vn"
        onSubmit={() => {}}
        value={{
          target: "",
          type: ETypeLinkCheck.RootDomain,
        }}
      />
      <div className="domain-overview-content scrollbar">
        <FastFilterList
          selectedValue={locationFilter}
          onChange={(v: string | null) => {
            if (!!v) {
              setLocationFilter(v);
            } else {
              setLocationFilter("all");
            }
          }}
          data={dataFastFilter}
          hasShowMore
          numberOfItemShowed={3}
          className="content-location-filter"
        />
        <ul className="indexNoPercent-list">
          {indexNoPercentData.map(data => (
            <li key={data.title}>
              <IndexNoPercentBox data={data} />
            </li>
          ))}
        </ul>
        <div className="content-divide-2">
          <div className="divide-left">
            <OverviewCommonCaption
              className="divide-caption"
              width="420px"
              caption="Tỷ trọng lượng truy cập"
              tooltip="Tỷ trọng lượng truy cập"
              icon={IconChart}
            />
            <Divider />
            <div className="left-content">
              <BarChartCustom
                data={[]}
                listKeyField={["pv", "uv"]}
                XAxisKey="day"
                YAxisType="number"
                unit="%"
                height="146px"
              />
              <TableNavDetailNoPagination
                headersTable={headersTable}
                data={{ ...DEFAULT_BASE_PAGING_RES }}
                handleViewMore={() => {}}
                isFullTable
                handlePageChange={page => {}}
                maxHeight={"calc(100vh - 280px)"}
              />
            </div>
          </div>
          <div className="divide-right">
            <OverviewCommonCaption
              className="divide-caption"
              width="420px"
              caption="Tỷ trọng lượng truy cập"
              tooltip="Tỷ trọng lượng truy cập"
              icon={IconChart}
            />

            <Divider />
            <div className="right-content">
              <BubbleChartCustom
                data={[
                  { name: "test 1", volumn: 12, index: 1, count: 1700 },
                  { name: "test 2", volumn: 2, index: 12, count: 1800 },
                  { name: "test 3", volumn: 35, index: 13, count: 1050 },
                  { name: "test 4", volumn: 24, index: 31, count: 120 },
                  { name: "test 5", volumn: 51, index: 41, count: 2000 },
                  { name: "test 6", volumn: 61, index: 51, count: 3030 },
                ]}
                XAxisKey="volumn"
                YAxisKey="index"
                ZAxisKey="count"
              />
              <TableNavDetailNoPagination
                headersTable={headersOrganicTable}
                data={{
                  ...DEFAULT_BASE_PAGING_RES,
                }}
              />
            </div>
          </div>
        </div>
        <h2 className="h2-title">Top page</h2>
        <TableNavDetailNoPagination
          headersTable={headersTopPageTable}
          data={{
            ...DEFAULT_BASE_PAGING_RES,
          }}
        />
        <h2 className="h2-title">Backlink</h2>
        <div className="backlink-wrapper">
          <div className="backlink-left">
            <p className="left-title">Follow and no follow</p>
            <PieChartDonutShape
              data={[
                { name: "Follow ", value: 5500 },
                { name: "No Follow ", value: 1000 },
              ]}
              isLegendBottom
              className="left-chart"
              isSmall
            />
            <p className="left-title">Backlink types</p>
            <ul className="left-proccess-list">
              {backlinkTypesData.map(backlink => (
                <li key={backlink.title}>
                  <div className="proccess-text flex-between-align-bottom">
                    <p>{backlink.title}</p>
                    <p>{backlink.percent}%</p>
                  </div>
                  <ProgressBar percent={backlink.percent} />
                </li>
              ))}
            </ul>
          </div>
          <div className="backlink-right">
            <TableNavDetailNoPagination
              headersTable={headersOrganicTable}
              data={{
                ...DEFAULT_BASE_PAGING_RES,
              }}
            />
          </div>
        </div>
        <div className="content-divide-2">
          <div className="divide-left">
            <OverviewCommonCaption
              className="divide-caption"
              width="420px"
              caption="Referring domain"
              tooltip="Referring domain"
              icon={IconChart}
            />
            <Divider />
            <div className="left-content">
              <div className="left-chart-wrapper">
                <BarChartCustom
                  data={[
                    {
                      anchor: "thiết kế web bất động sản",
                      backLinksNum: "2604",
                    },
                    {
                      anchor: "image",
                      backLinksNum: "1459",
                    },
                    {
                      anchor: "<EmptyAnchor>",
                      backLinksNum: "858",
                    },
                    {
                      anchor: "mikotech.vn",
                      backLinksNum: "800",
                    },
                    {
                      anchor: "thiết kế website",
                      backLinksNum: "734",
                    },
                  ].reverse()}
                  listKeyField={["backLinksNum"]}
                  YAxisKey={"anchor"}
                  XAxisType="number"
                  layout="vertical"
                  height={"250px"}
                  reversedYAxis
                  colorTextList={[variableStyles.NaturalColor0]}
                  isHasLabelList={true}
                  colorFillList={[variableStyles.GreenPrimaryColor400]}
                />
              </div>
              <TableNavDetailNoPagination
                headersTable={headersTable}
                data={{ ...DEFAULT_BASE_PAGING_RES }}
                handleViewMore={() => {}}
                isFullTable
                handlePageChange={page => {}}
                maxHeight={"calc(100vh - 280px)"}
              />
            </div>
          </div>
          <div className="divide-right">
            <OverviewCommonCaption
              className="divide-caption"
              width="420px"
              caption="Top anchor text"
              tooltip="Top anchor text"
              icon={IconChart}
            />
            <Divider />
            <div className="right-content">
              <BubbleChartCustom
                data={[
                  { name: "test 1", volumn: 12, index: 1, count: 1700 },
                  { name: "test 2", volumn: 2, index: 12, count: 1800 },
                  { name: "test 3", volumn: 35, index: 13, count: 1050 },
                  { name: "test 4", volumn: 24, index: 31, count: 120 },
                  { name: "test 5", volumn: 51, index: 41, count: 2000 },
                  { name: "test 6", volumn: 61, index: 51, count: 3030 },
                ]}
                YAxisKey="index"
                ZAxisKey="count"
              />
              <TableNavDetailNoPagination
                headersTable={headersOrganicTable}
                data={{
                  ...DEFAULT_BASE_PAGING_RES,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </DomainOverviewCheckWrapper>
  );
}
