import React from "react";
import {
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
  ZAxis,
} from "recharts";
import {
  ContentType,
  Payload,
} from "recharts/types/component/DefaultLegendContent";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { IChartValue } from "../../../models/common/models.type";
import variableStyles from "../../../theme/variable-styles";
import { BubbleChartCustomWrapper } from "./styles";

interface BubbleChartCustomProps {
  data: IChartValue[];
  XAxisKey?: string;
  YAxisKey?: string;
  ZAxisKey?: string;
  XAxisType?: "number" | "category";
  YAxisType?: "number" | "category";
  ZAxisType?: "number" | "category";
  height?: string;
  colorFillList?: string[];
  colorTextList?: string[];
  fieldTextList?: { value: string; color: string }[];
  YAxisWidth?: number;
}

export default function BubbleChartCustom(props: BubbleChartCustomProps) {
  const {
    data,
    XAxisKey,
    YAxisKey,
    ZAxisKey,
    XAxisType = "number",
    YAxisType = "number",
    ZAxisType = "number",
    height = "160px",
    colorFillList = [
      "#54B4DC4d",
      "#23B4FF4d",
      "#DB9F004d",
      "#1C7F5E4d",
      "#122e3f4d",
      "#FDCF004d",
    ],
    fieldTextList,
    YAxisWidth = 40,
  } = props;
  const range = [16, 3000];

  const parseDomain = () => [
    0,
    Math.max(
      Math.max.apply(
        null,
        data.map(entry => Number(entry.count))
      )
    ),
  ];

  const CustomTooltip = ({
    active,
    payload,
  }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      const data = payload[0] && payload[0].payload;

      return (
        <div
          style={{
            backgroundColor: "#fff",
            border: "1px solid #999",
            margin: 0,
            padding: 10,
          }}
        >
          <p>{data.name}: </p>
          <p>{!!ZAxisKey && data[ZAxisKey]}</p>
        </div>
      );
    }

    return null;
  };
  interface IPropsType {
    payload?: Payload[];
  }
  const renderLegend: ContentType = (props: IPropsType) => {
    const { payload } = props;
    if (!!payload) {
      const data = payload[0] && payload[0].payload;
    }

    return (
      <ul className="legend-list">
        {!!fieldTextList ? (
          <>
            {fieldTextList?.map((entry: any, index: any) => (
              <li key={`item-${index}`}>
                <div style={{ backgroundColor: colorFillList[index] }} />
                <p> {entry.value}</p>
              </li>
            ))}
          </>
        ) : (
          <>
            {data?.map((entry: any, index: any) => (
              <li key={`item-${index}`}>
                <div style={{ backgroundColor: colorFillList[index] }} />
                <p> {entry.name}</p>
              </li>
            ))}
          </>
        )}
      </ul>
    );
  };

  const domain = parseDomain();

  return (
    <BubbleChartCustomWrapper height={height}>
      <ResponsiveContainer width="100%">
        <ScatterChart
          margin={{
            top: 0,
            right: 10,
            bottom: 0,
            left: 0,
          }}
        >
          <CartesianGrid
            strokeWidth={1}
            stroke={`${variableStyles.NaturalColor200}`}
            vertical={false}
          />
          {!!XAxisKey && (
            <XAxis
              type={XAxisType}
              dataKey={XAxisKey}
              tick={{
                color: variableStyles.NaturalColor950,
                fontWeight: variableStyles.fwMedium,
                fontSize: "10px",
              }}
              axisLine={false}
              tickLine={false}
            />
          )}
          {!!YAxisKey && (
            <YAxis
              type={YAxisType}
              dataKey={YAxisKey}
              tick={{
                color: variableStyles.NaturalColor800,
                fontWeight: variableStyles.fwRegular,
                fontSize: "10px",
              }}
              tickLine={false}
              axisLine={{
                strokeWidth: 1,
                stroke: variableStyles.NaturalColor200,
              }}
              width={YAxisWidth}
            />
          )}
          <ZAxis
            type={ZAxisType}
            dataKey={ZAxisKey}
            domain={domain}
            range={range}
          />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{
              stroke: `${variableStyles.blueSecondary200}`,
              strokeWidth: 1,
            }}
          />
          <Legend
            content={renderLegend}
            verticalAlign="top"
            wrapperStyle={{ top: "-18px", left: "10px" }}
          />
          <Scatter data={data} fill="#54B4DC">
            {data.map((_, index) => (
              <Cell key={`cell-${index}`} fill={colorFillList[index]} />
            ))}
          </Scatter>
        </ScatterChart>
      </ResponsiveContainer>
    </BubbleChartCustomWrapper>
  );
}
