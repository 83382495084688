import { useMediaQuery } from "@mui/material";
import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { IDataFilterBacklinkType } from "../../..";
import { DEFAULT_BASE_PAGING_RES } from "../../../../../../../common/constants/value.constant";
import {
  FastFilterList,
  NumberRangePicker,
  ProgressBar,
  TableNavDetailNoPagination,
} from "../../../../../../../components";
import InputSearch from "../../../../../../../components/controls/InputSearch";
import SelectCustom from "../../../../../../../components/SelectCustom";
import { ITableNavDetailNoPaginationHeader } from "../../../../../../../components/Table/TableNavDetailNoPagination";
import { IValue } from "../../../../../../../models/common/models.type";

interface BackLinkOverviewTabProps {
  dataFilter: IDataFilterBacklinkType;
  onChangeParamsURL: (value: string) => void;
}

export default function BacklinkOverviewTab(props: BackLinkOverviewTabProps) {
  const { dataFilter, onChangeParamsURL } = props;

  const timerOut = useRef<number>();
  const matches1020 = useMediaQuery("(max-width:1020px)");

  const [keySearchState, setKeySearchState] = useState<string | null>(null);

  const dataLinkFilter: IValue[] = [
    { value: "all", title: "Tất cả" },
    { value: "newlink", title: "New link" },
    { value: "lostlink", title: "Lost link" },
  ];

  const dataFollowFilter: IValue[] = [
    { value: "all", title: "Tất cả" },
    { value: "follow", title: "Follow" },
    { value: "noFollow", title: "No Follow" },
  ];
  const backlinkTypeFilter: IValue[] = [
    { value: "all", title: "Tất cả" },
    { value: "image", title: "Image" },
    { value: "text", title: "Text" },
    { value: "form", title: "Form" },
    { value: "frame", title: "Frame" },
  ];

  useEffect(() => {
    setKeySearchState(dataFilter.searchLink);
  }, [dataFilter.searchLink]);

  const onChangeSearch = (e: any) => {
    const value = e?.target?.value;
    if (timerOut.current) {
      clearTimeout(timerOut.current);
    }
    timerOut.current = window.setTimeout(() => {
      handleChangeParams([
        { keyParams: "searchLink", valueParams: value.toString() },
      ]);
    }, 700);
  };

  const handleChangeParams = (
    value: { keyParams: string; valueParams: string | null }[]
  ) => {
    const newData: any = { ...dataFilter };
    value.forEach(value => {
      if (
        !value.valueParams ||
        (!!value.valueParams && value.valueParams.length <= 0) ||
        value.valueParams === "all"
      ) {
        delete newData[value.keyParams];
      } else {
        if (newData.hasOwnProperty(value.keyParams)) {
          newData[value.keyParams] = value.valueParams;
        }
      }
    });

    for (const [key, valueFor] of Object.entries(
      newData as IDataFilterBacklinkType
    )) {
      if (
        !valueFor ||
        (!!valueFor && valueFor.length <= 0) ||
        valueFor === "all"
      ) {
        delete newData[key];
      }
    }
    const newParams = queryString.stringify(newData);
    onChangeParamsURL(newParams.toLocaleLowerCase());
  };

  const headersOrganicTable: ITableNavDetailNoPaginationHeader<any>[] = [
    {
      field: "domain",
      title: `Tiêu đề/ đường dẫn`,
      width: "110px",
      isSticky: true,
      className: "table-cell-link",
      isLink: true,
    },
    {
      field: "competitorRelevance",
      title: `Điểm uy tín`,
      width: "140px",
      className: "table-cell-progress",
      handleItem: (item: any) => {
        return (
          <div>
            <p className="progress-percent">{item.competitorRelevance || 0}%</p>
            <ProgressBar
              percent={item.competitorRelevance || 0}
              className="progress-bar"
              type={"normal"}
              height={"4px"}
            />
          </div>
        );
      },
    },
    {
      field: "organicTraffic",
      title: `External link`,
      width: "100px",
    },
    {
      field: "organicKeywords",
      title: `Internal link`,
      width: "120px",
    },
    {
      field: "commonKeywords",
      title: `Anchor text / URL`,
      width: "140px",
    },
    {
      field: "commonKeywords",
      title: `First seen`,
      width: "140px",
    },
    {
      field: "commonKeywords",
      title: `Last seen`,
      width: "140px",
    },
  ];

  return (
    <div className="overview-tab-wrapper tab-common-wrapper">
      <div className="tab-header-filter flex-between-align-center">
        <div className="filter-left flex-start-align-center">
          <InputSearch
            placeholder={"Tìm kiếm"}
            onChange={(e: any) => {
              setKeySearchState(e.target.value);
              onChangeSearch(e);
            }}
            value={keySearchState}
            size={"medium"}
            width={"340px"}
            typeStyle="outlined"
            selectData={[
              { title: "Anchor text", value: "anchortext" },
              { title: "Referring URL", value: "referringurl" },
              { title: "Target URL", value: "targeturl" },
            ]}
            onChangeSelect={value =>
              handleChangeParams([
                { keyParams: "searchLinkType", valueParams: value.toString() },
              ])
            }
            valueSelect={dataFilter.searchLinkType || "anchortext"}
          />
          {matches1020 && (
            <div className="responsive-filter flex-end-align-center">
              <SelectCustom
                options={backlinkTypeFilter}
                onChange={event => {
                  handleChangeParams([
                    {
                      keyParams: "backLinkType",
                      valueParams: event.target.value,
                    },
                  ]);
                }}
                placeholder="Chọn loại"
                isSelectedIcon
                value={dataFilter.backLinkType || backlinkTypeFilter[0].value}
                className="action-select-backlinktype"
                fullWidth={false}
              />
              <NumberRangePicker
                onSubmit={(value: {
                  from: number | null;
                  to: number | null;
                }) => {
                  handleChangeParams([
                    {
                      keyParams: "reputationFrom",
                      valueParams: value.from?.toString() || "",
                    },
                    {
                      keyParams: "reputationTo",
                      valueParams: value.to?.toString() || "",
                    },
                  ]);
                }}
                onClear={() => {
                  handleChangeParams([
                    {
                      keyParams: "reputationFrom",
                      valueParams: "",
                    },
                    {
                      keyParams: "reputationTo",
                      valueParams: "",
                    },
                  ]);
                }}
                placeholder="Điểm uy tín"
                modalTitle="Điểm uy tín"
                value={{
                  from: Number(dataFilter.reputationFrom),
                  to: Number(dataFilter.reputationTo),
                }}
                isFormatNumber
              />
            </div>
          )}
        </div>
        <div className="filter-right flex-end-align-center">
          <FastFilterList
            selectedValue={dataFilter.typeLink || "all"}
            onChange={(v: string | null) => {
              handleChangeParams([{ keyParams: "typeLink", valueParams: v }]);
            }}
            data={dataLinkFilter}
            numberOfItemShowed={3}
            className="content-location-filter"
          />
          <FastFilterList
            selectedValue={dataFilter.followType || "all"}
            onChange={(v: string | null) => {
              handleChangeParams([{ keyParams: "followType", valueParams: v }]);
            }}
            data={dataFollowFilter}
            numberOfItemShowed={3}
            className="content-location-filter"
          />
          {!matches1020 && (
            <>
              <SelectCustom
                size="small"
                options={backlinkTypeFilter}
                onChange={event => {
                  handleChangeParams([
                    {
                      keyParams: "backLinkType",
                      valueParams: event.target.value,
                    },
                  ]);
                }}
                placeholder="Chọn loại"
                isSelectedIcon
                value={dataFilter.backLinkType || backlinkTypeFilter[0].value}
                className="action-select-backlinktype"
                fullWidth={false}
              />
              <NumberRangePicker
                onSubmit={(value: {
                  from: number | null;
                  to: number | null;
                }) => {
                  handleChangeParams([
                    {
                      keyParams: "reputationFrom",
                      valueParams: value.from?.toString() || "",
                    },
                    {
                      keyParams: "reputationTo",
                      valueParams: value.to?.toString() || "",
                    },
                  ]);
                }}
                onClear={() => {
                  handleChangeParams([
                    {
                      keyParams: "reputationFrom",
                      valueParams: "",
                    },
                    {
                      keyParams: "reputationTo",
                      valueParams: "",
                    },
                  ]);
                }}
                placeholder="Điểm uy tín"
                modalTitle="Điểm uy tín"
                value={{
                  from: Number(dataFilter.reputationFrom),
                  to: Number(dataFilter.reputationTo),
                }}
                isFormatNumber
                size="small"
              />
            </>
          )}
        </div>
      </div>
      <div className="overview-table">
        <TableNavDetailNoPagination
          headersTable={headersOrganicTable}
          data={{
            ...DEFAULT_BASE_PAGING_RES,
          }}
        />
      </div>
    </div>
  );
}
