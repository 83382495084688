import React, { useState } from "react";
import { Button } from "@mui/material";
import { Helmet } from "react-helmet";
import { ImageCustom, TabsCustom } from "../../../components";
import { DomainAuditDetailWrapper } from "./styles";
import IconDownload from "../../../assets/images/common/icon_download_line_white.svg";
import { IValue } from "../../../models/common/models.type";
import OverviewSection from "../components/tabs/OverviewTab";
import TechnicalViewSection from "../components/tabs/TechnicalViewTab";
import CrawledPageSection from "../components/tabs/CrawledPageTab";
import ErrorTableModal from "../components/ModalErrorTable";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { ROUTE_PATH } from "../../../common/constants/app.constant";
import { useSelector } from "react-redux";
import { IStateApp } from "../../../models/redux";
import { IGetReportsRes } from "../../../models/report";
import queryString from "query-string";

const dataTabs: IValue[] = [
  { value: 0, title: "Overview" },
  { value: 1, title: "Technical view" },
  { value: 2, title: "Crawled Page" },
];

export default function DomainAuditDetail() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const paramTab: {
    tab: string | null;
  } = {
    tab: searchParams.get("tab") || "0",
  };

  const dataReport = useSelector<IStateApp, IGetReportsRes>(
    appState => appState.project?.dataReport
  );

  const downloadPdf = () => {};
  return (
    <>
      <ErrorTableModal />
      <DomainAuditDetailWrapper>
        <Helmet>
          <title>Domain Audit</title>
        </Helmet>
        <div className="domain-audit-header">
          <p className="header-name fit-one-line">My project</p>
          <div className="header-action">
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={downloadPdf}
              className="download-button"
            >
              <ImageCustom
                src={IconDownload}
                alt="Icon-Download"
                width={"18px"}
                height={"18px"}
              />
              <p>Tải báo cáo</p>
            </Button>
            <Button
              color="primary"
              size="small"
              variant="outlined"
              onClick={_ => {}}
              className="share-button"
            >
              Chia sẻ
            </Button>
          </div>
        </div>
        <TabsCustom
          data={dataTabs}
          onChange={(v: number) => {
            const path = generatePath(ROUTE_PATH.DOMAIN_AUDIT_DETAIL, {
              id: dataReport.auditId,
            });
            const params = queryString.stringify({ tab: v });
            navigate({
              pathname: `${path}`,
              search: params,
            });
          }}
          selectedValue={Number(paramTab.tab) || 0}
        />
        {Number(paramTab.tab) === 0 ? (
          <OverviewSection />
        ) : Number(paramTab.tab) === 1 ? (
          <TechnicalViewSection />
        ) : (
          <CrawledPageSection />
        )}
      </DomainAuditDetailWrapper>
    </>
  );
}
