import React, { useEffect, useState } from "react";
import Modal from "..";
import { ControlForm } from "../..";
import InputForm from "../../controls/InputForm";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { BehaviorSubject } from "rxjs";
import IconLink from "../../../assets/images/common/icon_link_line_grey.svg";
import { IProject, IProjectUpdateCreateReq } from "../../../models/project";
import { CreateProjectFormWrapper } from "./styles";

interface CreateProjectModalProps {
  open?: boolean;
  onSubmit?: (data: IProjectUpdateCreateReq) => Promise<void>;
  onUpdate?: (data: IProjectUpdateCreateReq) => Promise<void>;
  onCancel?: () => void;
  dataProject?: IProject;
}

const createProjectModalSubject = new BehaviorSubject<CreateProjectModalProps>({
  open: false,
  onSubmit: undefined,
  onUpdate: undefined,
  dataProject: undefined,
});

export const toggleCreateProjectModal = (value: CreateProjectModalProps) => {
  createProjectModalSubject.next(value);
};

export default function CreateProjectModal() {
  const [data, setData] = useState<CreateProjectModalProps>({
    open: false,
    onSubmit: async () => {},
    onUpdate: undefined,
    dataProject: undefined,
  });

  useEffect(() => {
    const subscribe = createProjectModalSubject.subscribe(value => {
      let params = { ...value };

      if (value.open === undefined) {
        params.open = true;
      }

      setData(params);
    });
    return () => {
      subscribe.unsubscribe();
    };
  }, []);

  const regex = /^(https?:\/\/)([\da-z\.-]+)\.([a-z\.]{2,6})$/;

  const validationSchema = Yup.object().shape({
    projectName: Yup.string().required(`${"Please enter project name"}`),
    domain: Yup.string()
      .matches(regex, "Is not in correct format")
      .required(`${"Please enter your domain"}`),
  }) as any;

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      projectName: "",
      domain: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const onCancel = () => {
    data.onCancel && data.onCancel();
    reset();
    setData({ ...data, open: false, dataProject: undefined });
  };

  const onSubmit = (value: IProjectUpdateCreateReq) => {
    if (!!data.dataProject && !!data.onUpdate) {
      data.onUpdate(value);
      setData({ ...data, open: false });
    } else if (!!data.onSubmit) {
      data.onSubmit(value);
      setData({ ...data, open: false });
    }
  };

  useEffect(() => {
    if (!!data.dataProject && !!data.dataProject.projectId) {
      setValue("projectName", data.dataProject.projectName);
      setValue("domain", data.dataProject.domain);
    }
  }, [data.dataProject]);

  return (
    <Modal
      title="Create new Project"
      description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  eiusmo"
      open={data.open}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onCancel}
    >
      <CreateProjectFormWrapper onSubmit={handleSubmit(onSubmit)}>
        <ControlForm
          title="Name"
          infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
          classname="brand-form"
        >
          <InputForm
            placeholder="Your project name "
            required
            name="projectName"
            errors={errors}
            control={control}
            size="small"
          />
        </ControlForm>
        <ControlForm
          title="Your Domain"
          infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
          classname="url-form"
        >
          <InputForm
            placeholder="https://..."
            required
            name="domain"
            errors={errors}
            control={control}
            size="small"
            startIcon={IconLink}
            isLink
          />
        </ControlForm>
      </CreateProjectFormWrapper>
    </Modal>
  );
}
