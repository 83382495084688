import queryString from "query-string";
import React, { forwardRef, useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import { ROUTE_PATH } from "../../../../../../../../common/constants/app.constant";
import { DEFAULT_BASE_PAGING_RES } from "../../../../../../../../common/constants/value.constant";
import { CollapseHandleError } from "../../../../../../../../components";
import { EAuditErrorStatus } from "../../../../../../../../models/common/models.enum";
import { IStateApp } from "../../../../../../../../models/redux";
import { TechnicalIssueTabWrapper } from "./styles";
import mapData, { IIssueCommon } from "./tempData";

const TechnicalIssueTab = forwardRef<any, any>(
  ({ keyFilter, dataSummary, dataHeading, dataImage }, ref) => {
    const errorList = useSelector<IStateApp, string[]>(
      appState => appState.project?.errorList
    );

    const childFunction = () => {
      const element = document.getElementById(keyFilter);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    };
    useImperativeHandle(ref, () => ({
      childFunction,
    }));

    const issueHeading: {
      pageHeadingDuplicate: IIssueCommon;
      pagesMoreThanHeading: IIssueCommon;
      pageHeadingToLong: IIssueCommon;
      pageHeadingAndTileDuplicate: IIssueCommon;
    } = {
      pageHeadingDuplicate: {
        title: "Trang trùng lặp thẻ Heading H1",
        key: "duplicate_h1",
        data: dataHeading?.pageHeadingDuplicate || [],
        headerData: [
          {
            field: "url",
            title: `URL`,
            className: "table-cell-link",
            handleItem: (item: any) => {
              return (
                <a
                  className="fit-one-line"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item.url}
                >
                  {item.url}
                </a>
              );
            },
          },
          {
            field: "h1",
            title: `H1`,
            handleItem: (item: any) => {
              return <p className="fit-one-line">{item.h1}</p>;
            },
          },
        ],
        navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
        navigateFilter: queryString.stringify({
          tab: 2,
          mFilter: "h1",
          sFilter: "duplicate_h1",
        }),
      },
      pagesMoreThanHeading: {
        title: "Trang có nhiều hơn 1 thẻ Heading 1",
        key: "many_h1",
        data: dataHeading?.pagesMoreThanHeading || [],
        headerData: [
          {
            field: "url",
            title: `URL`,
            className: "table-cell-link",
            handleItem: (item: any) => {
              return (
                <a
                  className="fit-one-line"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item.url}
                >
                  {item.url}
                </a>
              );
            },
          },
          {
            field: "domain",
            title: `Số lượng H1`,
            className: " ",
            handleItem: (item: any) => {
              return <p className="fit-one-line">{item.createdAt}</p>;
            },
          },
        ],
        navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
        navigateFilter: queryString.stringify({
          tab: 2,
          mFilter: "h1",
          sFilter: "many_h1",
        }),
      },
      pageHeadingToLong: {
        title: "Trang có thẻ Heading 1 quá dài",
        key: "h1_long",
        data: dataHeading?.pageHeadingToLong || [],
        headerData: [
          {
            field: "url",
            title: `URL`,
            className: "table-cell-link",
            handleItem: (item: any) => {
              return (
                <a
                  className="fit-one-line"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item.url}
                >
                  {item.url}
                </a>
              );
            },
          },
          {
            field: "domain",
            title: `Độ dài H1`,
            className: " ",
            handleItem: (item: any) => {
              return <p className="fit-one-line">{item.h1.length}</p>;
            },
          },
        ],
        navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
        navigateFilter: queryString.stringify({
          tab: 2,
          mFilter: "h1",
          sFilter: "many_h1",
        }),
      },
      pageHeadingAndTileDuplicate: {
        title: "Trang có thẻ tiêu đề và Heading 1 trùng lặp",
        key: "h1_title_duplicate",
        data: dataHeading?.pageHeadingAndTileDuplicate || [],
        headerData: [
          {
            field: "url",
            title: `URL`,
            className: "table-cell-link",
            handleItem: (item: any) => {
              return (
                <a
                  className="fit-one-line"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item.url}
                >
                  {item.url}
                </a>
              );
            },
          },
          {
            field: "domain",
            title: `Tiêu đề`,
            handleItem: (item: any) => {
              return <p className="fit-one-line">{item.createdAt}</p>;
            },
          },
          {
            field: "domain",
            title: `Heading 1`,
            handleItem: (item: any) => {
              return <p className="fit-one-line">{item.createdAt}</p>;
            },
          },
        ],
      },
    };

    const issueNoAlt: IIssueCommon = {
      title: "Hình ảnh thiếu thẻ ALT",
      key: "alternative_text",
      type: "resources",
      headerData: [
        {
          field: "url",
          title: `URL`,
          className: "table-cell-link",
          handleItem: (item: any) => {
            return (
              <a
                className="fit-one-line"
                target="_blank"
                rel="noopener noreferrer"
                href={item.url}
              >
                {item.url}
              </a>
            );
          },
        },
      ],
      navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
      navigateFilter: queryString.stringify({
        tab: 2,
        mFilter: "image",
        sFilter: "missingalt",
      }),
      data: dataImage,
    };

    const dataIssueWaring: IIssueCommon[] = mapData.dataIssueWaring.map(
      issue => {
        if (issue.key === "duplicate_h1") {
          return issueHeading.pageHeadingDuplicate;
        } else if (issue.key === "many_h1") {
          return issueHeading.pagesMoreThanHeading;
        } else if (issue.key === "h1_long") {
          return issueHeading.pageHeadingToLong;
        } else if (issue.key === "h1_title_duplicate") {
          return issueHeading.pageHeadingAndTileDuplicate;
        } else if (issue.key === "alternative_text") {
          return issueNoAlt;
        } else {
          return issue;
        }
      }
    );

    return (
      <TechnicalIssueTabWrapper>
        <ul className="issue-list" id="technical-error">
          <p className="list-title error">LỖI NGHIÊM TRỌNG</p>
          {mapData.dataIssueError
            .filter(item => errorList.includes(item.key))
            .map(content => (
              <li key={content.title} id={content.key}>
                <CollapseHandleError
                  headersTable={content.headerData}
                  typeError={EAuditErrorStatus.Error}
                  title={content.title}
                  filter={content.filter}
                  type={content.type}
                  isOpenKey={keyFilter}
                  keyFilter={content.key}
                  isNavigateToLink={content.isNavigateToLink}
                  navigatePath={content.navigatePath}
                  navigateFilter={content.navigateFilter}
                  data={content.data}
                />
              </li>
            ))}
        </ul>
        <ul className="issue-list" id="technical-warning">
          <p className="list-title warning">CẢNH BÁO</p>
          {dataIssueWaring
            .filter(item => errorList.includes(item.key))
            .map(content => (
              <li key={content.title} id={content.key}>
                <CollapseHandleError
                  headersTable={content.headerData}
                  typeError={EAuditErrorStatus.Warning}
                  title={content.title}
                  filter={content.filter}
                  type={content.type}
                  isOpenKey={keyFilter}
                  keyFilter={content.key}
                  isNavigateToLink={content.isNavigateToLink}
                  navigatePath={content.navigatePath}
                  navigateFilter={content.navigateFilter}
                  data={content.data}
                />
              </li>
            ))}
        </ul>
        {mapData.dataIssueInfor.filter(item => errorList.includes(item.key))
          .length > 0 && (
            <ul className="issue-list" id="technical-notes">
              <p className="list-title infor">CẦN CHÚ Ý</p>
              {mapData.dataIssueInfor
                .filter(item => errorList.includes(item.key))
                .map(content => (
                  <li key={content.title} id={content.key}>
                    <CollapseHandleError
                      headersTable={content.headerData}
                      typeError={EAuditErrorStatus.Infor}
                      title={content.title}
                      filter={content.filter}
                      type={content.type}
                      isOpenKey={keyFilter}
                      keyFilter={content.key}
                      isNavigateToLink={content.isNavigateToLink}
                      navigatePath={content.navigatePath}
                      navigateFilter={content.navigateFilter}
                      data={content.data}
                    />
                  </li>
                ))}
            </ul>
          )}
      </TechnicalIssueTabWrapper>
    );
  }
);
export default TechnicalIssueTab;
