import OverviewCommonCaption from "../../OverviewCommonCaption";
import OverviewCommonSubTitle from "../../OverviewCommonSubTitle";
import OverviewCommonTitle from "../../OverviewCommonTitle";
import { SecuritySectionWrapper } from "./styles";
import { Box, Divider, Typography } from "@mui/material";
import Quote from "../../Quote";
import Google from "../../../../../assets/images/domai-audit/security-section/google.png";
import {
  CollapseHandleError,
  ImageCustom,
  LoadingIndicator,
} from "../../../../../components";
import ScreenshotOnDevice, { EStrategy } from "./components/ScreenshotOnDevice";
import FullWidthTabs from "../../../../../components/FullWidthTabsTime";
import React, { forwardRef, useEffect, useState } from "react";
import moment from "moment";
import { DATETIME_FORMAT } from "../../../../../config/constants";
import PageSpeedOnDevice from "./components/PageSpeedOnDevice";
import { IGooglePageSpeed } from "../../../../../models/google/google.page.speed";
import IconDesktop from "../../../../../assets/images/common/icon_desktop_line_grey_audit.svg";
import IconPhone from "../../../../../assets/images/common/icon_phone_line_grey_audit.svg";
import IconDesktopActive from "../../../../../assets/images/common/icon_desktop_fill_blue_audit.svg";
import IconPhoneActive from "../../../../../assets/images/common/icon_phone_fill_blue_audit.svg";
import AuditReportService from "../../../../../services/api/audit.report.service";
import {
  EAuditErrorStatus,
  ESectionOfAuditReport,
} from "../../../../../models/common/models.enum";
import { useSelector } from "react-redux";
import { IStateApp } from "../../../../../models/redux";
import { IGetReportsRes } from "../../../../../models/report";

enum EStrategyTab {
  desktop,
  mobile,
}

const SecuritySection = forwardRef<any>(function (props, ref) {
  const dataReport = useSelector<IStateApp, IGetReportsRes>(
    appState => appState.project?.dataReport
  );

  const [activeTab, setActiveTab] = useState<EStrategyTab>(
    EStrategyTab.desktop
  );
  const [pageSpeedDesktopData, setPageSpeedDesktopData] =
    useState<IGooglePageSpeed>();
  const [pageSpeedMobileData, setPageSpeedMobileData] =
    useState<IGooglePageSpeed>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!!dataReport.target) {
      checkPerformance();
    }
  }, [dataReport]);

  const checkPerformance = async () => {
    // const language = "vi"; //Lang.language.toLowerCase();
    setIsLoading(true);
    const res = await AuditReportService.getAuditReportSection(
      ESectionOfAuditReport.PAGESPEED,
      dataReport.thirdPartyId
    );

    if (!res.isError && !!res.data) {
      setPageSpeedDesktopData(res.data);
      setIsLoading(false);
      if (activeTab !== EStrategyTab.desktop) {
        setActiveTab(EStrategyTab.desktop);
      }
    }
    // if (!res.isError && !!res.data.id) {
    //   setPageSpeedMobileData(mobileRes.data);
    //   if (!desktopRes.data?.id && activeTab !== EStrategyTab.mobile) {
    //     setActiveTab(EStrategyTab.mobile);
    //   }
    // }
  };

  const score = (pageSpeedData?: IGooglePageSpeed) => {
    let value = 0;
    if (pageSpeedData) {
      value =
        (pageSpeedData.lighthouseResult.categories.accessibility?.score +
          pageSpeedData.lighthouseResult.categories.accessibility?.score +
          pageSpeedData.lighthouseResult.categories.accessibility?.score +
          pageSpeedData.lighthouseResult.categories.accessibility?.score) /
        4;
    }

    return value;
  };

  const desktopScore = score(pageSpeedDesktopData);
  const mobileScore = score(pageSpeedMobileData);

  const crawlPageData: {
    status: boolean;
    title: string;
    description: string;
    data?: {
      typeError: EAuditErrorStatus;
      title: string;
      dataArray?: React.ReactNode;
      recommendAction: any;
      infor?: string;
    }[];
  }[] = [
    {
      status: false,
      title: "Xây dựng bộ nhận diện thương hiệu",
      description:
        "Bộ nhận diện thương hiệu (Brand Identity) là tập hợp các yếu tố đồ họa và hình ảnh đại diện cho một thương hiệu. Bộ nhận diện thương hiệu bao gồm logo, màu sắc chủ đạo, font chữ, slogan, hình ảnh, âm thanh,...",
      data: [
        {
          typeError: EAuditErrorStatus.Success,
          title: "Có logo",
          dataArray: (
            <ul className="crawl-link-list">
              <li>
                <a
                  href={"http://www.labdrill.com"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.labdrill.com
                </a>
              </li>
            </ul>
          ),
          recommendAction: (
            <ul>
              <li>
                Logo cần khác biệt so với các logo khác trên thị trường để tạo
                ấn tượng và dễ dàng ghi nhớ.{" "}
              </li>
              <li>
                Thiết kế Logo phù hợp với ngành nghề, đối tượng khách hàng và
                giá trị cốt lõi của thương hiệu.
              </li>
            </ul>
          ),
          infor:
            "Một logo được thiết kế tốt giúp truyền tải những thông điệp, giá trị cốt lõi và tính cách của thương hiệu.",
        },
        {
          typeError: EAuditErrorStatus.Success,
          title: "Có banner",
          dataArray: (
            <ul className="crawl-link-list">
              <li>
                <a
                  href={"http://www.labdrill.com"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.labdrill.com
                </a>
              </li>{" "}
              <li>
                <a
                  href={"http://www.labdrill.com"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.labdrill.com
                </a>
              </li>{" "}
              <li>
                <a
                  href={"http://www.labdrill.com"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.labdrill.com
                </a>
              </li>
            </ul>
          ),
          recommendAction: (
            <ul>
              <li>
                Chọn kích thước banner phù hợp với vị trí đặt banner trên
                website.
              </li>
              <li>
                Lưu ý định dạng file banner để đảm bảo banner hiển thị rõ nét và
                không bị vỡ.
              </li>
              <li>
                Màu sắc banner phù hợp với thương hiệu và tạo sự tương phản để
                thu hút sự chú ý.
              </li>
            </ul>
          ),
          infor:
            "Banner là những hình ảnh quảng cáo trực tuyến có kích thước tiêu chuẩn, giúp truyền tải thông điệp một cách nhanh chóng và hiệu quả, đồng thời tăng độ nhận diện thương hiệu cho doanh nghiệp.",
        },
        {
          typeError: EAuditErrorStatus.Error,
          title: "Favicon",
          recommendAction:
            "Favicon nên đơn giản, dễ nhớ và có thể nhận biết được ngay cả ở kích thước nhỏ. Chọn màu sắc Favicon phù hợp với màu sắc chủ đạo của website",
          infor:
            "Favicon là một biểu tượng nhỏ, được hiển thị trên thanh địa chỉ của trình duyệt web, bên cạnh tên website giúp người dùng dễ dàng nhận ra website của bạn. Một favicon được thiết kế đẹp mắt và phù hợp với thương hiệu sẽ tạo ấn tượng chuyên nghiệp và đáng tin cậy.",
        },
      ],
    },
    {
      status: true,
      title: "Giao diện rõ ràng trực quan",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua d tempor incididunt ut labore et dolore magna aliqua",
      data: [
        {
          typeError: EAuditErrorStatus.Success,
          title: "Kích cỡ chữ tối thiểu",
          dataArray: (
            <ul className="crawl-link-list">
              <li>
                <p className="crawl-text">10px</p>
              </li>
            </ul>
          ),
          recommendAction: "",
        },
        {
          typeError: EAuditErrorStatus.Success,
          title: "Khoảng cách dòng",
          dataArray: (
            <ul className="crawl-link-list">
              <li>
                <p className="crawl-text">12px</p>
              </li>
            </ul>
          ),
          recommendAction: "",
        },
        {
          typeError: EAuditErrorStatus.Error,
          title: "Số lượng font chữ",
          dataArray: (
            <ul className="crawl-link-list">
              <li>
                <p className="crawl-text">3px</p>
              </li>
            </ul>
          ),
          recommendAction: "",
        },
        {
          typeError: EAuditErrorStatus.Success,
          title: "Kích cỡ nút bấm",
          dataArray: (
            <ul className="crawl-link-list">
              <li>
                <p className="crawl-text">12px</p>
              </li>
              <li>
                <p className="crawl-text">24px</p>
              </li>
              <li>
                <p className="crawl-text">32px</p>
              </li>
            </ul>
          ),
          recommendAction: "",
        },
      ],
    },
    {
      status: true,
      title: "Khả năng điều hướng người dùng",
      description:
        "Khả năng điều hướng người dùng là một hệ thống hướng dẫn người dùng thực hiện các hoạt động trên website. Một hệ thống điều hướng đơn giản giúp người dùng dễ dàng di chuyển trên website của bạn, giúp họ biết đang ở trang nào trên website, có thể đi đến trang nào cũng như dễ dàng quay lại các trang mong muốn.",
      data: [
        {
          typeError: EAuditErrorStatus.Success,
          title: "Liên kết có màu phân biệt",
          infor:
            "Liên kết là các văn bản được gắn link dẫn đến các nội dung khác trên website hoặc dẫn đến các website khác. Sử dụng màu sắc phân biệt giúp ngừời dùng dễ dàng nhận ra đâu là liên kết và đâu là văn bản thường.",
          recommendAction:
            "Sử dụng màu sắc cho phần văn bản liên kết khác với các phần văn bản xung quanh. Có thể chọn màu xanh lam làm màu mặc định cho liên kết hoặc chọn màu liên kết phù hợp với màu chủ đạo của website.",
        },
        {
          typeError: EAuditErrorStatus.Success,
          title: "Có khung search",
          recommendAction: (
            <ul>
              <li>
                Đặt khung tìm kiếm tại vị trí dễ dàng nhìn thấy và truy cập.
              </li>
              <li>
                Cung cấp các gợi ý tìm kiếm liên quan để giúp người dùng tìm
                thấy kết quả nhanh hơn.
              </li>
              <li>
                Đảm bảo kết quả tìm kiếm hiển thị đúng và liên quan đến từ khóa
                tìm kiếm của người dùng.
              </li>
            </ul>
          ),
          infor:
            "Một khung tìm kiếm ở vị trí dễ thấy trên trang web cho phép người dùng nhập vào những từ khóa họ mong muốn từ đó giúp người dùng tìm kiếm thông tin một cách nhanh chóng và dễ dàng.",
        },
        {
          typeError: EAuditErrorStatus.Error,
          title: "Có breadcrumbs",
          infor:
            "Breadcrumb thường được hiển thị dưới dạng một chuỗi các liên kết, giúp người dùng xác định vị trí của họ trên website từ đó dễ dàng di chuyển giữa các trang đồng thời giúp các công cụ tìm kiếm hiểu rõ hơn về cấu trúc của website, từ đó cải thiện khả năng xếp hạng trên kết quả tìm kiếm.",
          recommendAction: (
            <ul>
              <li>
                Đặt breadcrumb ở vị trí dễ thấy thường là phía trên tiêu đề bài
                viết hoặc phía dưới thanh menu.
              </li>
              <li>
                Sử dụng màu sắc tương phản để làm nổi bật breadcrumb so với phần
                còn lại của trang.
              </li>
              <li>
                Sử dụng biểu tượng mũi tên, dấu gạch ngang hoặc dấu chấm để phân
                cách các liên kết.
              </li>
            </ul>
          ),
        },
        {
          typeError: EAuditErrorStatus.Success,
          title: "Có nút quay lại đầu trang",
          infor:
            "Nút quay lại đầu trang (thường được biểu thị bằng một mũi tên hướng lên) cho phép người dùng nhanh chóng quay trở lại đầu trang chỉ bằng một cú click chuột.",
          recommendAction: (
            <ul>
              <li>
                Đặt nút quay lại đầu trang ở vị trí góc dưới bên phải màn hình -
                đây là vị trí người dùng thường đưa chuột đến khi muốn di chuyển
                lên đầu trang.
              </li>
              <li>
                Có thể tạo dạng nút tĩnh (cố định) hoặc nút động (Nút chỉ xuất
                hiện khi người dùng cuộn xuống dưới một ngưỡng nhất định).
              </li>
            </ul>
          ),
        },
        {
          typeError: EAuditErrorStatus.Success,
          title: "Có phân trang",
          infor:
            "Phân trang là việc chia nhỏ nội dung trên một trang  thành các trang con, giúp giảm thiểu tốc độ tải trang đồng thời giúp người dùng dễ dàng tìm kiếm và tập trung vào thông tin họ quan tâm mà không bị quá tải bởi lượng thông tin quá lớn.",
          recommendAction: (
            <ul>
              <li>
                Sử dụng phân trang cho các trang có nội dung lớn như Blog tin
                tức hoặc danh mục sản phẩm.
              </li>
              <li>
                Hiển thị danh sách các trang con tại cuối mỗi trang cho phép
                người dùng chọn trang muốn xem.
              </li>
              <li>
                {" "}
                <strong>Lưu ý:</strong> Sử dụng thẻ rel="next" và rel="prev" -
                Các thẻ này giúp các công cụ tìm kiếm hiểu rõ mối quan hệ giữa
                các trang con.
              </li>
            </ul>
          ),
        },
        {
          typeError: EAuditErrorStatus.Success,
          title: "Có nút call to action",
          infor: `Nút Call to Action (CTA) hay "nút kêu gọi hành động", là một yếu tố quan trọng trên các website giúp hướng dẫn người dùng thực hiện một hành động cụ thể sau khi xem trang web như liên hệ, mua hàng hay tìm hiểu thêm thông tin,...`,
          recommendAction: (
            <ul>
              <li>
                Nút CTA nên được đặt ở vị trí dễ thấy, thường là ở trên cùng
                hoặc dưới cùng của trang, hoặc ngay bên cạnh nội dung chính.
              </li>
              <li>
                Nút CTA nên có kích thước đủ lớn để người dùng dễ dàng nhấp vào.
              </li>
              <li>
                Sử dụng những từ ngữ ngắn gọn, mạnh mẽ và rõ ràng, như "Mua
                ngay", "Đăng ký miễn phí", "Tìm hiểu thêm"
              </li>
            </ul>
          ),
        },
        {
          typeError: EAuditErrorStatus.Success,
          title: "Có menu",
          infor:
            "Menu là một tập hợp các liên kết trên website được sắp xếp một cách logic và hệ thống, giúp người dùng dễ dàng nắm bắt cấu trúc của website từ đó dễ dàng tìm thấy sản phẩm, dịch vụ hoặc thông tin cần thiết.",
          recommendAction: (
            <ul>
              <li>
                Đặt menu ở vị trí thích hợp: menu ngang (thường đặt ở đầu trang)
                hoặc menu dọc (thường ở bên trái).
              </li>
              <li>
                Các mục trong menu nên được sắp xếp theo logic nhất định, thường
                là theo thứ tự quan trọng hoặc theo cấu trúc của website.
              </li>
            </ul>
          ),
        },
      ],
    },
    {
      status: true,
      title: "Thân thiện với di động",
      description:
        "Hiện nay khách hàng dành rất nhiều thời gian trên di động. Việc tối ưu trang web thân thiện với di động là một yếu tố quan trọng giúp gia tăng trải nghiệm của khách hàng cũng như cải thiện vị trí website trên công cụ tìm kiếm.",
    },
    {
      status: true,
      title: "Trang 404 tuỳ chỉnh",
      description:
        "Trang 404 là một trang thông báo lỗi khi người dùng truy cập vào một trang không tồn tại trên website. Khi người dùng truy cập vào một trang không tồn tại trên website, họ dễ cảm thấy thất vọng và muốn rời đi. Việc thiết kế một trang 404 tùy chỉnh giúp cải thiện trải nghiệm người dùng, giúp giữ chân họ ở lại và thu hút họ bấm vào xem các nội dung mong muốn khác trên website.",
    },
    {
      status: true,
      title: "Hình ảnh chất lượng cao",
      description:
        "Hình ảnh chất lượng, sắc nét giúp thu hút sự chú ý, tạo ấn tượng chuyên nghiệp, đáng tin cậy đối với người dùng về thông tin hoặc sản phẩm, dịch vụ mà website đang cung cấp",
    },
  ];

  return (
    <SecuritySectionWrapper
      className="common-layout"
      id={"security-section"}
      ref={ref}
    >
      <OverviewCommonTitle
        title="Trải nghiệm người dùng"
        description="Trải nghiệm người dùng đề cập đến quá trình tương tác của người dùng trên website từ việc tìm kiếm thông tin, mua sắm và các hành động khác. Một website được thiết kế với giao diện trực quan, dễ sử dụng, tốc độ nhanh chóng sẽ mang đến những trải nghiệm tốt cho người dùng từ đó giúp gia tăng tỉ lệ chuyển đổi, nâng cao uy tín thương hiệu và cải thiện thứ hạng website trên công cụ tìm kiếm."
        className="security-title"
        scores={((desktopScore + mobileScore) / 2) * 100}
      />
      <div className="security-wrapper">
        <OverviewCommonSubTitle title="Tốc độ Website" />
        <Quote
          content="Tốc độ website là thời gian mà một trang web mất để tải hoàn toàn trên trình duyệt của người dùng. Một Website được tối ưu tốt về tốc độ giúp người dùng có trải nghiệm tốt hơn đồng thời tăng thứ hạng SEO và tỷ lệ chuyển đổi."
          sx={{
            marginTop: "15px",
            borderRadius: "8px",
          }}
        />

        <div className="security-index-wrapper">
          <div className="security-index-box">
            <Box
              className="flex-between-align-center"
              sx={{ width: "100%", padding: "24px" }}
            >
              <OverviewCommonCaption
                className="index-box-caption"
                width="420px"
                caption="Tốc độ website trên loại thiết bị"
                description="Tùy vào mỗi loại thiết bị khác nhau như máy tính hay điện thoại di động, tốc độ tải trang có thể chênh lệch đáng kể do sự khác biệt về cấu hình phần cứng, kết nối mạng và kích thước màn hình. Điều này đòi hỏi website cần được tối ưu hóa nhằm đảm bảo trải nghiệm người dùng tốt nhất trên mọi thiết bị."
              />

              <ImageCustom
                src={Google}
                alt="Google logo"
                width={92}
                height={53}
              />
            </Box>
            <Divider />
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              <Box className="flex-center" sx={{ mt: "32px" }}>
                <ScreenshotOnDevice
                  strategy={EStrategy.desktop}
                  score={desktopScore}
                  screenshot={
                    pageSpeedDesktopData?.lighthouseResult?.fullPageScreenshot
                      .screenshot.data
                  }
                />
                <ScreenshotOnDevice
                  strategy={EStrategy.mobile}
                  score={mobileScore}
                  screenshot={
                    pageSpeedMobileData?.lighthouseResult?.fullPageScreenshot
                      .screenshot.data
                  }
                />
              </Box>
            )}
          </div>
        </div>
      </div>

      {(!!pageSpeedDesktopData || !!pageSpeedMobileData) && (
        <Box className="performance-on-strategy-wrapper">
          <FullWidthTabs
            className="tabs"
            time={moment(pageSpeedDesktopData?.analysisUTCTimestamp).format(
              DATETIME_FORMAT
            )}
            tabs={[
              {
                lable: {
                  text: "Máy tính",
                  icon: IconDesktop,
                  iconActive: IconDesktopActive,
                },
                value: EStrategyTab.desktop,
                content: (
                  <PageSpeedOnDevice
                    strategy={EStrategy.desktop}
                    pageSpeedData={pageSpeedDesktopData}
                  />
                ),
              },
              {
                lable: {
                  text: "Di động",
                  icon: IconPhone,
                  iconActive: IconPhoneActive,
                },
                value: EStrategyTab.mobile,
                content: (
                  <PageSpeedOnDevice
                    strategy={EStrategy.mobile}
                    pageSpeedData={pageSpeedMobileData}
                  />
                ),
              },
            ]}
            value={activeTab}
            onChange={tab => setActiveTab(tab)}
          />
        </Box>
      )}
      <div className="error-crawl-page-wrapper">
        {crawlPageData.map((item, i) => (
          <CrawlPageDataBox
            key={i}
            status={item.status}
            title={item.title}
            description={item.description}
            data={item.data}
          />
        ))}
      </div>
    </SecuritySectionWrapper>
  );
});
export default SecuritySection;

interface CrawlPageDataBoxProps {
  status: boolean;
  title: string;
  description: string;
  data?: {
    typeError: EAuditErrorStatus;
    title: string;
    dataArray?: React.ReactNode;
    infor?: string;
    recommendAction?: any;
  }[];
}

const CrawlPageDataBox = (props: CrawlPageDataBoxProps) => {
  const { data, status, title, description } = props;

  return (
    <div className="box-wrapper">
      <div className="box-header flex-between-align-center">
        <Typography variant="subtitle2">{title}</Typography>
        <div className={`header-status ${status ? "passed" : "failed"}`}>
          <p className="status-text">Status</p>
          <p className="status-content">{status ? "Passed" : "Failed"}</p>
        </div>
      </div>
      <p className="box-description">{description}</p>
      {!!data && (
        <ul className="list-box">
          {data.map(item => (
            <li key={item.title}>
              <CollapseHandleError
                typeError={item.typeError}
                title={item.title}
                type={"crawl"}
                dataArray={item.dataArray}
                infor={item.infor}
                recommendAction={item.recommendAction}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
