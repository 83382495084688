import React from "react";
import {
  BarChartCustom,
  PieChartDonutShape,
} from "../../../../../../../../../components";
import variableStyles from "../../../../../../../../../theme/variable-styles";
import OverviewCommonCaption from "../../../../../../OverviewCommonCaption";
import { BackLinkChartWrapper } from "./styles";
import IconChart from "../../../../../../../../../assets/images/common/icon_chart_line_green.svg";

interface BackLinkChartProps {
  title: string;
  infor: string;
  description: string;
  barChartData?: {
    listKeyField: string[];
    chartData: any[];
    YAxisKey: string;
    XAxisKey?: string;
    fieldTextList?: {
      value: string;
      color: string;
    }[];
    height: string;
  };
  pieChartData?: { listKeyField: string[]; chartData: any[] };
}

export default function BackLinkChartBox(props: {
  data: BackLinkChartProps;
  colorFillList?: string[];
}) {
  const { data, colorFillList = [variableStyles.GreenPrimaryColor400] } = props;
  return (
    <BackLinkChartWrapper>
      <OverviewCommonCaption
        className="header-title"
        width="100%"
        caption={data.title}
        icon={IconChart}
        description={data.description}
      />
      <div className="chart-wrapper">
        {!!data.pieChartData ? (
          <PieChartDonutShape data={data.pieChartData.chartData} />
        ) : (
          <>
            {!!data.barChartData && (
              <BarChartCustom
                data={data.barChartData.chartData}
                listKeyField={data.barChartData.listKeyField}
                YAxisKey={data.barChartData.YAxisKey}
                XAxisKey={data.barChartData.XAxisKey}
                XAxisType="number"
                layout="vertical"
                height={data.barChartData.height}
                reversedYAxis
                colorFillList={colorFillList}
                fieldTextList={data.barChartData.fieldTextList}
                colorTextList={[variableStyles.NaturalColor0]}
                isHasLabelList={true}
              />
            )}
          </>
        )}
      </div>
    </BackLinkChartWrapper>
  );
}
