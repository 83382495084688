import React, { useEffect, useState } from "react";
import { CollapseHandleErrorWrapper, TableDataWrapper } from "./styles";
import IconError from "../../assets/images/common/icon_error_fill_red_border.svg";
import IconWarning from "../../assets/images/common/icon_warning_fill_yellow_border.svg";
import IconInforBorder from "../../assets/images/common/icon_infor_fill_blue_border.svg";
import IconArrow from "../../assets/images/common/icon_arrow_down_line_black.svg";
import IconInfor from "../../assets/images/common/icon_infor_line_grey.svg";
import IconSuccess from "../../assets/images/common/icon_success_fill_green_border.svg";
import {
  ImageCustom,
  LoadingIndicator,
  Modal,
  TableNavDetailNoPagination,
} from "..";
import {
  Collapse,
  Stack,
  SxProps,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
} from "@mui/material";
import { EAuditErrorStatus } from "../../models/common/models.enum";
import ImageNoData from "../../assets/images/common/img_project_empty.png";
import AuditReportService from "../../services/api/audit.report.service";
import { IBasePagingRes } from "../../models/common/models.type";
import { DEFAULT_BASE_PAGING_RES } from "../../common/constants/value.constant";
import { useSelector } from "react-redux";
import { IStateApp } from "../../models/redux";
import { IGetReportsRes } from "../../models/report";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../common/constants/app.constant";
import queryString from "query-string";

export interface ITableHeader<T> {
  field?: keyof T;
  title?: string;
  handleItem?: (item: T) => React.ReactNode;
  align?: "left" | "right" | "center";
  notFilter?: boolean;
  width?: number | string;
  sx?: SxProps<Theme>;
  className?: string;
  isSticky?: boolean;
}

interface CollapseHandleErrorProps {
  headersTable?: ITableHeader<any>[];
  typeError: EAuditErrorStatus;
  title: string;
  isStickyOrder?: boolean;
  filter?: {
    key: string;
    value: string;
    operator: string;
    type: string;
  }[];
  type?: string;
  isOpenKey?: string;
  keyFilter?: string;
  isNavigateToLink?: boolean;
  dataArray?: React.ReactNode;
  recommendAction?: any;
  infor?: string;
  navigatePath?: string;
  navigateFilter?: string;
  data?: any[];
}

export default function CollapseHandleError(props: CollapseHandleErrorProps) {
  const {
    headersTable,
    typeError,
    title,
    isStickyOrder,
    filter,
    type,
    isOpenKey,
    keyFilter,
    isNavigateToLink = false,
    dataArray,
    recommendAction,
    infor,
    navigatePath,
    navigateFilter,
    data,
  } = props;

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resDataPaging, setResDataPaging] = useState<IBasePagingRes<any>>({
    ...DEFAULT_BASE_PAGING_RES,
  });
  const [resData, setResData] = useState<any[] | undefined>();
  const [openModal, setOpenModal] = useState<boolean | null>(false);

  const dataReport = useSelector<IStateApp, IGetReportsRes>(
    appState => appState.project?.dataReport
  );

  useEffect(() => {
    if (!!isOpenKey && !!keyFilter && isOpenKey === keyFilter) {
      setIsOpen(true);
    }
  }, [isOpenKey]);

  useEffect(() => {
    if (!!isOpen && !resData && !isLoading) {
      getDetailError();
      console.log("first");
    }
  }, [isOpen]);

  useEffect(() => {
    if (!!data && data.length > 0) {
      setResData(data);
    }
  }, [data]);

  const getDetailError = async () => {
    if (!resData || (resData.length === 0 && !!dataReport.thirdPartyId)) {
      setIsLoading(true);
      if (!!type && type === "page" && !!filter) {
        let params = {
          limit: 30,
          id: dataReport.thirdPartyId,
          filters: filter,
          offSet: 0,
        };
        const res = await AuditReportService.getErrorsFromPages(params);
        if (!res.isError) {
          setResData(res.data.items);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else if (!!type && type === "links" && !!filter) {
        const params = {
          limit: 30,
          id: dataReport.thirdPartyId,
          filters: filter,
          page_from: "",
          page_to: "",
        };
        const res = await AuditReportService.getErrorsFromLink(params);
        if (!res.isError) {
          setResData(res.data.items);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else if (!!type && type === "resources" && !!filter) {
        const params = {
          limit: 30,
          id: dataReport.thirdPartyId,
          filters: filter,
        };
        const res = await AuditReportService.getDomainAuditResources(params);
        if (!res.isError && !!res.data) {
          setResData(res.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    }
  };

  const handleChangePage = (page: number) => {
    if (!!resData) {
      const newData = {
        page: page,
        pageSize: 10,
        total: resData.length,
        items: [...resData]?.slice(page * 10 - 10, page * 10),
        isFull: true,
      };
      setResDataPaging(newData);
    }
  };

  return (
    <>
      <Modal
        title={title}
        open={!!openModal}
        width={"744px"}
        onClose={() => setOpenModal(null)}
        textSubmit="Xem tất cả"
        onSubmit={
          !navigatePath || !navigateFilter
            ? undefined
            : () => {
                const path = generatePath(navigatePath, {
                  id: dataReport.auditId,
                });
                // navigate({
                //   pathname: `${path}`,
                //   search: `${navigateFilter?.toLocaleLowerCase()}`,
                // });

                const newWindow = window.open(
                  `${path}?${navigateFilter?.toLocaleLowerCase()}`,
                  "_blank",
                  "noopener,noreferrer"
                );
                if (newWindow) newWindow.opener = null;
              }
        }
        maxheight="calc(100vh - 88px)"
      >
        {!!headersTable && (
          <TableNavDetailNoPagination
            headersTable={headersTable}
            data={resDataPaging}
            handleViewMore={() => {}}
            isFullTable
            handlePageChange={page => {
              handleChangePage(page);
            }}
            maxHeight={"calc(100vh - 280px)"}
          />
        )}
      </Modal>
      <CollapseHandleErrorWrapper>
        <div
          className={`collapse-header flex-between-align-center ${
            !!filter ||
            (!!headersTable && headersTable.length > 0) ||
            !!dataArray
              ? ""
              : "not-collapse"
          }`}
          onClick={() => {
            if (
              !!filter ||
              (!!headersTable && headersTable.length > 0) ||
              !!dataArray
            ) {
              setIsOpen(!isOpen);
            }
          }}
        >
          <div className="header-content flex-start-align-center">
            {typeError !== EAuditErrorStatus.Content && (
              <ImageCustom
                src={
                  typeError === EAuditErrorStatus.Error
                    ? IconError
                    : typeError === EAuditErrorStatus.Warning
                      ? IconWarning
                      : typeError === EAuditErrorStatus.Success
                        ? IconSuccess
                        : IconInforBorder
                }
                alt="Icon-Issue"
                width={"22px"}
                height={"22px"}
              />
            )}
            <div className="header-text-wrapper">
              <p className="header-text fit-one-line">{title}</p>
              {!!infor && (
                <Tooltip title={infor} placement="top-start">
                  <div>
                    <ImageCustom
                      src={IconInfor}
                      alt="Icon-Infor"
                      width={"14px"}
                      height={"14px"}
                    />
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
          <div className="header-action flex-end-align-center">
            {!!recommendAction && (
              <Tooltip title={recommendAction} placement="left-start">
                <div>
                  <p className="action-recommend">Recommend action</p>
                </div>
              </Tooltip>
            )}
            <figure
              className={`${(!!filter && !!headersTable && headersTable.length > 0) || !!dataArray ? "" : "hidden-arrow"} ${isOpen ? "active" : ""} arrow-collapse`}
            >
              <ImageCustom
                src={IconArrow}
                alt="Icon-Arrow"
                width={"22px"}
                height={"22px"}
              />
            </figure>
          </div>
        </div>
        {((!!headersTable && headersTable.length > 0) || !!dataArray) && (
          <Collapse in={isOpen}>
            {!!dataArray && dataArray}
            {!!headersTable && (
              <div className="table-wrapper">
                <TableContainer
                  sx={{
                    borderRadius: "6px 6px 0 0",
                    scrollBehavior: "smooth !important",
                  }}
                  className="scrollbar"
                >
                  <TableDataWrapper stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={`table-cell-order ${isStickyOrder ? "sticky-column" : ""}`}
                        >
                          STT
                        </TableCell>
                        {headersTable.map((item, idx) => (
                          <TableCell
                            key={idx}
                            sx={{
                              ...item.sx,
                              width: item.width,
                            }}
                            className={`${item.className} ${item.isSticky ? "sticky-column" : ""}`}
                          >
                            {item.title}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    {isLoading ? (
                      <TableRow className="box-empty-wrapper">
                        <TableCell colSpan={headersTable.length + 1}>
                          <Stack sx={{ width: "100%" }}>
                            <div className="loading-box">
                              <LoadingIndicator />
                            </div>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <>
                        {!!resData && resData.length > 0 ? (
                          <TableBody>
                            {resData.slice(0, 5).map((item, idx) => (
                              <TableRow
                                hover
                                key={idx}
                                className={`${
                                  (!item.compareWith ||
                                    (!!item.compareWith &&
                                      item.compareWith.length <= 0)) &&
                                  !item.keyword
                                    ? "not-collapse"
                                    : ""
                                }`}
                              >
                                <TableCell
                                  className={`table-cell-order ${isStickyOrder ? "sticky-column" : ""}`}
                                >
                                  {idx + 1}
                                </TableCell>
                                {headersTable.map((field, key) => {
                                  if (field.handleItem) {
                                    return (
                                      <TableCell
                                        key={key}
                                        sx={{
                                          ...field.sx,
                                          textAlign: field.align,
                                          width: field.width,
                                        }}
                                        className={`${field.className}  ${field.isSticky ? "sticky-column" : ""}`}
                                      >
                                        {field.handleItem(item)}
                                      </TableCell>
                                    );
                                  } else {
                                    return (
                                      <TableCell
                                        sx={{
                                          ...field.sx,
                                          textAlign: field.align,
                                          width: field.width,
                                        }}
                                        className={`${field.className}  ${field.isSticky ? "sticky-column" : ""}`}
                                        key={key}
                                      >
                                        {field.field ? item[field.field] : null}{" "}
                                      </TableCell>
                                    );
                                  }
                                })}
                              </TableRow>
                            ))}
                          </TableBody>
                        ) : (
                          <TableBody>
                            <TableRow className="box-empty-wrapper">
                              <TableCell colSpan={headersTable.length + 1}>
                                <Stack sx={{ width: "100%" }}>
                                  <div
                                    className={"box-empty flex-column-center"}
                                  >
                                    <ImageCustom
                                      src={ImageNoData}
                                      alt="Icon-NoData"
                                      width={"120px"}
                                      height={"120px"}
                                    />
                                    <p className={"text-empty"}>No data</p>
                                  </div>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                      </>
                    )}
                  </TableDataWrapper>
                </TableContainer>
                {!!resData && resData.length > 10 && (
                  <button
                    onClick={() => {
                      if (
                        (type === "page" || type === "resources") &&
                        !isNavigateToLink
                      ) {
                        setOpenModal(true);
                        handleChangePage(1);
                      } else {
                        const newParams = queryString.stringify({
                          tab: "inlink",
                          follow: "follow",
                          code: "404",
                        });
                        const path = generatePath(
                          ROUTE_PATH.DOMAIN_AUDIT_ERROR_DETAIL,
                          {
                            id: dataReport.auditId,
                          }
                        );
                        const newWindow = window.open(
                          `${path}?${newParams}`,
                          "_blank",
                          "noopener,noreferrer"
                        );
                        if (newWindow) newWindow.opener = null;
                      }
                    }}
                    className="view-more-button"
                  >
                    {type === "page" || type === "resources"
                      ? "Xem thêm"
                      : "Xem tất cả"}
                  </button>
                )}
              </div>
            )}
          </Collapse>
        )}
      </CollapseHandleErrorWrapper>
    </>
  );
}
