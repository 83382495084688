import { styled } from "@mui/material/styles";
import variableStyles from "../../../../../../../../../../theme/variable-styles";

export const TableDetailWrapper = styled("div")(({}) => ({
  border: `1px solid ${variableStyles.NaturalColor200}`,
  borderRadius: "6px",
  boxShadow: "0px 0px 18.3px 0px #0000000A",
  marginBottom: "10px",
  "& .MuiTableHead-root": {
    "& .MuiTableCell-root": {
      color: variableStyles.NaturalColor1000,
      fontWeight: variableStyles.fwMedium,
      fontSize: "14px",
      lineHeight: "20px",
      padding: "10px 12px",
      border: `none`,
      backgroundColor: variableStyles.NaturalColor300,
      "&.align-end": {
        textAlign: "right",
      },
    },
  },
  "& .MuiTableBody-root": {
    "& .MuiTableRow-root": {
      backgroundColor: variableStyles.NaturalColor0,
      "&:hover": {
        backgroundColor: variableStyles.NaturalColor100,
        "& .sticky-column": {
          backgroundColor: `${variableStyles.NaturalColor0} !important`,
        },
      },
      "&:last-of-type": {
        "& .MuiTableCell-root": {
          borderBottom: `none !important`,
        },
      },
    },
    "& .MuiTableCell-root": {
      color: variableStyles.InfoA700,
      fontWeight: variableStyles.fwMedium,
      fontSize: "10px",
      lineHeight: "15px",
      padding: "6px 12px",
      height: "50px",
      borderBottom: `1px solid ${variableStyles.NaturalColor200}`,
      "&.align-end": {
        textAlign: "right",
        "&.table-cell-error-tag, & .table-cell-error-tag": {
          "& p": {
            marginLeft: " auto",
          },
        },
        "&.table-cell-success-tag, & .table-cell-success-tag": {
          "& p": {
            marginLeft: " auto",
          },
        },
        "&.table-cell-text-tag": {
          "& p": {
            marginLeft: " auto",
          },
        },
      },
    },
  },
}));
