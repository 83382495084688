import React from "react";
import { EReportType } from "../../models/common/models.enum";
import { ReportTagsWrapper } from "./styles";

interface ReportTagsProps {
  type: EReportType;
}

export default function ReportTypeTags(props: ReportTagsProps) {
  const { type } = props;

  const renderText = () => {
    switch (type) {
      case EReportType.DomainAudit:
        return "Domain audit";
      case EReportType.DomainCompetitive:
        return "Domain Competitive";
      case EReportType.PageAudit:
        return "Page audit";
      default:
        return "Page Competitive";
    }
  };
  const renderClassname = () => {
    switch (type) {
      case EReportType.DomainAudit:
        return "blue";
      case EReportType.DomainCompetitive:
        return "green";
      case EReportType.PageAudit:
        return "grey";
      default:
        return "yellow";
    }
  };
  return (
    <ReportTagsWrapper className={renderClassname()}>
      {renderText()}
    </ReportTagsWrapper>
  );
}
