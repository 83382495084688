import { useMediaQuery } from "@mui/material";
import moment from "moment";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { CheckBoxCustom } from "../..";
import {
  AreaChartColors,
  CheckBoxIconList,
} from "../../../common/constants/value.constant";
import { IChartValue, IValue } from "../../../models/common/models.type";

import variableStyles from "../../../theme/variable-styles";
import { AreaChartCustomWrapper } from "./styles";
import Icon3 from "../../../assets/images/checkbox/icon_checkbox_3.svg";
import IconActive3 from "../../../assets/images/checkbox/icon_checkbox_active_3.svg";

interface AreaChartCustomProps {
  data: IChartValue[];
  listKeyField: IValue[];
  XAxisKey: string;
  YAxisKey?: string;
  listFilter: string[];
  height?: string;
  onChangeFilter: (value: string) => void;
  onChangeLine: (value: string[]) => void;
  filterText: string;
  filterValue: string;
  chartLine: string[];
  toolTipText?: string;
}

export default function AreaChartCustom(props: AreaChartCustomProps) {
  const {
    data,
    listKeyField,
    XAxisKey,
    YAxisKey,
    listFilter,
    onChangeFilter,
    filterText,
    filterValue,
    onChangeLine,
    chartLine,
    toolTipText,
    height,
  } = props;

  const renderAreaChart = () => {
    const dynamic_array = listKeyField;

    const areas = dynamic_array
      .filter(item => chartLine.includes(item.value))
      .map(value => {
        const indexIcon = listKeyField.findIndex(
          item => item.value === value.value
        );
        return (
          <Area
            dataKey={value.value}
            stackId="1"
            type="monotone"
            stroke={AreaChartColors[indexIcon]}
            fill={AreaChartColors[indexIcon]}
            fillOpacity={0.3}
            key={value.value}
            strokeWidth={2}
            activeDot={{ fill: `${variableStyles.Warning600}`, strokeWidth: 0 }}
          />
        );
      });
    return areas;
  };

  const onChangeChart = (value: string) => {
    if (chartLine.includes(value)) {
      if (chartLine.length > 1) {
        const newActive = chartLine.filter(chart => chart !== value);
        onChangeLine(newActive);
      }
    } else {
      onChangeLine([...chartLine, value]);
    }
  };
  const CustomTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          {payload.map((item, idx) => {
            const findObject = listKeyField.find(
              itemlist => itemlist.value === item.name
            );
            if (!!findObject) {
              return (
                <p className="YAxis-text" key={idx}>
                  {findObject.title}: {item.value} {toolTipText}
                </p>
              );
            } else {
              return (
                <p className="YAxis-text" key={idx}>
                  {listKeyField[idx].title}: {item.value} {toolTipText}
                </p>
              );
            }
          })}
          <p className="XAxis-text">{label}</p>
        </div>
      );
    }
    return <></>;
  };

  const formatXAxis = (tickFormat: string) => {
    return moment(tickFormat).format("DD/MM/YYYY");
  };

  return (
    <AreaChartCustomWrapper>
      <div className="legend-filter-wrapper">
        <ul className="legend-list flex-start-align-center">
          {listFilter?.map((entry: string, index: any) => (
            <li key={`item-${index}`}>
              <CheckBoxCustom
                onChange={() => {
                  onChangeFilter(entry);
                }}
                label={`${entry} ${filterText}`}
                value={entry}
                iconCheck={Icon3}
                iconCheckActive={IconActive3}
                checked={filterValue === entry}
                width="16px"
                height="16px"
              />
            </li>
          ))}
        </ul>
        <ul className="legend-list">
          {listKeyField?.map((entry: IValue, index: any) => (
            <li key={`item-${index}`}>
              <CheckBoxCustom
                onChange={() => {
                  onChangeChart(entry.value);
                }}
                label={entry.title}
                value={entry.value}
                iconCheck={CheckBoxIconList[index].iconCheck}
                iconCheckActive={CheckBoxIconList[index].iconCheckActive}
                checked={chartLine.includes(entry.value)}
                width="16px"
                height="16px"
              />
            </li>
          ))}
        </ul>
      </div>
      <ResponsiveContainer width="100%" height={height}>
        <AreaChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 5,
            left: 5,
            bottom: 0,
          }}
        >
          <XAxis
            dataKey={XAxisKey}
            tick={{
              color: variableStyles.NaturalColor950,
              fontWeight: variableStyles.fwMedium,
              fontSize: "10px",
            }}
            axisLine={false}
            tickLine={false}
            reversed={true}
            interval="preserveStartEnd"
          />
          <YAxis
            tick={{
              color: variableStyles.NaturalColor800,
              fontWeight: variableStyles.fwRegular,
              fontSize: "10px",
            }}
            tickLine={false}
            axisLine={{
              strokeWidth: 1,
              stroke: variableStyles.NaturalColor200,
            }}
            orientation="right"
          />
          <CartesianGrid
            strokeWidth={1}
            stroke={`${variableStyles.NaturalColor200}`}
            vertical={false}
          />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{
              stroke: `${variableStyles.blueSecondary200}`,
              strokeWidth: 1,
              fill: "transparent",
            }}
          />
          {renderAreaChart()}
        </AreaChart>
      </ResponsiveContainer>
    </AreaChartCustomWrapper>
  );
}
