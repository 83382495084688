import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const ReportTagsWrapper = styled("div")(() => ({
  borderWidth: "1px",
  borderStyle: "solid",
  borderRadius: "14px",
  padding: "3px 8px",
  fontWeight: variableStyles.fwRegular,
  fontSize: "14px",
  lineHeight: "20px",
  textAlign: "center",
  width: "max-content",
  "&.green": {
    borderColor: variableStyles.GreenPrimaryColor600,
    color: variableStyles.GreenPrimaryColor600,
    backgroundColor: variableStyles.GreenPrimaryColor50
  },
  "&.blue": {
    borderColor: variableStyles.blueSecondary700,
    color: variableStyles.blueSecondary700,
    backgroundColor: variableStyles.blueSecondary50
  },
  "&.yellow": {
    borderColor: variableStyles.Warning700,
    color: variableStyles.Warning700,
    backgroundColor: variableStyles.Warning50
  },
  "&.grey": {
    borderColor: variableStyles.NaturalColor800,
    color: variableStyles.NaturalColor800,
    backgroundColor: variableStyles.NaturalColor50
  }
}));
