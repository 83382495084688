import ProgressHalfCircle from "../ProgressHalfCircle";
import {
  AuditRef,
  Audits,
  CategoriesItem,
  CategoryGroups,
  LighthouseResultI18n,
  StackPacks,
} from "../../../../../../../../../models/google/google.page.speed";
import MarkdownRenderer from "../../../../../../../../../components/MarkdownRenderer";
import { useState } from "react";
import OtherGroupAudit from "../OtherGroupAudit";
import GroupAudit from "../GroupAudit";

interface IProps {
  data: CategoriesItem;
  audits: Audits;
  stackPacks: StackPacks[];
  i18n: LighthouseResultI18n;
  categoryGroups: CategoryGroups;
}

const getGroups = (
  auditRefs: AuditRef[],
  categoryGroups: CategoryGroups,
  i18n: LighthouseResultI18n
) => {
  let groupKeys: string[] = [];

  for (let index = 0; index < auditRefs.length; index++) {
    const element = auditRefs[index];
    const keyGroup = element.group ? element.group : "orther";

    if (!groupKeys.includes(keyGroup)) {
      groupKeys.push(keyGroup);
    }
  }
  const groups = groupKeys.map(key => {
    const group = categoryGroups[key];
    const keyGroup = key === "orther" ? undefined : key;

    const childrens = auditRefs.filter(a => a.group === keyGroup);

    const title =
      key === "orther"
        ? i18n.rendererFormattedStrings.notApplicableAuditsGroupTitle
        : key === "hidden"
          ? i18n.rendererFormattedStrings.passedAuditsGroupTitle
          : group?.title;

    return {
      key: key,
      title: title,
      description: group?.description ?? "",
      children: childrens,
    };
  });

  return groups;
};

export const getAuditIds = (auditRefs: AuditRef[]) => {
  if (!auditRefs) {
    return [];
  }
  return auditRefs.filter(f => (f.group ? f.group === "hidden" : false));
};

const OrtherCategorySection = ({
  data,
  stackPacks,
  audits,
  i18n,
  categoryGroups,
}: IProps) => {
  const [showPassed, setShowPassed] = useState<boolean>(false);
  const [showOrther, setShowOrther] = useState<boolean>(false);

  const score = data.score ?? 0;

  const onShowPassed = () => setShowPassed(pre => !pre);
  const onShowOrther = () => setShowOrther(pre => !pre);

  const groups = getGroups(data.auditRefs, categoryGroups, i18n);

  return (
    <div className="orther-category-section">
      <div className="orther-category-wrapper">
        <div className="orther-category-content">
          <ProgressHalfCircle
            value={score}
            lable={data?.title ?? ""}
            size="large"
          />

          {data?.description ? (
            <div className="description">
              <MarkdownRenderer content={data?.description ?? ""} />
              <MarkdownRenderer content={data?.manualDescription ?? ""} />
            </div>
          ) : null}
        </div>
      </div>

      {groups.map(item => {
        if (item.key === "orther") {
          return (
            <OtherGroupAudit
              key={item.key}
              groupTitle={item.title}
              onShow={onShowOrther}
              isShow={showOrther}
              data={item.children}
              audits={audits}
              stackPacks={stackPacks}
            />
          );
        }
        if (item.key === "hidden") {
          return (
            <OtherGroupAudit
              key={item.key}
              groupTitle={item.title}
              onShow={onShowPassed}
              isShow={showPassed}
              data={item.children}
              audits={audits}
              stackPacks={stackPacks}
            />
          );
        }

        return (
          <GroupAudit
            key={item.key}
            groupTitle={item.title}
            data={item.children}
            audits={audits}
            stackPacks={stackPacks}
          />
        );
      })}
    </div>
  );
};

export default OrtherCategorySection;
