import React, { useEffect, useRef, useState } from "react";
import { Button } from "@mui/material";
import moment from "moment";
import queryString from "query-string";
import { Helmet } from "react-helmet";
import {
  generatePath,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  DateRangePickerPopover,
  ImageCustom,
  LoadingIndicator,
  NoDataBox,
  ReportStatusTags,
  ReportTypeTags,
  TableDataGrey,
} from "..";
import { ROUTE_PATH } from "../../common/constants/app.constant";
import { DEFAULT_BASE_PAGING_RES } from "../../common/constants/value.constant";
import { DATETIME_FORMAT, DATE_REQ_FORMAT } from "../../config/constants";
import { IBasePagingRes } from "../../models/common/models.type";
import { IGetReportsReq } from "../../models/report";
import { IGetReportsParams } from "../../pages/MyProject/ProjectReports";
import ReportService from "../../services/api/report.service";
import { toggleConfirmModal } from "../ConfirmModal/ConfirmModal";
import InputSearch from "../controls/InputSearch";
import { toggleCreateReportModal } from "../Modal/CreateReportModal";
import { toggleMessage } from "../Toast/Toast";
import { CommonReportListPageWrapper } from "./styles";
import IconAdd from "../../assets/images/common/icon_plus_line_white.svg";
import { EReportType } from "../../models/common/models.enum";
import { ITableHeader } from "../Table/TableDataGrey";
import { useDispatch } from "react-redux";
import { setReportState } from "../../redux/project/project.duck";
import { REPORT_ID_KEY } from "../../services/local/local-storage";

interface CommonReportListPageProps {
  type?: EReportType;
}

const DEFAULT_QUERY = {
  pageIndex: 1,
  pageSize: 10,
};

export default function CommonReportListPage(props: CommonReportListPageProps) {
  const { type } = props;
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const timerOut = useRef<number>();
  const dispatch = useDispatch();

  const dataFilter: IGetReportsParams = {
    startDate: searchParams.get("startDate"),
    endDate: searchParams.get("endDate"),
    pageIndex: Number(searchParams.get("pageIndex")) || 1,
    pageSize: Number(searchParams.get("pageSize")) || 10,
    keySearch: searchParams.get("keySearch"),
    reportType: [`${type?.toString() || undefined}`],
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataReportsRes, setDataReportsRes] = useState<IBasePagingRes<any>>({
    ...DEFAULT_BASE_PAGING_RES,
  });

  useEffect(() => {
    onGetListReport();
  }, [location]);

  const onGetListReport = async () => {
    setIsLoading(true);
    const newQuery: IGetReportsParams = { ...dataFilter };
    const newParams: IGetReportsReq = {
      pageIndex: newQuery.pageIndex,
      pageSize: newQuery.pageSize,
      keySearch: newQuery.keySearch,
    };
    newParams.reportType =
      !!newQuery.reportType && newQuery.reportType.length > 0
        ? dataFilter?.reportType?.join(",")
        : null;
    newParams.status =
      !!newQuery.status && newQuery.status.length > 0
        ? dataFilter?.status?.join(",")
        : null;
    newParams.startDate = !!newQuery.startDate ? newQuery.startDate : null;
    newParams.endDate = !!newQuery.endDate ? newQuery.endDate : null;

    const res = await ReportService.getReportList(newParams);
    if (!res.isError) {
      setIsLoading(false);
      const newArray = res.data.items.map(item => ({
        ...item,
        id: item.projectId,
      }));
      setDataReportsRes({ ...res.data, items: newArray });
    } else {
      setIsLoading(false);
    }
  };

  const onChangeSearch = (e: any) => {
    const value = e?.target?.value;
    if (timerOut.current) {
      clearTimeout(timerOut.current);
    }
    timerOut.current = window.setTimeout(() => {
      handleChangeParamUrl("keySearch", value);
    }, 1000);
  };

  const handleChangeParamUrl = (key: keyof IGetReportsReq, value: any) => {
    const queryParams: any = {
      ...DEFAULT_QUERY,
      ...dataFilter,
      pageIndex: key === "pageIndex" ? value : 1,
      [key]: value,
    };
    if (!value || value.length <= 0) {
      delete queryParams[key];
    }
    const removeEmptyQuery = Object.fromEntries(
      Object.entries(queryParams).filter(([_, v]) => v != null)
    );
    navigateParams(removeEmptyQuery);
  };

  const handleChangeDateRangeParamUrl = (startDate?: Date, endDate?: Date) => {
    const filterParams = {
      ...dataFilter,
      ...DEFAULT_QUERY,
      startDate: !!startDate ? moment(startDate).format(DATE_REQ_FORMAT) : null,
      endDate: !!endDate ? moment(endDate).format(DATE_REQ_FORMAT) : null,
    };
    const removeEmptyQuery = Object.fromEntries(
      Object.entries(filterParams).filter(([_, v]) => v != null)
    );
    navigateParams(removeEmptyQuery);
  };

  const navigateParams = (value: { [k: string]: unknown }) => {
    const newParams = queryString.stringify(value);

    const path = generatePath(ROUTE_PATH.DOMAIN_AUDIT);
    navigate({
      pathname: `${path}`,
      search: `${newParams}`,
    });
  };

  const asyncFuncDelete: (id: string) => Promise<void> = async (id: string) => {
    const res = await ReportService.deleteReport({ id: id });
    if (!!res && !res.isError) {
      toggleMessage({
        type: "success",
        title: "Xoá thành công",
        message: "Xoá báo cáo thành công!",
      });
      onGetListReport();
    } else {
      toggleMessage({
        type: "error",
        title: "Xoá không thành công",
        message: "Xoá báo cáo không thành công!",
      });
    }
  };

  const handleCreateSuccess = () => {
    navigateParams({ pageIndex: 1, pageSize: 10 });
  };

  const headersTable: ITableHeader<any>[] = [
    {
      field: "domain",
      title: `Domain`,
      width: "250px",
      className: "table-cell-link",
      isSticky: true,
      handleItem: (item: any) => {
        return (
          <button
            className="fit-one-line"
            onClick={() => {
              dispatch(setReportState(item));
              localStorage.setItem(REPORT_ID_KEY, item.auditId);
              const path = generatePath(ROUTE_PATH.DOMAIN_AUDIT_DETAIL, {
                id: item.auditId,
              });
              navigate({
                pathname: `${path}`,
              });
            }}
          >
            {item?.target}
          </button>
        );
      },
    },
    {
      field: "type",
      title: `Loại báo cáo`,
      width: "200px",
      handleItem: (item: any) => {
        return <ReportTypeTags type={item.reportType} />;
      },
    },
    {
      field: "createdAt",
      title: `Ngày tạo`,
      width: "200px",
      handleItem: (item: any) => {
        return (
          <p className="fit-one-line">
            {moment(item.createdAt).format(DATETIME_FORMAT)}
          </p>
        );
      },
    },
    {
      field: "status",
      title: `Trạng thái`,
      width: "200px",
      handleItem: (item: any) => {
        return <ReportStatusTags status={item.status} />;
      },
    },
  ];

  return (
    <CommonReportListPageWrapper>
      <Helmet>
        <title>Domain Audit</title>
      </Helmet>
      <div className="domain-audit-header">
        <div className="header-title-wrapper">
          <div className="header-title">
            <p className="header-name fit-one-line">Domain audit</p>
            <p className="header-count">{dataReportsRes.total} báo cáo</p>
          </div>
          <p className="header-description fit-two-line">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt eiusmo
          </p>
        </div>
        <div className="header-action">
          <InputSearch
            placeholder={"Tìm kiếm"}
            onChange={e => {
              onChangeSearch(e);
            }}
            size={"medium"}
            width={"198px"}
            typeStyle="outlined"
          />
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={_ =>
              toggleCreateReportModal({
                onCreateSuccess: () => handleCreateSuccess(),
              })
            }
            className="add-button"
          >
            <ImageCustom
              src={IconAdd}
              alt="Icon-Add"
              width={"18px"}
              height={"18px"}
            />
            <p>Tạo báo cáo</p>
          </Button>
        </div>
      </div>
      <div className="domain-audit-content-wrapper">
        <div className={`audit-filter-box `}>
          <p className="select-filter-text">Hiển thị theo</p>
          <DateRangePickerPopover
            onDataChange={(v: {
              startDate: Date | undefined;
              endDate: Date | undefined;
            }) => {
              handleChangeDateRangeParamUrl(v.startDate, v.endDate);
            }}
            maxDate={Date.now()}
            value={{
              startDate: dataFilter.startDate
                ? new Date(dataFilter.startDate)
                : undefined,
              endDate: dataFilter.endDate
                ? new Date(dataFilter.endDate)
                : undefined,
            }}
          />
        </div>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            {!!dataReportsRes?.items && dataReportsRes.items?.length > 0 ? (
              <TableDataGrey
                data={dataReportsRes}
                headersTable={headersTable}
                handleDeleteData={id => {
                  toggleConfirmModal({
                    title: `Xoá báo cáo`,
                    content: `Bạn có chắc chắc muốn xoá báo cáo này không?`,
                    onSubmit: () => asyncFuncDelete(id.toString()),
                    submitText: "Xoá",
                    type: "warning-red",
                  });
                }}
                maxHeight={"calc(100vh - 301px)"}
                handleChangeSearchParams={(v: any) =>
                  handleChangeParamUrl("pageIndex", v)
                }
                keyField="auditId"
              />
            ) : (
              <NoDataBox
                onClick={() => {
                  toggleCreateReportModal({
                    onCreateSuccess: () => {
                      handleCreateSuccess();
                    },
                  });
                }}
              />
            )}
          </>
        )}
      </div>
    </CommonReportListPageWrapper>
  );
}
