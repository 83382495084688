import { all, put, takeEvery } from "redux-saga/effects";
import { toggleMessage } from "../../components/Toast/Toast";
import { IBaseResponseModel } from "../../models/common/models.type";

import { EUserType, IActionGetUser } from "../../models/redux/user";
import { IUser } from "../../models/user";
import UserService from "../../services/api/user.service";
import { setUserState } from "./user.duck";

function* getUser({ payload }: IActionGetUser) {
  try {
    const resUser: IBaseResponseModel<IUser> = yield UserService.getSingle();
    if (resUser.success && resUser.statusCode === 200) {
      const userInfo = resUser.data;
      yield put(setUserState(userInfo));
    } else {
      toggleMessage({
        type: "error",
        message: "Your email or password is incorrect!",
        title: "Error Occurred"
      });
    }
  } catch (err) {}
}

export default function* rootSaga() {
  yield all([takeEvery(EUserType.GET_USER, getUser)]);
}
