import { styled } from "@mui/material/styles";
import variableStyles from "../../../../../theme/variable-styles";
import ImageTitleUnderline from "../../../../../assets/images/domai-audit/overview-section/image_underline_title_green.png";

export const OverviewDomainAuditWrapper = styled("div")(() => ({
  backgroundColor: variableStyles.NaturalColor50,
  display: "flex",
  alignItems: "flex-start",

  "& .overview-content": {
    flex: "1 1 auto",
    height: "calc(100vh - 196px)",
    overflow: "auto",
    maxWidth: "830px",
    margin: "30px auto 12px",
    padding: "0 12px",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    "& .overview-introduction-wrapper": {
      padding: "56px 0",

      "& .overview-introduction": {
        display: "flex",
        position: "relative",
        padding: "0 40px",
        marginBottom: "124px",
        "& .introduction-content": {
          width: "340px",
          position: "relative",
          zIndex: "1",
          "& .content-title": {
            color: variableStyles.NaturalColor1000,
            fontWeight: variableStyles.fwBold,
            fontSize: "32px",
            lineHeight: "60px",
            marginBottom: "69px",
            position: "relative",
            "&::after": {
              content: '""',
              width: "100%",
              height: "32px",
              background: `url(${ImageTitleUnderline}) center/contain no-repeat`,
              position: "absolute",
              bottom: "-28px",
              left: "0",
            },
          },
          "& .content-description": {
            color: variableStyles.NaturalColor950,
            fontWeight: variableStyles.fwRegular,
            fontSize: "14px",
            lineHeight: "30px",
            "& span": {
              display: "inline-block",
              marginTop: "30px",
            },
          },
        },
        "& .introduction-image": {
          position: "absolute",
          right: "0",
          bottom: "-110px",
        },
      },
    },
  },
  "& .index-list": {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "16px",
    width: "100%",
    maxWidth: "650px",
    padding: "0 40px",
  },
  "& .common-layout": {
    border: `1px solid ${variableStyles.NaturalColor300}`,
    borderRadius: "4px",
    boxShadow: "0px 0px 17.4px -3px #0000001F ",
    backgroundColor: variableStyles.NaturalColor0,
  },
}));

