import { CheckBoxIconList } from "../../../../../common/constants/value.constant";
import { CheckBoxCustom } from "../../../../../components";

const dataFilterCrawlPage = [
  {
    title: "Title",
    value: "title",
    isMultiple: false,
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "title",
        title: `Title`,
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.meta.title}</p>;
        },
      },
      {
        field: "title",
        title: `Title Length`,
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.meta.title.length}</p>;
        },
      },
      {
        field: "index",
        title: `Indexable`,
        width: "120px",
        className: "table-cell-error-check",
        handleItem: (item: any) => {
          return (
            <div
              className={`cell-indexable ${!!item.indexable ? "success" : "error"}`}
            >
              <p>{!!item.indexable ? "Index" : "Noindex"}</p>
            </div>
          );
        },
      },
    ],
    filterChild: [
      {
        title: "Thiếu Title",
        value: "missingtitle",
        type: "page",
        filter: [
          {
            key: "resource_type",
            value: "html",
            operator: "=",
            type: "field",
          },
          {
            key: "",
            value: "and",
            operator: "",
            type: "operator",
          },
          {
            key: "checks.no_title",
            value: "true",
            operator: "=",
            type: "field",
          },
        ],
      },
      {
        title: "Trùng title",
        value: "duplicatetitle",
        type: "page",
        filter: [
          {
            key: "resource_type",
            value: "html",
            operator: "=",
            type: "field",
          },
          {
            key: "",
            value: "and",
            operator: "",
            type: "operator",
          },
          {
            key: "duplicate_title",
            value: "true",
            operator: "=",
            type: "field",
          },
        ],
      },
      {
        title: "Nhiều hơn 1 tiêu đề",
        value: "manytitle",
        type: "page",
        filter: [
          {
            key: "resource_type",
            value: "html",
            operator: "=",
            type: "field",
          },
          {
            key: "",
            value: "and",
            operator: "",
            type: "operator",
          },
          {
            key: "checks.duplicate_title_tag",
            value: "true",
            operator: "=",
            type: "field",
          },
        ],
      },
      {
        title: "Tiêu đề quá dài",
        value: "longtitle",
        type: "page",
        filter: [
          {
            key: "resource_type",
            value: "html",
            operator: "=",
            type: "field",
          },
          {
            key: "",
            value: "and",
            operator: "",
            type: "operator",
          },
          {
            key: "checks.title_too_long",
            value: "true",
            operator: "=",
            type: "field",
          },
        ],
      },
      {
        title: "Tiêu đề quá ngắn",
        value: "shorttitle",
        type: "page",
        filter: [
          {
            key: "resource_type",
            value: "html",
            operator: "=",
            type: "field",
          },
          {
            key: "",
            value: "and",
            operator: "",
            type: "operator",
          },
          {
            key: "checks.title_too_short",
            value: "true",
            operator: "=",
            type: "field",
          },
        ],
      },
    ],
  },
  {
    title: "H1",
    value: "h1",
    isMultiple: false,
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "title",
        title: `H1 1`,
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.meta.htags.h1[0]}</p>;
        },
      },
      {
        field: "title",
        title: `H1  Length`,
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.meta.htags.h1[0]?.length}</p>;
        },
      },
      {
        field: "title",
        title: `H1 2`,
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.meta.htags.h1[1]}</p>;
        },
      },
      {
        field: "title",
        title: `H1 2 Length`,
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.meta.htags.h1[1]?.length}</p>;
        },
      },
      {
        field: "domain",
        title: `Status`,
        width: "120px",
        className: "table-cell-error-check",
        handleItem: (item: any) => {
          return (
            <div className={`cell-status-code  success}`}>
              <p>{item.status_code || "4xx"}</p>
            </div>
          );
        },
      },
      {
        field: "index",
        title: `Indexable`,
        width: "120px",
        className: "table-cell-error-check",
        handleItem: (item: any) => {
          return (
            <div
              className={`cell-indexable ${!!item.indexable ? "success" : "error"}`}
            >
              <p>{!!item.indexable ? "Index" : "Noindex"}</p>
            </div>
          );
        },
      },
    ],
    filterChild: [
      {
        title: "Thiếu thẻ H1",
        value: "missingh1",
        type: "page",
        filter: [
          {
            key: "resource_type",
            value: "html",
            operator: "=",
            type: "field",
          },
          {
            key: "",
            value: "and",
            operator: "",
            type: "operator",
          },
          {
            key: "checks.no_h1_tags",
            value: "true",
            operator: "=",
            type: "field",
          },
        ],
      },
      { title: "Trùng thẻ H1", value: "duplicateh1", filter: {} },
      { title: "Nhiều hơn 1 thẻ H1", value: "manyh1", filter: {} },
    ],
  },
  {
    title: "Meta description",
    value: "metadescription",
    isMultiple: false,
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "meta",
        title: `Meta`,
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.meta.title}</p>;
        },
      },
      {
        field: "meta",
        title: `Meta Length`,
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.meta.title.length}</p>;
        },
      },
      {
        field: "domain",
        title: `Status`,
        width: "120px",
        className: "table-cell-error-check",
        handleItem: (item: any) => {
          return (
            <div className={`cell-status-code  success}`}>
              <p>{item.status_code || "4xx"}</p>
            </div>
          );
        },
      },
      {
        field: "index",
        title: `Indexable`,
        width: "120px",
        className: "table-cell-error-check",
        handleItem: (item: any) => {
          return (
            <div
              className={`cell-indexable ${!!item.indexable ? "success" : "error"}`}
            >
              <p>{!!item.indexable ? "Index" : "Noindex"}</p>
            </div>
          );
        },
      },
    ],
    filterChild: [
      {
        title: "Thiếu meta description",
        value: "missingmeta",
        type: "page",
        filter: [
          {
            key: "resource_type",
            value: "html",
            operator: "=",
            type: "field",
          },
          {
            key: "",
            value: "and",
            operator: "",
            type: "operator",
          },
          {
            key: "checks.no_description",
            value: "true",
            operator: "=",
            type: "field",
          },
        ],
      },
      {
        title: "Trùng meta description",
        value: "duplicatemeta",
        type: "page",
        filter: [
          {
            key: "resource_type",
            value: "html",
            operator: "=",
            type: "field",
          },
          {
            key: "",
            value: "and",
            operator: "",
            type: "operator",
          },
          {
            key: "duplicate_description",
            value: "true",
            operator: "=",
            type: "field",
          },
        ],
      },
      {
        title: "Meta description quá dài",
        value: "longmeta",
        type: "page",
        filter: [
          {
            key: "resource_type",
            value: "html",
            operator: "=",
            type: "field",
          },
          {
            key: "",
            value: "and",
            operator: "",
            type: "operator",
          },
          {
            key: "meta.description_length",
            value: "155",
            operator: ">=",
            type: "field",
          },
        ],
      },
    ],
  },
  {
    title: "H2",
    value: "h2",
    isMultiple: false,
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "meta",
        title: `H2- 1`,
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.meta.title.length}</p>;
        },
      },
      {
        field: "meta",
        title: `H2- 2`,
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.meta.title.length}</p>;
        },
      },
      {
        field: "domain",
        title: `Status`,
        width: "120px",
        className: "table-cell-error-check",
        handleItem: (item: any) => {
          return (
            <div className={`cell-status-code  success}`}>
              <p>{item.status_code || "4xx"}</p>
            </div>
          );
        },
      },
      {
        field: "index",
        title: `Indexable`,
        width: "120px",
        className: "table-cell-error-check",
        handleItem: (item: any) => {
          return (
            <div
              className={`cell-indexable ${!!item.indexable ? "success" : "error"}`}
            >
              <p>{!!item.indexable ? "Index" : "Noindex"}</p>
            </div>
          );
        },
      },
    ],
    filterChild: [{ title: "Trùng lặp", value: "duplicateh2", filter: {} }],
  },
  {
    title: "Image",
    value: "image",
    isMultiple: false,
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "image ",
        operator: "=",
        type: "field",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Status`,
        width: "120px",
        className: "table-cell-error-check",
        handleItem: (item: any) => {
          return (
            <div className={`cell-status-code  success}`}>
              <p>{item.status_code || "4xx"}</p>
            </div>
          );
        },
      },
      {
        field: "size",
        title: `Size`,
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.size}</p>;
        },
      },
      {
        field: "alt",
        title: `Thẻ Alt ảnh`,
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.alt}</p>;
        },
      },
      {
        field: "url",
        title: `URL chứa ảnh`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
    filterChild: [
      {
        title: "Thiếu thẻ ALT",
        value: "missingalt",
        type: "resources",
        filter: [
          {
            key: "resource_type",
            value: "image ",
            operator: "=",
            type: "field",
          },
          {
            key: "",
            value: "and",
            operator: "",
            type: "operator",
          },
          {
            key: "meta.alternative_text",
            value: "",
            operator: "=",
            type: "field",
          },
        ],
      },
      {
        title: "Lớn hơn 100KB",
        value: "size100",
        type: "page",
        filter: [
          {
            key: "resource_type",
            value: "image",
            operator: "=",
            type: "field",
          },
          {
            key: "",
            value: "and",
            operator: "",
            type: "operator",
          },
          {
            key: "size",
            value: "102400",
            operator: ">=",
            type: "field",
          },
        ],
      },
    ],
  },
  {
    title: "Canocical",
    value: "canocical",
    isMultiple: false,
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Status`,
        width: "120px",
        className: "table-cell-error-check",
        handleItem: (item: any) => {
          return (
            <div className={`cell-status-code  success}`}>
              <p>{item.status_code || "4xx"}</p>
            </div>
          );
        },
      },
      {
        field: "index",
        title: `Indexable`,
        width: "120px",
        className: "table-cell-error-check",
        handleItem: (item: any) => {
          return (
            <div
              className={`cell-indexable ${!!item.indexable ? "success" : "error"}`}
            >
              <p>{!!item.indexable ? "Index" : "Noindex"}</p>
            </div>
          );
        },
      },
      {
        field: "url",
        title: `Link canonical`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
    filterChild: [
      { title: "Thiếu thẻ canonical", value: "missingcanonical", filter: {} },
      {
        title: "Trang lỗi thẻ canonical",
        value: "errorcanonical",
        type: "page",
        filter: [
          {
            key: "resource_type",
            value: "html",
            operator: "=",
            type: "field",
          },
          {
            key: "",
            value: "and",
            operator: "",
            type: "operator",
          },
          {
            key: "checks.canonical_to_broken",
            value: "true",
            operator: "=",
            type: "field",
          },
        ],
      },
      {
        title: "Thẻ canonical trả về mã 3xx",
        value: "3xxcanonical",
        type: "page",
        filter: [
          {
            key: "resource_type",
            value: "html",
            operator: "=",
            type: "field",
          },
          {
            key: "",
            value: "and",
            operator: "",
            type: "operator",
          },
          {
            key: "checks.canonical_to_redirect",
            value: "true",
            operator: "=",
            type: "field",
          },
        ],
      },
    ],
  },
  {
    title: "Status code",
    value: "statuscode",
    isMultiple: true,
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Status`,
        width: "120px",
        className: "table-cell-error-check",
        handleItem: (item: any) => {
          return (
            <div className={`cell-status-code  success}`}>
              <p>{item.status_code || "4xx"}</p>
            </div>
          );
        },
      },
      {
        field: "index",
        title: `Indexable`,
        width: "120px",
        className: "table-cell-error-check",
        handleItem: (item: any) => {
          return (
            <div
              className={`cell-indexable ${!!item.indexable ? "success" : "error"}`}
            >
              <p>{!!item.indexable ? "Index" : "Noindex"}</p>
            </div>
          );
        },
      },
      {
        field: "url",
        title: `Link redirect`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
    filterChild: [
      {
        title: "200",
        value: "code200",
        type: "page",
        filter: ["status_code", "=", "200"],
      },
      {
        title: "301",
        value: "code301",
        type: "page",
        filter: ["status_code", "=", "301"],
      },
      {
        title: "302",
        value: "code302",
        type: "page",
        filter: ["status_code", "=", "302"],
      },
      {
        title: "401",
        value: "code401",
        type: "page",
        filter: ["status_code", "=", "401"],
      },
      {
        title: "403",
        value: "code403",
        type: "page",
        filter: ["status_code", "=", "403"],
      },
      {
        title: "404",
        value: "code404",
        type: "page",
        filter: ["status_code", "=", "404"],
      },
      {
        title: "501",
        value: "code501",
        type: "page",
        filter: ["status_code", "=", "501"],
      },
      {
        title: "502",
        value: "code502",
        type: "page",
        filter: ["status_code", "=", "502"],
      },
      {
        title: "505",
        value: "code505",
        type: "page",
        filter: ["status_code", "=", "505"],
      },
    ],
  },
  {
    title: "Link",
    value: "link",
    isMultiple: false,
    type: "link",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Status`,
        width: "120px",
        className: "table-cell-error-check",
        handleItem: (item: any) => {
          return (
            <div className={`cell-status-code  success}`}>
              <p>{item.status_code || "4xx"}</p>
            </div>
          );
        },
      },
      {
        field: "index",
        title: `Indexable`,
        width: "120px",
        className: "table-cell-error-check",
        handleItem: (item: any) => {
          return (
            <div
              className={`cell-indexable ${!!item.indexable ? "success" : "error"}`}
            >
              <p>{!!item.indexable ? "Index" : "Noindex"}</p>
            </div>
          );
        },
      },
      {
        field: "size",
        title: `Số Internalink`,
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.size}</p>;
        },
      },
      {
        field: "size",
        title: `Số External link`,
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.size}</p>;
        },
      },
    ],
  },
  {
    title: "Click Depth",
    value: "clickdepth",
    isMultiple: true,
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Status`,
        width: "120px",
        className: "table-cell-error-check",
        handleItem: (item: any) => {
          return (
            <div className={`cell-status-code  success}`}>
              <p>{item.status_code || "4xx"}</p>
            </div>
          );
        },
      },
      {
        field: "index",
        title: `Indexable`,
        width: "120px",
        className: "table-cell-error-check",
        handleItem: (item: any) => {
          return (
            <div
              className={`cell-indexable ${!!item.indexable ? "success" : "error"}`}
            >
              <p>{!!item.indexable ? "Index" : "Noindex"}</p>
            </div>
          );
        },
      },
      {
        field: "size",
        title: `Click Depth`,
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.size}</p>;
        },
      },
    ],
    filterChild: [
      {
        title: "1",
        value: "depth1",
        type: "page",
        filter: [
          {
            key: "resource_type",
            value: "html",
            operator: "=",
            type: "field",
          },
          {
            key: "",
            value: "and",
            operator: "",
            type: "operator",
          },
          {
            key: "click_depth",
            value: "1",
            operator: "=",
            type: "field",
          },
        ],
      },
      {
        title: "2",
        value: "depth2",
        type: "page",
        filter: [
          {
            key: "resource_type",
            value: "html",
            operator: "=",
            type: "field",
          },
          {
            key: "",
            value: "and",
            operator: "",
            type: "operator",
          },
          {
            key: "click_depth",
            value: "2",
            operator: "=",
            type: "field",
          },
        ],
      },
      {
        title: "3",
        value: "depth3",
        type: "page",
        filter: [
          {
            key: "resource_type",
            value: "html",
            operator: "=",
            type: "field",
          },
          {
            key: "",
            value: "and",
            operator: "",
            type: "operator",
          },
          {
            key: "click_depth",
            value: "3",
            operator: "=",
            type: "field",
          },
        ],
      },
      {
        title: "4",
        value: "depth4",
        type: "page",
        filter: [
          {
            key: "resource_type",
            value: "html",
            operator: "=",
            type: "field",
          },
          {
            key: "",
            value: "and",
            operator: "",
            type: "operator",
          },
          {
            key: "click_depth",
            value: "4",
            operator: "=",
            type: "field",
          },
        ],
      },
      {
        title: "5",
        value: "depth5",
        type: "page",
        filter: [
          {
            key: "resource_type",
            value: "html",
            operator: "=",
            type: "field",
          },
          {
            key: "",
            value: "and",
            operator: "",
            type: "operator",
          },
          {
            key: "click_depth",
            value: "5",
            operator: "=",
            type: "field",
          },
        ],
      },
      {
        title: "5+",
        value: "depth5+",
        type: "page",
        filter: [
          {
            key: "resource_type",
            value: "html",
            operator: "=",
            type: "field",
          },
          {
            key: "",
            value: "and",
            operator: "",
            type: "operator",
          },
          {
            key: "click_depth",
            value: "5",
            operator: ">",
            type: "field",
          },
        ],
      },
    ],
  },
  {
    title: "Chuỗi chuyển hướng",
    value: "stringredirect",
    isMultiple: false,
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "checks.redirect_chain",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Status`,
        width: "120px",
        className: "table-cell-error-check",
        handleItem: (item: any) => {
          return (
            <div className={`cell-status-code  success}`}>
              <p>{item.status_code || "4xx"}</p>
            </div>
          );
        },
      },
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
  },
  {
    title: "Content",
    value: "content",
    isMultiple: false,
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Status`,
        width: "120px",
        className: "table-cell-error-check",
        handleItem: (item: any) => {
          return (
            <div className={`cell-status-code  success}`}>
              <p>{item.status_code || "4xx"}</p>
            </div>
          );
        },
      },
      {
        field: "index",
        title: `Indexable`,
        width: "120px",
        className: "table-cell-error-check",
        handleItem: (item: any) => {
          return (
            <div
              className={`cell-indexable ${!!item.indexable ? "success" : "error"}`}
            >
              <p>{!!item.indexable ? "Index" : "Noindex"}</p>
            </div>
          );
        },
      },
      {
        field: "title",
        title: `Title`,
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.size}</p>;
        },
      },
      {
        field: "size",
        title: `Text ratio`,
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.size}</p>;
        },
      },
      {
        field: "size",
        title: `Spelling`,
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.size}</p>;
        },
      },
      {
        field: "size",
        title: `Reability`,
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.size}</p>;
        },
      },
    ],
    filterChild: [
      {
        title: "Trang trùng lặp nội dung",
        value: "duplicatecontent",
        type: "page",
        filter: [
          {
            key: "resource_type",
            value: "html",
            operator: "=",
            type: "field",
          },
          {
            key: "",
            value: "and",
            operator: "",
            type: "operator",
          },
          {
            key: "duplicate_content",
            value: "true",
            operator: "=",
            type: "field",
          },
        ],
      },
      {
        title: "Trang có tỉ lệ nội dung thấp",
        value: "lowcontent",
        type: "page",
        filter: [
          {
            key: "resource_type",
            value: "html",
            operator: "=",
            type: "field",
          },
          {
            key: "",
            value: "and",
            operator: "",
            type: "operator",
          },
          {
            key: "checks.low_content_rate",
            value: "true",
            operator: "=",
            type: "field",
          },
        ],
      },
      {
        title: "Trang có tỉ lệ nội dung cao",
        value: "highcontent",
        type: "page",
        filter: [
          {
            key: "resource_type",
            value: "html",
            operator: "=",
            type: "field",
          },
          {
            key: "",
            value: "and",
            operator: "",
            type: "operator",
          },
          {
            key: "checks.high_content_rate",
            value: "true",
            operator: "=",
            type: "field",
          },
        ],
      },
      {
        title: "Trang có thẻ Title không liên quan",
        value: "notrelativetitle",
        type: "page",
        filter: [
          {
            key: "resource_type",
            value: "html",
            operator: "=",
            type: "field",
          },
          {
            key: "",
            value: "and",
            operator: "",
            type: "operator",
          },
          {
            key: "checks.irrelevant_title",
            value: "true",
            operator: "=",
            type: "field",
          },
        ],
      },
      {
        title: "Trang có lỗi chính tả",
        value: "spellcontent",
        type: "page",
        filter: [
          {
            key: "resource_type",
            value: "html",
            operator: "=",
            type: "field",
          },
          {
            key: "",
            value: "and",
            operator: "",
            type: "operator",
          },
          {
            key: "meta.spell",
            value: "null",
            operator: "<>",
            type: "field",
          },
        ],
      },
      {
        title: "Trang có tỉ lệ đọc thấp",
        value: "lowread",
        type: "page",
        filter: [
          {
            key: "resource_type",
            value: "html",
            operator: "=",
            type: "field",
          },
          {
            key: "",
            value: "and",
            operator: "",
            type: "operator",
          },
          {
            key: "checks.low_readability_rate",
            value: "true",
            operator: "=",
            type: "field",
          },
        ],
      },
    ],
  },
  {
    title: "Chứng chỉ Https",
    value: "https",
    isMultiple: false,
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Status`,
        width: "120px",
        className: "table-cell-error-check",
        handleItem: (item: any) => {
          return (
            <div className={`cell-status-code  success}`}>
              <p>{item.status_code || "4xx"}</p>
            </div>
          );
        },
      },
      {
        field: "index",
        title: `Indexable`,
        width: "120px",
        className: "table-cell-error-check",
        handleItem: (item: any) => {
          return (
            <div
              className={`cell-indexable ${!!item.indexable ? "success" : "error"}`}
            >
              <p>{!!item.indexable ? "Index" : "Noindex"}</p>
            </div>
          );
        },
      },
      {
        field: "title",
        title: `Https verson`,
        width: "120px",
        handleItem: (item: any) => {
          return (
            <CheckBoxCustom
              onChange={() => { }}
              label={""}
              value={item?.https || ""}
              iconCheck={CheckBoxIconList[0].iconCheck}
              iconCheckActive={CheckBoxIconList[0].iconCheckActive}
              checked={true}
              width="16px"
              height="16px"
            />
          );
        },
      },
      {
        field: "size",
        title: `Http verson`,
        width: "120px",
        handleItem: (item: any) => {
          return (
            <CheckBoxCustom
              onChange={() => { }}
              label={""}
              value={item?.https || ""}
              iconCheck={CheckBoxIconList[0].iconCheck}
              iconCheckActive={CheckBoxIconList[0].iconCheckActive}
              checked={true}
              width="16px"
              height="16px"
            />
          );
        },
      },
    ],
    filterChild: [
      {
        title: "Trang có liên kết trỏ đến bản Http",
        value: "redirecthttp",
        type: "page",
        filter: [
          {
            key: "resource_type",
            value: "html",
            operator: "=",
            type: "field",
          },
          {
            key: "",
            value: "and",
            operator: "",
            type: "operator",
          },
          {
            key: "checks.https_to_http_links",
            value: "true",
            operator: "=",
            type: "field",
          },
        ],
      },
      {
        title: "Trang có nội dung hỗn hợp",
        value: "redirectmix",
        type: "links",
        filter: [
          {
            key: "type",
            value: "image",
            operator: "=",
            type: "field",
          },
          {
            key: "",
            value: "and",
            operator: "",
            type: "operator",
          },
          {
            key: "page_to_scheme",
            value: "hntp",
            operator: "=",
            type: "field",
          },
        ],
      },
      {
        title: "Trang có bản HTTP được đặt trong Sitemap",
        value: "httpinsitemap",
        filter: {},
      },
    ],
  },
  {
    title: "Robot.txt",
    value: "robottxt",
    isMultiple: false,
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Status`,
        width: "120px",
        className: "table-cell-error-check",
        handleItem: (item: any) => {
          return (
            <div className={`cell-status-code  success}`}>
              <p>{item.status_code || "4xx"}</p>
            </div>
          );
        },
      },
      {
        field: "index",
        title: `Indexable`,
        width: "120px",
        className: "table-cell-error-check",
        handleItem: (item: any) => {
          return (
            <div
              className={`cell-indexable ${!!item.indexable ? "success" : "error"}`}
            >
              <p>{!!item.indexable ? "Index" : "Noindex"}</p>
            </div>
          );
        },
      },
    ],
    filterChild: [
      {
        title: "Các trang không nên chặn",
        value: "robotnotprevent",
        filter: {},
      },
    ],
  },
  {
    title: "Href lang",
    value: "hreflang",
    isMultiple: false,
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Status`,
        width: "120px",
        className: "table-cell-error-check",
        handleItem: (item: any) => {
          return (
            <div className={`cell-status-code  success}`}>
              <p>{item.status_code || "4xx"}</p>
            </div>
          );
        },
      },
      {
        field: "index",
        title: `Indexable`,
        width: "120px",
        className: "table-cell-error-check",
        handleItem: (item: any) => {
          return (
            <div
              className={`cell-indexable ${!!item.indexable ? "success" : "error"}`}
            >
              <p>{!!item.indexable ? "Index" : "Noindex"}</p>
            </div>
          );
        },
      },
      {
        field: "url",
        title: `Hreflang URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
    filterChild: [
      {
        title: "Trang gắn thẻ Hreflang sai vị trí",
        value: "hrefwrongposition",
        filter: {},
      },
      { title: "Trang không chứa thẻ Hreflang", value: "nohref", filter: {} },
      {
        title: "Trang sử dụng sai thẻ Hreflang",
        value: "wronghref",
        filter: {},
      },
      { title: "Trang sử dụng hỗn hợp dịch", value: "mixhref", filter: {} },
    ],
  },
  {
    title: "Schema Markup",
    value: "schema",
    isMultiple: false,
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Status`,
        width: "120px",
        className: "table-cell-error-check",
        handleItem: (item: any) => {
          return (
            <div className={`cell-status-code  success}`}>
              <p>{item.status_code || "4xx"}</p>
            </div>
          );
        },
      },
      {
        field: "index",
        title: `Indexable`,
        width: "120px",
        className: "table-cell-error-check",
        handleItem: (item: any) => {
          return (
            <div
              className={`cell-indexable ${!!item.indexable ? "success" : "error"}`}
            >
              <p>{!!item.indexable ? "Index" : "Noindex"}</p>
            </div>
          );
        },
      },
      {
        field: "size",
        title: `Loại Schema`,
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.size}</p>;
        },
      },
    ],
    filterChild: [
      { title: "Thiếu Schema", value: "missingschema", filter: {} },
      { title: "Lỗi Schema", value: "errorschema", filter: {} },
    ],
  },
];

export default dataFilterCrawlPage;
