import { Table } from "@mui/material";
import { styled } from "@mui/material/styles";
import variableStyles from "../../../theme/variable-styles";

export const TableBoxWrapper = styled("div")(() => ({
  "& .table-wrapper": {
    border: `1px solid ${variableStyles.NaturalColor200}`,
    borderRadius: "12px",
    boxShadow: "0px 0px 18.3px 0px #0000000A", position: "relative"
  },
  "& h4": {
    color: variableStyles.Info800,
    fontWeight: variableStyles.fwSemiBold,
    fontSize: "16px",
    lineHeight: "24px",
    marginBottom: "4px",
  },
  "& .view-all-button": {
    backgroundColor: "transparent",
    color: variableStyles.NaturalColor950,
    fontWeight: variableStyles.fwSemiBold,
    fontSize: "14px",
    lineHeight: "14px",
    width: "100%",
    height: "50px",
    "&:hover": {
      backgroundColor: variableStyles.NaturalColor100,
    },
  },
  "& .pagination-wrapper": {
    padding: "10px",
  },
}));

export const TableDataWrapper = styled(Table)<{ colorheader: string }>(
  ({ colorheader }) => ({
    minWidth: "max-content",
    backgroundColor: variableStyles.NaturalColor0,
    tableLayout: "fixed",
    "& .MuiTableHead-root": {
      "& .MuiTableCell-root": {
        padding: "10px 12px !important",
        border: `none`,
        backgroundColor: colorheader,
        "& .header-table-cell-wrapper": {
          gap: "4px",
          "& p": {
            color: variableStyles.NaturalColor0,
            fontWeight: variableStyles.fwSemiBold,
            fontSize: "12px",
            lineHeight: "14px",
          },
          "& img": {
            flex: "0 0 auto",
          },
        },
      },
    },
    "& .MuiTableBody-root": {
      "& .MuiTableRow-root": {
        backgroundColor: variableStyles.NaturalColor0,
        // "&:nth-of-type(even)": {
        //   backgroundColor: variableStyles.NaturalColor100,
        //   "& .sticky-column": {
        //     backgroundColor: `${variableStyles.NaturalColor100} !important`,
        //   },
        // },
        "&:hover": {
          backgroundColor: variableStyles.NaturalColor100,
          "& .sticky-column": {
            backgroundColor: `${variableStyles.NaturalColor100} !important`,
          },
        },
        "&.box-empty-wrapper": {
          pointerEvents: "none",
        },
      },
      "& .MuiTableCell-root": {
        color: variableStyles.NaturalColor950,
        fontWeight: variableStyles.fwMedium,
        fontSize: "12px",
        lineHeight: "14.31px",
        borderBottom: `1px solid ${variableStyles.NaturalColor100}`,
        padding: "10px 12px !important",
        height: "50px",
      },
    },
    "& .MuiTableCell-root.table-cell-link": {
      "& a": {
        color: variableStyles.blueSecondary700,
        fontWeight: variableStyles.fwMedium,
        fontSize: "12px",
        lineHeight: "13.31px",
        cursor: "pointer",
      },
      "& button": {
        color: variableStyles.blueSecondary700,
        fontWeight: variableStyles.fwMedium,
        fontSize: "14px",
        lineHeight: "16px",
        width: "100%",
        textAlign: "left",
        display: "inline-block !important",
      },
    },
    "& .ps__rail-x": {
      height: "7px",
    },
    "& .MuiTableCell-stickyHeader.sticky-column": { zIndex: "999" },
    "& .sticky-column": {
      position: "sticky",
      left: 0,
      background: variableStyles.NaturalColor0,
      zIndex: "99",
    },
    "& .title-with-link": {
      "& p": {
        color: variableStyles.NaturalColor1000,
        fontWeight: variableStyles.fwMedium,
        fontSize: "12px",
        lineHeight: "12px",
        marginBottom: "8px",
      },
      "& a": {
        color: variableStyles.blueSecondary600,
        fontWeight: variableStyles.fwMedium,
        fontSize: "12px !important",
        lineHeight: "12px !important",
        marginBottom: "8px",
      },
    },
    "& .table-cell-IP": {
      gap: "4px",
      "& p": {
        color: variableStyles.Info600,
        fontWeight: variableStyles.fwMedium,
        fontSize: "12px !important",
        lineHeight: "12px !important",
      },
    },
    "& .table-cell-progress": {
      "& p.progress-percent": {
        color: variableStyles.NaturalColor1000,
        fontWeight: variableStyles.fwMedium,
        fontSize: "12px !important",
        lineHeight: "14.52px !important",
        marginBottom: "6px",
      },
    },
    "& .table-cell-error-check": {
      "& div.cell-indexable": {
        borderRadius: "100px",
        padding: "3.5px 8px",
        width: "min-content",
        "& p": {
          fontWeight: variableStyles.fwMedium,
          fontSize: "10px !important",
          lineHeight: "15px !important",
        },
        "&.success": {
          backgroundColor: variableStyles.Success100,
          "& p": {
            color: variableStyles.Success600,
          },
        },
        "&.error": {
          backgroundColor: variableStyles.NaturalColor100,
          "& p": {
            color: variableStyles.Error500,
          },
        },
      },
      "& div.cell-status-code": {
        borderRadius: "100px",
        padding: "3.5px 8px",
        width: "min-content",
        "& p": {
          fontWeight: variableStyles.fwMedium,
          fontSize: "10px !important",
          lineHeight: "15px !important",
        },
        "&.success": {
          backgroundColor: variableStyles.Success100,
          "& p": {
            color: variableStyles.Success600,
          },
        },
        "&.error": {
          backgroundColor: variableStyles.Error100,
          "& p": {
            color: variableStyles.Error500,
          },
        },
      },
    },
    "& .table-cell-link-error": {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      "& button": { backgroundColor: "transparent" },
    },
    "& .table-cell-button-modal": {
      "& button.button-open-modal": {
        textDecoration: "underline",
        backgroundColor: "transparent",
      },
    },
    "& .table-cell-intents-tag": {
      "& div.intents-tags": { gap: "4px", flexWrap: "wrap" },
      "& p.intents-tag": {
        fontWeight: variableStyles.fwSemiBold,
        fontSize: "12px",
        lineHeight: "14px",
        width: "22px",
        height: "22px",
        borderRadius: "6px",
        flex: "0 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "& .I": {
        color: variableStyles.Info900,
        backgroundColor: variableStyles.Info100,
      },
      "& .C": {
        color: variableStyles.blueSecondary950,
        backgroundColor: variableStyles.blueSecondary200,
      },
      "& .N": {
        color: variableStyles.Success700,
        backgroundColor: variableStyles.Success200,
      },
      "& .T": {
        color: variableStyles.Error700,
        backgroundColor: variableStyles.Error100,
      },
    },
    "& .box-empty": {
      gap: "24px", position: "absolute",
      left: '50%',
      top: 'calc(50% + 20px)',
      transform: 'translate(-50%, -50%)',
      "& .text-empty": {
        color: variableStyles.NaturalColor1000,
        fontWeight: variableStyles.fwMedium,
        fontSize: "20px",
        lineHeight: "24px",
      },
    },
    "& .box-loading": {
      gap: "24px",
      position: "absolute",
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      "& .text-loading": {
        color: variableStyles.NaturalColor1000,
        fontWeight: variableStyles.fwMedium,
        fontSize: "20px",
        lineHeight: "24px",
      },
    },
  })
);
