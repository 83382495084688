import React, { useEffect, useState } from "react";
import { OverviewSideBarWrapper } from "./styles";
import IconSidebar from "../../../../assets/images/common/icon_sidebar_line_grey.svg";
import { ImageCustom } from "../../../../components";
import { IValue } from "../../../../models/common/models.type";

import IconIssue from "../../../../assets/images/common/icon_issue_line_grey.svg";
import IconIssueActive from "../../../../assets/images/common/icon_issue_line_green.svg";
import IconOptimize from "../../../../assets/images/common/icon_optimize_line_grey.svg";
import IconOptimizeActive from "../../../../assets/images/common/icon_optimize_line_green.svg";
import IconPerform from "../../../../assets/images/common/icon_perform_line_grey.svg";
import IconPerformActive from "../../../../assets/images/common/icon_perform_line_green.svg";
import IconSecurity from "../../../../assets/images/common/icon_security_line_grey.svg";
import IconSecurityActive from "../../../../assets/images/common/icon_security_line_green.svg";
import IconSEO from "../../../../assets/images/common/icon_seo_line_grey.svg";
import IconSEOActive from "../../../../assets/images/common/icon_seo_line_green.svg";
import { Tooltip, useMediaQuery } from "@mui/material";

interface OverviewSidebarProps {
  activeValue: string;
  onScrollto: (value: string) => void;
}

const navbarData: IValue[] = [
  {
    title: "Hiệu suất hoạt động",
    value: "0",
    icon: IconPerform,
    iconActive: IconPerformActive,
  },
  {
    title: "Trải nghiệm người dùng",
    value: "1",
    icon: IconSecurity,
    iconActive: IconSecurityActive,
  },
  {
    title: "Tối ưu chuyển đổi",
    value: "2",
    icon: IconOptimize,
    iconActive: IconOptimizeActive,
  },
  {
    title: "Đánh giá SEO Website",
    value: "3",
    icon: IconSEO,
    iconActive: IconSEOActive,
  },
  {
    title: "Thông tin công ty",
    value: "4",
    icon: IconIssue,
    iconActive: IconIssueActive,
  },
];

export default function OverviewSidebar(props: OverviewSidebarProps) {
  const { activeValue, onScrollto } = props;
  const [notExpaned, setNotExpaned] = useState<boolean>(false);

  const matches1070 = useMediaQuery("(max-width:1070px)");

  useEffect(() => {
    if (matches1070 && !notExpaned) {
      setNotExpaned(true);
    }
  }, []);

  return (
    <OverviewSideBarWrapper className={`${notExpaned ? "notExpaned" : ""}`}>
      <div className="sidebar-header">
        <p className="header-text">Report Navigation</p>
        <button
          onClick={() => {
            setNotExpaned(!notExpaned);
          }}
          className="header-button"
        >
          <ImageCustom
            src={IconSidebar}
            alt="Icon-Sidebar"
            width={"20px"}
            height={"20px"}
          />
        </button>
      </div>
      <ul className="sidebar-list">
        {navbarData.map(item => (
          <li key={item.value}>
            <Tooltip title={!notExpaned ? "" : item?.title} placement="right">
              <button
                className={`sidebar-button ${activeValue === item.value ? "active" : ""}`}
                onClick={() => {
                  if (matches1070) {
                    setNotExpaned(true);
                  }
                  onScrollto(item.value);
                }}
              >
                <div className="image-box">
                  <ImageCustom
                    src={item?.icon}
                    alt="Sidebar-Icon"
                    width={"18px"}
                    height={"18px"}
                    className={`box-default`}
                  />
                  <ImageCustom
                    src={item?.iconActive}
                    alt="Sidebar-Icon"
                    width={"18px"}
                    height={"18px"}
                    className={`box-active`}
                  />
                </div>
                <p>{item.title}</p>
              </button>
            </Tooltip>
          </li>
        ))}
      </ul>
    </OverviewSideBarWrapper>
  );
}
