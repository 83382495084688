import { Table } from "@mui/material";
import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const CollapseHandleErrorWrapper = styled("div")(({ theme }) => ({
  backgroundColor: variableStyles.NaturalColor0,
  border: `1px solid ${variableStyles.NaturalColor200}`,
  boxShadow: "0px 0px 18.3px 0px #0000000A",
  borderRadius: "6px",
  "& .collapse-header": {
    width: "100%",
    backgroundColor: "transparent",
    gap: "12px",
    padding: "10px 12px 10px",
    cursor: "pointer",
    "&.not-collapse": {
      cursor: "auto",
    },
    "& .header-content": {
      gap: "8px",
      flex: "1 1 auto",
      "& .header-text-wrapper": {
        display: "flex",
        alignItems: "center",
        gap: "4px",
      },
      "& .detail-button": {
        color: variableStyles.blueSecondary800,
        fontWeight: variableStyles.fwRegular,
        fontSize: "12px",
        lineHeight: "20px",
        backgroundColor: "transparent",
        flex: "0 0 auto",
        "&:hover": {
          textDecoration: "underline",
        },
      },
      "& .header-text": {
        color: variableStyles.NaturalColor1000,
        fontWeight: variableStyles.fwMedium,
        fontSize: "12px",
        lineHeight: "18px",
        textAlign: "left",
      },
    },
    "& .header-action": {
      gap: "12px",
      flex: "0 0 auto",
      "& .action-recommend": {
        color: variableStyles.blueSecondary800,
        fontWeight: variableStyles.fwRegular,
        fontSize: "12px",
        lineHeight: "20px",
      },
      "& .arrow-collapse": {
        transition: "all .2s linear",
        "&.active": {
          transform: "rotate(-180deg)",
        },
        "&.hidden-arrow": {
          pointerEvents: "none",
          "& img": {
            visibility: "hidden",
          },
        },
      },
    },
  },
  "& .table-wrapper": {
    border: `1px solid ${variableStyles.NaturalColor200}`,
    borderRadius: "6px",
    boxShadow: "0px 0px 18.3px 0px #0000000A",
    margin: "10px",
  },
  "& .view-more-button": {
    backgroundColor: variableStyles.NaturalColor100,
    color: variableStyles.NaturalColor950,
    fontWeight: variableStyles.fwSemiBold,
    fontSize: "14px",
    lineHeight: "14px",
    width: "100%",
    height: "40px",
    borderRadius: "0 0 6px 6px",
    "&:hover": {
      backgroundColor: variableStyles.NaturalColor0,
    },
  },
  "& .url-list": {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    padding: "8px 12px",
    borderRadius: "6px",
    backgroundColor: variableStyles.NaturalColor100,
    border: `1px solid ${variableStyles.NaturalColor200}`,
    margin: "10px",
    "& li": {
      color: variableStyles.blueSecondary700,
      fontWeight: variableStyles.fwMedium,
      fontSize: "11px",
      lineHeight: "16.5px",
    },
  },
  "& .text-wrapper": {
    padding: "8px 12px",
    borderRadius: "6px",
    backgroundColor: variableStyles.NaturalColor100,
    border: `1px solid ${variableStyles.NaturalColor200}`,
    margin: "10px",
    "& p": {
      color: variableStyles.NaturalColor1000,
      fontWeight: variableStyles.fwMedium,
      fontSize: "11px",
      lineHeight: "16.5px",
    },
  },
  "& .loading-box": {
    width: "100%",
  },
}));

export const TableDataWrapper = styled(Table)(() => ({
  minWidth: "max-content",
  backgroundColor: variableStyles.NaturalColor100,
  tableLayout: "fixed",
  "& .MuiTableHead-root": {
    "& .MuiTableCell-root": {
      color: variableStyles.NaturalColor1000,
      fontWeight: variableStyles.fwMedium,
      fontSize: "10px",
      lineHeight: "15px",
      padding: "10px 12px",
      border: `none`,
      backgroundColor: variableStyles.NaturalColor300,
      "&.align-end": {
        textAlign: "right",
      },
    },
  },
  "& .MuiTableBody-root": {
    "& .MuiTableRow-root": {
      backgroundColor: variableStyles.NaturalColor100,
      "&:hover": {
        backgroundColor: variableStyles.NaturalColor0,
        "& .sticky-column": {
          backgroundColor: `${variableStyles.NaturalColor0} !important`,
        },
      },
      "&.box-empty-wrapper": {
        pointerEvents: "none",
      },
    },
    "& .MuiTableCell-root": {
      color: variableStyles.InfoA700,
      fontWeight: variableStyles.fwMedium,
      fontSize: "10px",
      lineHeight: "15px",
      padding: "6px 12px",
      height: "50px",
      borderBottom: `1px solid ${variableStyles.NaturalColor200}`,
      "&.align-end": {
        textAlign: "right",
        "&.table-cell-error-tag, & .table-cell-error-tag": {
          "& p": {
            marginLeft: " auto",
          },
        },
        "&.table-cell-success-tag, & .table-cell-success-tag": {
          "& p": {
            marginLeft: " auto",
          },
        },
        "&.table-cell-text-tag": {
          "& p": {
            marginLeft: " auto",
          },
        },
      },
    },
  },
  "& .MuiTableCell-root.table-cell-order": {
    padding: "10px 12px !important",
    maxWidth: "50px",
    width: "50px",
    flex: "0 0 auto",
    textAlign: "center",
    color: variableStyles.NaturalColor1000,
    fontWeight: variableStyles.fwMedium,
    fontSize: "10px",
    lineHeight: "15px",
    "&.sticky-column": {
      right: 0,
      left: "auto",
    },
  },
  "& .MuiTableCell-root.table-cell-link": {
    "& a": {
      color: variableStyles.blueSecondary700,
      fontWeight: variableStyles.fwMedium,
      fontSize: "11px",
      lineHeight: "13.31px",
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    "& button": {
      color: variableStyles.blueSecondary700,
      fontWeight: variableStyles.fwMedium,
      fontSize: "11px",
      lineHeight: "16.5px",
      width: "100%",
      textAlign: "left",
      display: "inline-block !important",
      backgroundColor: "transparent",
      textDecoration: "underline",
      "&:hover": {
        textDecoration: "none",
      },
    },
  },
  "& .MuiTableCell-root.table-cell-error-tag, & .MuiTableCell-root .table-cell-error-tag":
    {
      "& p": {
        color: variableStyles.Error500,
        fontWeight: variableStyles.fwMedium,
        fontSize: "10px",
        lineHeight: "15px",
        padding: "3.5px 8px",
        backgroundColor: variableStyles.NaturalColor0,
        borderRadius: "100px",
        width: "max-content",
      },
    },
  "& .MuiTableCell-root.table-cell-success-tag, & .MuiTableCell-root .table-cell-success-tag":
    {
      "& p": {
        color: variableStyles.Success500,
        fontWeight: variableStyles.fwMedium,
        fontSize: "10px",
        lineHeight: "15px",
        padding: "3.5px 8px",
        backgroundColor: variableStyles.NaturalColor0,
        borderRadius: "100px",
        width: "max-content",
      },
    },
  "& .MuiTableCell-root.table-cell-text-tag": {
    "& p": {
      color: variableStyles.NaturalColor950,
      fontWeight: variableStyles.fwMedium,
      fontSize: "10px",
      lineHeight: "15px",
      padding: "3.5px 8px",
      backgroundColor: variableStyles.NaturalColor0,
      borderRadius: "100px",
      width: "max-content",
      border: `1px solid ${variableStyles.NaturalColor300}`,
    },
  },
  "& .MuiTableCell-root .table-cell-link-error": {
    display: "flex",
    gap: "6px",
    alignItems: "center",
    "& button": { backgroundColor: "transparent" },
  },
  "& .ps__rail-x": {
    height: "7px",
  },
  "& .MuiTableCell-stickyHeader.sticky-column": { zIndex: "999" },
  "& .sticky-column": {
    position: "sticky",
    left: 0,
    background: variableStyles.NaturalColor100,
    zIndex: "99",
  },
}));
