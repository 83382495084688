import { styled } from "@mui/material/styles";

export const BacklinkDetailWrapper = styled("div")(() => ({
  "& .tab-common-wrapper": {
    padding: "20px 24px",
  },
  "& .tab-header-filter": {
    gap: "20px",
    marginBottom: "20px",
    "& .filter-right": {
      gap: "12px",
    },
    "& .content-location-filter": {
      margin: "0",
    },
  },
  "& .overview-tab-wrapper": {
    "@media (min-width: 1366px)": {
      "& .tab-header-filter ": {},
    },
    "@media (max-width: 1365.98px)": {
      "& .tab-header-filter ": {
        flexDirection: "column",
        gap: "6px",
        alignItems: "flex-start",
        "& .filter-left": {},
        "& .filter-right": {
          justifyContent: "flex-start",
        },
      },
    },
    "@media (min-width: 1020px)": {
      "& .action-select-backlinktype": {
        width: "126px",
      },
    },
    "@media (max-width: 1020px)": {
      "& .action-select-backlinktype": {
        width: "80px",
      },
      "& .tab-header-filter ": {
        "& .filter-left": {
          justifyContent: "space-between",
          gap: "12px",
          width: "100%",
        },
        "& .responsive-filter": {
          gap: "12px",
          flex: "0 0 auto",
        },
      },
    },
  },
}));
