import { Tooltip } from "@mui/material";
import { ImageCustom } from "../../../../../../../../../components";
import DotIcon from "../../../../../../../../../components/DotIcon";
import MultiColorProgress from "../MultiColorProgress";
import IconInfor from "../../../../../../../../../assets/images/domai-audit/overview-section/icon_infor_fill_grey.svg";
import { CoreWebVitalsItemWrapper } from "./styles";
import { EAuditErrorStatus } from "../../../../../../../../../models/common/models.enum";

interface CoreWebVitalsProps {
  title: string;
  label: string;
  value: number;
  status: EAuditErrorStatus;
  stages: {
    percentage: number;
  }[];
}

export default function CoreWebVitalsItem(props: CoreWebVitalsProps) {
  const { title, stages, value, label, status } = props;
  return (
    <CoreWebVitalsItemWrapper>
      <div className="performance-metrics-item-title flex-center">
        <DotIcon
          status={status}
          width={8}
          height={8}
          borderRadius={"50%"}
          className="dot-color"
        />
        <p className="fit-one-line">{title}</p>
        <Tooltip title={title} placement="top-start">
          <div>
            <ImageCustom
              src={IconInfor}
              alt="Icon-Infor"
              width={"14px"}
              height={"14px"}
            />
          </div>
        </Tooltip>
      </div>
      <div className="performance-metrics-item-progress">
        <MultiColorProgress
          value={value}
          stages={stages}
          label={label}
          status={status}
          width="95%"
        />
      </div>
    </CoreWebVitalsItemWrapper>
  );
}
