import React from "react";
import { EReportStatus } from "../../models/common/models.enum";
import { ReportStatusTagsWrapper } from "./styles";

interface ReportStatusTagsProps {
  status: EReportStatus;
}

export default function ReportStatusTags(props: ReportStatusTagsProps) {
  const { status } = props;

  const renderText = () => {
    switch (status) {
      case EReportStatus.Running:
        return "In Progress";
      case EReportStatus.Error:
        return "Error";
      case EReportStatus.Done:
        return "Done";
      default:
        return "Cancel";
    }
  };
  const renderClassname = () => {
    switch (status) {
      case EReportStatus.Running:
        return "blue";
      case EReportStatus.Error:
        return "orange";
      case EReportStatus.Done:
        return "green";
      default:
        return "grey";
    }
  };
  return (
    <ReportStatusTagsWrapper className={renderClassname()}>
      {renderText()}
    </ReportStatusTagsWrapper>
  );
}
