import { Popover } from "@mui/material";
import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";
import IconArrowFull from "../../assets/images/common/icon_arrow_full_line_blue.svg";

export const FormCheckLinkWrapper = styled("div")(({}) => ({
  width: "640px",
  margin: "50px auto 0",
  "& p": {
    "&.form-check-title, &.form-check-description": {
      textAlign: "center",
      width: "100%",
    },
    "&.form-check-title": {
      color: variableStyles.GreenPrimaryColor500,
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "28px",
      lineHeight: "33.89px",
      marginBottom: "12px",
    },
    "&.form-check-description": {
      color: variableStyles.NaturalColor950,
      fontWeight: variableStyles.fwMedium,
      fontSize: "14px",
      lineHeight: "21px",
      marginBottom: "40px",
    },
  },
  "& .form-wrapper": {
    background: variableStyles.NaturalColor0,
    border: `1px solid ${variableStyles.NaturalColor300}`,
    padding: "12px",
    borderRadius: "12px",
    boxShadow: "1px 2px 24px 0px #0000000F",
    "& .input-wrapper": {
      gap: "8px",
      marginBottom: "12px",
      "& .control-form-link": {
        flex: "1 1 auto",
      },
      "& .control-form-type": {
        flex: "0 0 auto",
        width: "210px",
      },
    },
    "& .button-submit": {
      width: "100%",
    },
  },
}));
