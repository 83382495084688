import React, { forwardRef, useEffect, useState } from "react";
import {
  Avatar,
  ImageCustom,
  Modal,
  StarRating,
} from "../../../../../components";
import OverviewCommonTitle from "../../OverviewCommonTitle";
import {
  CompanyProfileSectionWrapper,
  PopoverTimeWorkWrapper,
  PostListWrapper,
  ReviewListWrapper,
} from "./styles";
import IconStar from "../../../../../assets/images/common/icon_star_fill_yellow.svg";
import IconEarth from "../../../../../assets/images/common/icon_earth_line_grey.svg";
import IconLocation from "../../../../../assets/images/common/icon_location_square_line_grey.svg";
import IconPhone from "../../../../../assets/images/common/icon_phone_line_grey.svg";
import IconTime from "../../../../../assets/images/common/icon_time_line_grey.svg";
import IconArrowDown from "../../../../../assets/images/common/icon_arrow_down_line_black.svg";
import variableStyles from "../../../../../theme/variable-styles";
import IconChat from "../../../../../assets/images/common/icon_chat_fill_grey.svg";
import moment from "moment";
import { DATETIME_FORMAT } from "../../../../../config/constants";
import IconInfor from "../../../../../assets/images/common/icon_infor_line_grey.svg";
import { truncateText } from "../../../../../common/handles/common.handles";
import GoogleMyBusinessProfile from "./components/GoogleMyBusinessProfile";
import { IBasePagingRes } from "../../../../../models/common/models.type";
import { DEFAULT_BASE_PAGING_RES } from "../../../../../common/constants/value.constant";
import { Tooltip } from "@mui/material";
import AuditReportService from "../../../../../services/api/audit.report.service";
import {
  IBlogBusiness,
  IBusinessInfor,
  IReviewBusiness,
} from "../../../../../models/audit-report";
import { ESectionOfAuditReport } from "../../../../../models/common/models.enum";
import { useSelector } from "react-redux";
import { IStateApp } from "../../../../../models/redux";
import { IGetReportsRes } from "../../../../../models/report";

const CompanyProfileSection = forwardRef<any>(function (props, ref) {
  const dataReport = useSelector<IStateApp, IGetReportsRes>(
    appState => appState.project?.dataReport
  );

  const [openTime, setOpenTime] = useState<HTMLButtonElement | null>(null);
  const [openModal, setOpenModal] = useState<"review" | "post" | null>(null);
  const [resDataBusinessInfor, setResDataBusinessInfor] =
    useState<IBusinessInfor>();
  const [resDataReviewPaging, setResReviewPaging] = useState<
    IBasePagingRes<any>
  >({ ...DEFAULT_BASE_PAGING_RES });
  const [resDataReview, setResDataReview] = useState<IReviewBusiness>({
    totalCount: 0,
    ...DEFAULT_BASE_PAGING_RES,
  });
  const [resDataPostPaging, setResPostPaging] = useState<IBasePagingRes<any>>({
    ...DEFAULT_BASE_PAGING_RES,
  });
  const [resDataPost, setResDataPost] = useState<IBlogBusiness>({
    totalCount: 0,
    ...DEFAULT_BASE_PAGING_RES,
  });

  useEffect(() => {
    if (!!dataReport.thirdPartyId) {
      getDataofPage();
    }
  }, [dataReport.thirdPartyId]);

  const getDataofPage = async () => {
    const res = await AuditReportService.getAuditReportSection(
      ESectionOfAuditReport.COMPANYPROFILE,
      dataReport.thirdPartyId
    );
    if (!res.isError && !!res.data) {
      setResDataBusinessInfor(res.data.info);
      setResDataPost(res.data.blogs);
      setResDataReview(res.data.reviews);
    }
  };

  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const now = new Date();
  const day = dayNames.find((_, idx) => idx === now.getDay());

  const convertWorkTime = () => {
    const result: {
      date: string;
      time: { open: string; close: string } | null;
      status: boolean;
    }[] = [];
    if (!!resDataBusinessInfor) {
      const workTime = resDataBusinessInfor.work_time.work_hours.timetable;
      type TObj = {
        sunday: any[] | null;
        monday: any[] | null;
        tuesday: any[] | null;
        wednesday: any[] | null;
        thursday: any[] | null;
        friday: any[] | null;
        saturday: any[] | null;
      };

      let property: keyof TObj;
      for (property in workTime) {
        if (workTime.hasOwnProperty(property)) {
          const status = property === day?.toLowerCase();
          if (!workTime[property]) {
            result.push({ date: property, time: null, status: status });
          } else if (!!workTime[property]) {
            const data = workTime[property];
            if (!!data) {
              result.push({
                date: property,
                time: {
                  open: `${data[0].open.hour.toString().length > 1 ? data[0].open.hour : "0" + data[0].open.hour}${data[0].open.minute.toString().length > 1 ? ":" + data[0].open.minute : ":0" + data[0].open.minute}`,
                  close: `${data[0].close.hour.toString().length > 1 ? data[0].close.hour : "0" + data[0].close.hour}${data[0].close.minute.toString().length > 1 ? ":" + data[0].close.minute : ":0" + data[0].close.minute}`,
                },
                status: status,
              });
            }
          }
        }
      }
    }

    return result;
  };

  const renderActiveTime = () => {
    const result = convertWorkTime().find(
      work => work.date.toLowerCase() === day?.toLowerCase()
    );
    if (!!result) {
      return `${result.time?.open} - ${result.time?.close}`;
    }
  };

  const handleChangePage = (page: number, type: "review" | "post") => {
    const data =
      type === "review" ? [...resDataReview?.items] : [...resDataPost?.items];
    const newData = {
      page: page,
      pageSize: 10,
      total: data.length,
      items: [...data]?.slice(page * 10 - 10, page * 10),
      isFull: true,
    };
    if (type === "review") {
      setResReviewPaging(newData);
      if (openModal !== "review") {
        setOpenModal("review");
      }
    } else {
      setResPostPaging(newData);
      if (openModal !== "post") {
        setOpenModal("post");
      }
    }
  };

  return (
    <>
      <Modal
        title={"Đánh giá"}
        open={!!openModal}
        width={"698px"}
        onClose={() => setOpenModal(null)}
        background={variableStyles.NaturalColor0}
        pagination={{
          page:
            openModal === "review"
              ? resDataReviewPaging.page
              : resDataPostPaging.page,
          pageSize:
            openModal === "review"
              ? resDataReviewPaging.pageSize
              : resDataPostPaging.pageSize,
          total:
            openModal === "review"
              ? resDataReviewPaging.total
              : resDataPostPaging.total,
          onPageChange: page => {
            handleChangePage(page, openModal === "review" ? "review" : "post");
          },
          text: "Reviews",
        }}
      >
        {openModal === "review" ? (
          <ReviewListWrapper className="preview-list-modal scrollbar-small">
            {resDataReviewPaging.items.map(data => (
              <li key={data.review_id}>
                <ReviewBox
                  isFullText
                  data={{
                    profile_image_url: data.profile_image_url,
                    profile_name: data.profile_name,
                    reviews_count: data.reviews_count || 0,
                    photos_count: data.photos_count || 0,
                    review_text: data.review_text || "",
                    timestamp: data.timestamp || "",
                    rating: data.rating.value,
                  }}
                />
              </li>
            ))}
          </ReviewListWrapper>
        ) : (
          <PostListWrapper className="post-list-modal scrollbar-small">
            {resDataPostPaging.items.map(data => (
              <li key={data.url}>
                <p className="post-text">{data.snippet}</p>
                <div className="post-footer flex-between-align-center">
                  <a href={data.url} target="_blank" rel="noopener noreferrer">
                    Xem chi tiết
                  </a>
                  <Avatar
                    src={data.images_url}
                    alt="Avatar-Image"
                    width={28}
                    height={28}
                    borderColor={variableStyles.NaturalColor200}
                    className="header-avatar"
                  />
                </div>
              </li>
            ))}
          </PostListWrapper>
        )}
      </Modal>
      <PopoverTimeWorkWrapper
        open={!!openTime}
        anchorEl={openTime}
        onClose={() => setOpenTime(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ul className="list-time">
          {convertWorkTime().map(time => (
            <li key={time.date} className={`${time.status ? "active" : ""}`}>
              <p className="date-text">{time.date}</p>
              <p className="time-text">
                {!!time.time
                  ? `${time.time?.open} - ${time.time?.close}`
                  : "Close"}
              </p>
            </li>
          ))}
        </ul>
      </PopoverTimeWorkWrapper>
      <CompanyProfileSectionWrapper
        id="company-profile"
        ref={ref}
        className="common-layout"
      >
        <div className="company-profile-overview">
          <OverviewCommonTitle
            title="COMPANY PROFILE"
            description="Hồ sơ doanh nghiệp trên Google là một công cụ miễn phí của Google cho phép doanh nghiệp tạo và quản lý thông tin về cửa hàng hoặc dịch vụ của mình trên Google Search và Google Maps. Tạo và cập nhật đầy đủ thông tin liên lạc, địa chỉ, giờ hoạt động, đánh giá của khách hàng, và các thông tin quan trọng khác về doanh nghiệp giúp cải thiện khả năng tiếp cận và thu hút khách hàng tiềm năng."
            className="company-profile-title"
          />
          <div className="overview-wrapper">
            <div className="overview-infor flex-between-align-top">
              <div className="infor-name">
                <h3 className="name-text">
                  {resDataBusinessInfor?.title || "--"}
                </h3>
                <p className="name-subtext">
                  {resDataBusinessInfor?.category || "--"}
                </p>
              </div>
              <div className="infor-logo">
                <figure className="logo">
                  <ImageCustom
                    src={resDataBusinessInfor?.logo}
                    alt="Icon-CheckActive"
                    width={"86px"}
                    height={"86px"}
                  />
                </figure>
                <div className="logo-overlay">
                  <figure>
                    <ImageCustom
                      src={IconStar}
                      alt="Icon-Star"
                      width={"12px"}
                      height={"12px"}
                    />
                  </figure>
                  <p>{resDataBusinessInfor?.rating.value || 0}</p>
                </div>
              </div>
            </div>
            <ul className="overview-infor-detail">
              <div className="detail-group">
                <li>
                  <ImageCustom
                    src={IconEarth}
                    alt="Icon-Earth"
                    width={"16px"}
                    height={"16px"}
                  />
                  {!!resDataBusinessInfor && !!resDataBusinessInfor.domain ? (
                    <p
                      className="fit-one-line"
                    // href={resDataBusinessInfor.domain}
                    // target="_blank"
                    // rel="noopener noreferrer"
                    >
                      {resDataBusinessInfor.domain}
                    </p>
                  ) : (
                    <p>--</p>
                  )}
                </li>
                <li>
                  <ImageCustom
                    src={IconPhone}
                    alt="Icon-Phone"
                    width={"16px"}
                    height={"16px"}
                  />
                  <p className="fit-one-line">
                    {resDataBusinessInfor?.phone || "--"}
                  </p>
                </li>
              </div>
              <div className="detail-group">
                <li>
                  <ImageCustom
                    src={IconLocation}
                    alt="Icon-Location"
                    width={"16px"}
                    height={"16px"}
                  />
                  <p className="fit-one-line">
                    {resDataBusinessInfor?.address || "--"}
                  </p>
                </li>

                <li className="time-work-wrapper">
                  <button
                    onClick={e => {
                      setOpenTime(e.currentTarget);
                    }}
                  >
                    <ImageCustom
                      src={IconTime}
                      alt="Icon-Time"
                      width={"16px"}
                      height={"16px"}
                    />
                    <div className={`time-work ${!!openTime ? "active" : ""}`}>
                      <p>
                        {!!resDataBusinessInfor?.work_time.work_hours
                          .timetable ? (
                          <>{renderActiveTime()}</>
                        ) : (
                          "--"
                        )}
                      </p>
                      {!!resDataBusinessInfor?.work_time.work_hours
                        .timetable && (
                          <ImageCustom
                            src={IconArrowDown}
                            alt="Icon-Arrow-Down"
                            width={"16px"}
                            height={"16px"}
                            className="icon-arrow"
                          />
                        )}
                    </div>
                  </button>
                </li>
              </div>
            </ul>
            <div className="overview-maps">
              <ImageCustom
                src={resDataBusinessInfor?.main_image}
                alt="Icon-Arrow-Down"
                width={"100%"}
                height={"232px"}
                className="company-image"
              />
              <iframe
                src={`https://maps.google.com/maps?q=${resDataBusinessInfor?.latitude}, ${resDataBusinessInfor?.longitude}&hl=en&z=14&output=embed`}
                width="100%"
                height="232"
                style={{ border: 0 }}
                allowFullScreen={true}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
            {!!resDataReview && resDataReview.items.length > 0 && (
              <div className="overview-preview">
                <div className="preview-header flex-between-align-center">
                  <div className="header-title flex-start-align-center">
                    <p className="fit-one-line">
                      Đánh giá của khách hàng trên GMB
                    </p>
                    <Tooltip
                      title={"Đánh giá của khách hàng trên GMB"}
                      placement="top-start"
                    >
                      <div>
                        <ImageCustom
                          src={IconInfor}
                          alt="Icon-Infor"
                          width={"14px"}
                          height={"14px"}
                        />
                      </div>
                    </Tooltip>
                  </div>
                  <button
                    className="header-button"
                    onClick={() => handleChangePage(1, "review")}
                  >
                    Xem thêm
                  </button>
                </div>
                <ReviewListWrapper className="review-list">
                  {resDataReview?.items?.slice(0, 6).map(data => (
                    <li key={data.review_id}>
                      <ReviewBox
                        data={{
                          profile_image_url: data.profile_image_url,
                          profile_name: data.profile_name,
                          reviews_count: data.reviews_count || 0,
                          photos_count: data.photos_count || 0,
                          review_text: data.review_text || "",
                          timestamp: data.timestamp || "",
                          rating: data.rating.value,
                        }}
                      />
                    </li>
                  ))}
                </ReviewListWrapper>
              </div>
            )}
            {!!resDataPost?.items && resDataPost?.items?.length > 0 && (
              <div className="overview-preview">
                <div className="preview-header flex-between-align-center">
                  <div className="header-title flex-start-align-center">
                    <p className="fit-one-line">Bài viết</p>
                    <Tooltip title={"Đăng các bài viết chia sẻ thông tin, sự kiện hoặc cập nhật mới về doanh nghiệp giúp nâng cao tương tác và cải thiện thứ hạng tìm kiếm địa phương của doanh nghiệp.Chọn lọc các nội dung hấp dẫn, hữu ích, đồng thời đăng bài viết mới thường xuyên để duy trì sự hiện diện và tương tác với khách hàng."} placement="top-start">
                      <div>
                        <ImageCustom
                          src={IconInfor}
                          alt="Icon-Infor"
                          width={"14px"}
                          height={"14px"}
                        />
                      </div>
                    </Tooltip>
                  </div>
                  <button
                    className="header-button"
                    onClick={() => {
                      const newWindow = window.open(
                        `${resDataPost?.items[0].url}`,
                        "_blank",
                        "noopener,noreferrer"
                      );
                      if (newWindow) newWindow.opener = null;
                    }}
                  >
                    Xem tất cả
                  </button>
                </div>
                <PostListWrapper className="post-list">
                  {resDataPost?.items?.slice(0, 6).map(data => (
                    <li key={data.url}>
                      <p className="post-text fit-two-line">{data.snippet}</p>
                      <div className="post-footer flex-between-align-center">
                        <a
                          href={data.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Xem chi tiết
                        </a>
                        <Avatar
                          src={data.images_url}
                          alt="Avatar-Image"
                          width={28}
                          height={28}
                          borderColor={variableStyles.NaturalColor200}
                          className="header-avatar"
                        />
                      </div>
                    </li>
                  ))}
                </PostListWrapper>
              </div>
            )}
          </div>
        </div>
        <GoogleMyBusinessProfile
          data={[
            {
              title: "Tên doanh nghiệp",
              description: resDataBusinessInfor?.title || "",
              comment: {
                title: "Nhận xét",
                content:
                  "Tên doanh nghiệp là tên chính thức của công ty hoặc tổ chức, được sử dụng để nhận diện và đại diện cho doanh nghiệp trong mọi hoạt động kinh doanh. Đảm bảo tên doanh nghiệp trên Google My Business đồng nhất với tên trên website, mạng xã hội, biển hiệu,...",
              },
              status: !!resDataBusinessInfor?.title,
            },
            {
              title: "Lĩnh vực kinh doanh",
              description: resDataBusinessInfor?.category || "",
              comment: {
                title: "Nhận xét",
                content:
                  "Ngành kinh doanh là danh mục phân loại hình thức kinh doanh của doanh nghiệp, giúp website hiển thị trên trang kết quả phù hợp khi người dùng tìm kiếm trên google map. Chọn ngành kinh doanh phản ánh chính xác và cụ thể nhất hoạt động kinh doanh chính của doanh nghiệp. Ngoài danh mục chính có thể chọn thêm các danh mục phụ để mô tả đầy đủ về sản phẩm/dịch vụ cung cấp.",
              },
              status: !!resDataBusinessInfor?.category,
            },
            {
              title: "Địa chỉ",
              description: resDataBusinessInfor?.address,
              comment: {
                title: "Nhận xét",
                content:
                  "Địa chỉ trên Hồ sơ Doanh nghiệp Google là thông tin vị trí chính xác của doanh nghiệp bạn được hiển thị trên Google. Tạo và xác thực địa chỉ trên hồ sơ doanh nghiệp giúp tăng khả năng hiển thị doanh nghiệp trong tìm kiếm địa phương đồng thời tạo sự tin cậy cho khách hàng.",
              },
              status: !!resDataBusinessInfor?.address,
            },
            {
              title: "Số điện thoại",
              description: resDataBusinessInfor?.phone,
              comment: {
                title: "Nhận xét",
                content:
                  "Là số liên lạc mà doanh nghiệp cung cấp để khách hàng có thể gọi điện nhờ tu vấn hoặc hỗ trợ. Đảm bảo số điện thoại trên hồ sơ doanh nghiệp Google đồng nhất với số điện thoại trên website và các trang mạng xã hội khác, đồng thời số điện thoại cần luôn hoạt động để khách hàng có thể liên lạc dễ dàng.",
              },
              status: !!resDataBusinessInfor?.phone,
            },
            {
              title: "Trang web",
              description: resDataBusinessInfor?.domain,
              comment: {
                title: "Nhận xét",
                content:
                  "Trang web là địa chỉ trực tuyến (URL) mà doanh nghiệp sử dụng để cung cấp thông tin chi tiết về sản phẩm, dịch vụ và các hoạt động của doanh nghiệp. Đảm bảo liên kết hồ sơ doanh nghiệp với trang chủ hoặc trang đích quan trọng nhất trên website.",
              },
              status: !!resDataBusinessInfor?.domain,
            },
            {
              title: "Giờ làm việc",
              description: convertWorkTime(),
              comment: {
                title: "Nhận xét",
                content:
                  "Là thời gian mà doanh nghiệp mở cửa và sẵn sàng phục vụ khách hàng. Đảm bảo giờ mở cửa và đóng cửa cung cấp trên Google Maps trùng khớp với giờ hoạt động thực tế của doanh nghiệp.",
              },
              status: convertWorkTime().length > 0,
            },
            {
              title: "Hình ảnh",
              comment: {
                title: "Nhận xét",
                content:
                  "Là thời gian mà doanh nghiệp mở cửa và sẵn sàng phục vụ khách hàng. Đảm bảo giờ mở cửa và đóng cửa cung cấp trên Google Maps trùng khớp với giờ hoạt động thực tế của doanh nghiệp.",
              },
              status: !!resDataBusinessInfor?.total_photos,
            },
            {
              title: "Bài đăng",
              comment: {
                title: "Nhận xét",
                content:
                  "Lorem ipsum dolor sit amet, consectetur apiscing elit, sed do eiusmod tempor incididunt ut labore Lorem ipsum dolor sit amet, consectetur apiscing elit, sed do eiusmod tempor incididunt ut labore. Lorem ipsum dolor sit amet, consectetur apiscing elit, sed do eiusmod tempor incididunt ut labore Lorem ipsum dolor sit amet, consectetur apiscing elit, sed do eiusmod tempor incididunt ut labore",
              },
              status: resDataPost?.items?.length > 0,
            },
            {
              title: "Đánh giá",
              comment: {
                title: "Nhận xét",
                content:
                  "Đánh giá là những nhận xét và xếp hạng mà khách hàng để lại trên hồ sơ doanh nghiệp của bạn trên Google, phản ánh trải nghiệm của họ với sản phẩm hoặc dịch vụ mà bạn cung cấp. Khuyến khích khách hàng để lại nhận xét để tăng số lượng đánh giá,  giúp xây dựng độ tin cậy và uy tín cho doanh nghiệp, ảnh hưởng tích cực đến quyết định của khách hàng tiềm năng.",
              },
              status: resDataReview?.items?.length > 0,
            },
            {
              title: "Phản hồi đánh giá",
              comment: {
                title: "Nhận xét",
                content:
                  "Phản hồi các đánh giá của khách hàng trên hồ sơ doanh nghiệp Google khiến khách hàng cảm thấy bạn thật sự quan tâm đến ý kiến của họ. Dành thời gian để trả lời tất cả các đánh giá, cả tích cực lẫn tiêu cực là một cách giúp doanh nghiệp xây dựng lòng tin và uy tín với khách hàng.",
              },
              status: !!resDataBusinessInfor?.title,
            },
          ]}
        />
      </CompanyProfileSectionWrapper>
    </>
  );
});
export default CompanyProfileSection;

interface reviewProps {
  data: {
    profile_image_url: string;
    profile_name: string;
    reviews_count: number;
    photos_count: number;
    review_text: string;
    timestamp: string;
    rating: number;
  };
  isFullText?: boolean;
}

const ReviewBox = (props: reviewProps) => {
  const { data, isFullText = false } = props;
  return (
    <>
      <div className="list-header">
        <div className="header-infor-wrapper">
          <Avatar
            src={data.profile_image_url}
            alt="Avatar-Image"
            width={28}
            height={28}
            borderColor={variableStyles.NaturalColor50}
            className="header-avatar"
          />
          <div className="header-infor">
            <p className="infor-name fit-one-line">
              {truncateText(data.profile_name, 23)}
            </p>
            <p className="infor-count fit-one-line">
              {data.reviews_count} bài đánh giá{" "}
              {!!data.photos_count && `- ${data.photos_count} ảnh`}
            </p>
          </div>
        </div>
        <ImageCustom
          src={IconChat}
          alt="Icon-Chat"
          width={"18px"}
          height={"18px"}
          className="header-icon"
        />
      </div>
      <div className="list-content">
        <p className={`content-text ${isFullText ? "" : "fit-three-line"}`}>
          {data.review_text}
        </p>
        <div className="content-footer flex-between-align-center">
          <p className="footer-time">
            {moment(data.timestamp).format(DATETIME_FORMAT)}
          </p>
          <StarRating disabled activeStarProps={data.rating} />
        </div>
      </div>
    </>
  );
};
