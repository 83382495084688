import queryString from "query-string";
import React from "react";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { ROUTE_PATH } from "../../../../common/constants/app.constant";
import { TabsCustom } from "../../../../components";
import { ETypeLinkCheck } from "../../../../models/common/models.enum";
import { IValue } from "../../../../models/common/models.type";
import CheckToolTitle from "../../components/CheckToolTitle";
import OrganicCompetitorsTab from "./components/OrganicKeywordTabs/OrganicCompetitorsTab";
import OrganicKeywordsTab from "./components/OrganicKeywordTabs/OrganicKeywordsTab";
import TopPageTab from "./components/OrganicKeywordTabs/TopPageTab";
import { OrganicKeywordCheckWrapper } from "./styles";

enum EOrganicKeywordTabs {
  OrganicKeywords = "organickeywords",
  TopPage = "toppage",
  OrganicCompetitors = "organiccompetitors",
}

export interface IDataFilterOrganicKeywordType {
  target: string | null;
  type: string | null;
  tab: string | null;
  searchLink: string | null;
  searchLinkType: string | null;
  positionFrom: string | null;
  positionTo: string | null;
  volumeFrom: string | null;
  volumeTo: string | null;
  kdFrom: string | null;
  kdTo: string | null;
  trafficFrom: string | null;
  trafficTo: string | null;
  intent: string[] | null;
  numberKeywordFrom: string | null;
  numberKeywordTo: string | null;
}

export enum ETypeOrganicKeywordCheck {}

export default function OrganicKeywordCheck() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const dataFilter: IDataFilterOrganicKeywordType = {
    target: searchParams.get("target"),
    type: searchParams.get("type"),
    tab: searchParams.get("tab") || EOrganicKeywordTabs.OrganicKeywords,
    searchLink: searchParams.get("searchlink"),
    searchLinkType: searchParams.get("searchlinktype"),
    positionFrom: searchParams.get("positionfrom"),
    positionTo: searchParams.get("positionto"),
    volumeFrom: searchParams.get("volumefrom"),
    volumeTo: searchParams.get("volumeto"),
    kdFrom: searchParams.get("kdfrom"),
    kdTo: searchParams.get("kdto"),
    trafficFrom: searchParams.get("trafficfrom"),
    trafficTo: searchParams.get("trafficto"),
    intent: searchParams.getAll("intent"),
    numberKeywordFrom: searchParams.get("numberkeywordfrom"),
    numberKeywordTo: searchParams.get("numberkeywordto"),
  };

  const dataTabs: IValue[] = [
    { value: EOrganicKeywordTabs.OrganicKeywords, title: "Organic keywords" },
    { value: EOrganicKeywordTabs.TopPage, title: "Top page" },
    {
      value: EOrganicKeywordTabs.OrganicCompetitors,
      title: "Organic Competitors",
    },
  ];
  return (
    <OrganicKeywordCheckWrapper>
      <CheckToolTitle
        title="Domain overview"
        domain={dataFilter.target || ""}
        onSubmit={value => {
          const path = generatePath(ROUTE_PATH.ORGANIC_KEYWORD_CHECK);
          const newParams = queryString.stringify({
            ...value,
            tab: dataFilter.tab,
          });
          navigate({
            pathname: `${path}`,
            search: `${newParams}`,
          });
        }}
        value={{
          target: dataFilter.target,
          type: dataFilter.type as ETypeLinkCheck,
        }}
      />
      <TabsCustom
        data={dataTabs}
        onChange={(v: EOrganicKeywordTabs) => {
          const path = generatePath(ROUTE_PATH.ORGANIC_KEYWORD_CHECK);
          const newParams = queryString.stringify({
            tab: v,
            target: dataFilter.target,
            type: dataFilter.type,
          });
          navigate({
            pathname: `${path}`,
            search: `${newParams}`,
          });
        }}
        selectedValue={dataFilter.tab}
      />
      {dataFilter.tab === EOrganicKeywordTabs.OrganicKeywords ? (
        <OrganicKeywordsTab
          dataFilter={dataFilter}
          onChangeParamsURL={value => {
            const path = generatePath(ROUTE_PATH.ORGANIC_KEYWORD_CHECK);
            navigate({
              pathname: `${path}`,
              search: `${value}`,
            });
          }}
        />
      ) : dataFilter.tab === EOrganicKeywordTabs.TopPage ? (
        <TopPageTab
          dataFilter={dataFilter}
          onChangeParamsURL={value => {
            const path = generatePath(ROUTE_PATH.ORGANIC_KEYWORD_CHECK);
            navigate({
              pathname: `${path}`,
              search: `${value}`,
            });
          }}
        />
      ) : (
        <OrganicCompetitorsTab />
      )}
    </OrganicKeywordCheckWrapper>
  );
}
