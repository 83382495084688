import { Box, Divider, Paper, SxProps, Theme, Typography } from "@mui/material";
import { EStrategy } from "../ScreenshotOnDevice";
import { PageSpeedOnDeviceWrapper } from "./styles";
import ProgressHalfCircle from "./components/ProgressHalfCircle";
import {
  AuditRef,
  IGooglePageSpeed,
} from "../../../../../../../models/google/google.page.speed";
import PerformanceSection from "./components/PerformanceSection";
import OrtherCategorySection from "./components/OrtherCategorySection";
import LoadingExperience from "./components/LoadingExperience";

interface IProps {
  strategy: EStrategy;
  sx?: SxProps<Theme>;
  pageSpeedData?: IGooglePageSpeed;
}

export const getPassedAuditIds = (auditRefs?: AuditRef[]) => {
  if (!auditRefs) {
    return [];
  }
  return auditRefs.filter(f =>
    f.group ? f.group === "hidden" && f.id !== "screenshot-thumbnails" : false
  );
};

const PageSpeedOnDevice = ({ pageSpeedData, sx, strategy }: IProps) => {
  if (!pageSpeedData) {
    return null;
  }
  const performance = pageSpeedData?.lighthouseResult.categories.performance;
  const accessibility =
    pageSpeedData?.lighthouseResult.categories.accessibility ?? 0;
  const bestPractices =
    pageSpeedData?.lighthouseResult.categories["best-practices"] ?? 0;
  const seo = pageSpeedData?.lighthouseResult.categories.seo ?? 0;

  return (
    <PageSpeedOnDeviceWrapper sx={sx}>
      <Typography variant="subtitle2">
        Khám phá nội dung người dùng thực tế của bạn đang trải nghiệm
      </Typography>

      <LoadingExperience
        loadingExperience={pageSpeedData.loadingExperience}
        companyName="Branch name"
      />

      <Typography variant="subtitle2" sx={{ mt: "24px" }}>
        Chẩn đoán các vấn đề về hiệu suất
      </Typography>

      <Paper className="page-wrapper">
        <Box className="category-wrapper">
          <Box className="flex-center" sx={{ gap: "12px", flexWrap: "wrap" }}>
            <ProgressHalfCircle
              value={performance?.score}
              lable={performance?.title}
            />
            <ProgressHalfCircle
              value={accessibility?.score}
              lable={accessibility?.title}
            />
            <ProgressHalfCircle
              value={bestPractices?.score}
              lable={bestPractices?.title}
            />
            <ProgressHalfCircle value={seo?.score} lable={seo?.title} />
          </Box>
        </Box>
        {/* category */}
        {/* #region performances */}
        <PerformanceSection
          data={pageSpeedData.lighthouseResult.categories.performance}
          fullPageScreenshot={pageSpeedData.lighthouseResult.fullPageScreenshot}
          audits={pageSpeedData.lighthouseResult.audits}
          stackPacks={pageSpeedData.lighthouseResult.stackPacks}
          i18n={pageSpeedData.lighthouseResult.i18n}
          categoryGroups={pageSpeedData.lighthouseResult.categoryGroups}
          strategy={strategy}
        />
        {/* #endregion performances */}
        <Divider sx={{ mt: 3 }} />
        {/* #region Accessibility */}
        <OrtherCategorySection
          data={pageSpeedData.lighthouseResult.categories.accessibility}
          audits={pageSpeedData.lighthouseResult.audits}
          stackPacks={pageSpeedData.lighthouseResult.stackPacks}
          categoryGroups={pageSpeedData.lighthouseResult.categoryGroups}
          i18n={pageSpeedData.lighthouseResult.i18n}
        />
        {/* #endregion Accessibility */}
        {/* #endregion performances */}
        <Divider sx={{ mt: 3 }} />
        {/* #region best-practices */}
        <OrtherCategorySection
          data={pageSpeedData.lighthouseResult.categories["best-practices"]}
          audits={pageSpeedData.lighthouseResult.audits}
          stackPacks={pageSpeedData.lighthouseResult.stackPacks}
          categoryGroups={pageSpeedData.lighthouseResult.categoryGroups}
          i18n={pageSpeedData.lighthouseResult.i18n}
        />
        {/* #endregion best-practices */}
        {/* #endregion performances */}
        <Divider sx={{ mt: 3 }} />
        {/* #region seo */}
        <OrtherCategorySection
          data={pageSpeedData.lighthouseResult.categories.seo}
          audits={pageSpeedData.lighthouseResult.audits}
          stackPacks={pageSpeedData.lighthouseResult.stackPacks}
          categoryGroups={pageSpeedData.lighthouseResult.categoryGroups}
          i18n={pageSpeedData.lighthouseResult.i18n}
        />
        {/* #endregion seo */}
      </Paper>
    </PageSpeedOnDeviceWrapper>
  );
};

export default PageSpeedOnDevice;
