import { styled } from "@mui/material/styles";
import variableStyles from "../../../theme/variable-styles";

export const DomainAuditDetailWrapper = styled("div")(() => ({
  backgroundColor: variableStyles.NaturalColor50,
  "& .domain-audit-header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 24px",
    "& .header-name": {
      color: variableStyles.NaturalColor1000,
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "24px",
      lineHeight: "29.05px"
    },
    "& .header-action": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      gap: "10px",
      "& .download-button": {
        gap: "6px"
      },
      "& .share-button": { minWidth: "90px" }
    }
  }
}));
