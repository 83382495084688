import { Tooltip } from "@mui/material";
import { ImageCustom } from "..";
import { IndexNoPercentWrapper } from "./styles";
import IconInfor from "../../assets/images/common/icon_infor_line_grey.svg";

interface IndexNoPercentProps {
  title: string;
  infor: string;
  value: number;
}

export default function IndexNoPercentBox(props: {
  data: IndexNoPercentProps;
}) {
  const { data } = props;
  return (
    <IndexNoPercentWrapper>
      <div className="box-header flex-center">
        <p className="fit-one-line">{data.title}</p>
        <Tooltip title={data.infor} placement="top">
          <div>
            <ImageCustom
              src={IconInfor}
              alt="Icon-Infor"
              width={"14px"}
              height={"14px"}
              className={`tooltip-icon`}
            />
          </div>
        </Tooltip>
      </div>
      <p className="box-number">{data.value}</p>
    </IndexNoPercentWrapper>
  );
}
