import { styled } from "@mui/material/styles";
import { Dialog } from "@mui/material";
import variableStyles from "../../theme/variable-styles";

interface ModalCustomProps {
  width?: string;
  maxheight: string;
  background: string;
}

export const ModalCustomWrapper = styled(Dialog)<ModalCustomProps>(
  ({ theme, width, maxheight, background }) => ({
    "& .MuiPaper-root": {
      borderRadius: "12px",
      minWidth: !!width ? width : "407px",
      width: !!width ? width : "407px",
      maxHeight: maxheight,
      maxWidth: "unset",
      padding: "20px 12px 16px",
      backgroundColor: background,
      "& .modal-header": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        marginBottom: "16px",
        gap: "12px",
        "& .header-content": {
          "& .modal-title": {
            color: variableStyles.NaturalColor1000,
            fontWeight: variableStyles.fwSemiBold,
            fontSize: "20px",
            lineHeight: "23px",
            marginBottom: "8px",
          },
          "& .modal-description": {
            color: variableStyles.NaturalColor800,
            fontWeight: variableStyles.fwMedium,
            fontSize: "12px",
            lineHeight: "16.8px",
          },
        },
        "& .button-close": {
          borderRadius: "50%",
          minWidth: "28px",
        },
      },

      "& .modal-content": {
        marginBottom: "16px",
      },
      "& .modal-action": {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "9px",
        "& .button-cancel": {
          border: `1px solid ${variableStyles.NaturalColor400}`,
          color: variableStyles.NaturalColor950,
          fontWeight: variableStyles.fwSemiBold,
          fontSize: "14px",
          lineHeight: "14px",
          "&:hover": { backgroundColor: variableStyles.NaturalColor50 },
        },
        "& .button-submit": {
          "& .text-submit": {
            "&.is-loading": {
              visibility: "hidden",
            },
          },
        },
      },
    },
  })
);
