import React, { useEffect } from "react";
import { ImageCustom } from "../../../../../components";
import OverviewNavbar from "../../OverviewSidebar";
import { OverviewDomainAuditWrapper } from "./styles";
import PerformSection from "../../OverviewSection/PerformSection";
import ImageMain from "../../../../../assets/images/domai-audit/overview-section/img_overview_main_image.png";
import IconOptimize from "../../../../../assets/images/domai-audit/overview-section/icon_overview_optimize.svg";
import IconUX from "../../../../../assets/images/domai-audit/overview-section/icon_overview_UX.svg";
import IconSEO from "../../../../../assets/images/domai-audit/overview-section/icon_overview_SEO.svg";
import SecuritySection from "../../OverviewSection/SecuritySection";
import OptimizeSection from "../../OverviewSection/OptimizeSection";
import SEOSection from "../../OverviewSection/SEOSection";
import OverviewIndexBox from "../../../../../components/OverviewIndexBox";
import LogoTemp from "../../../../../assets/images/temp/temp_avatar.png";
import { useInView } from "react-intersection-observer";
import CompanyProfileSection from "../../OverviewSection/CompanyProfileSection";

export default function OverviewTab() {
  const [section1Ref, section1InView] = useInView();
  const [section2Ref, section2InView] = useInView();
  const [section3Ref, section3InView] = useInView();
  const [section4Ref, section4InView] = useInView();
  const [section5Ref, section5InView] = useInView();

  useEffect(() => {
    result();
  }, []);

  const handleScrollto = (value: string) => {
    const findId = () => {
      switch (value) {
        case "0":
          return "perform-section";
        case "1":
          return "security-section";
        case "2":
          return "optimize-section";
        case "3":
          return "seo-section";
        default:
          return "company-profile";
      }
    };
    const element = document.getElementById(findId());
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  const result = () => {
    // const res = RegionService.getGGMapsPlacePlatform();
  };

  const indexData: {
    type: "normal" | "warning" | "danger";
    percent: number;
    title: string;
    icon?: string;
    logo?: string;
    infor: string;
  }[] = [
    {
      type: "normal",
      percent: 20,
      title: "Trải nghiệm người dùng",
      icon: IconSEO,
      infor:
        "Trải nghiệm người dùng đề cập đến quá trình tương tác của người dùng trên website từ việc tìm kiếm thông tin, mua sắm và các hành động khác. Một website được thiết kế với giao diện trực quan, dễ sử dụng, tốc độ nhanh chóng sẽ mang đến những trải nghiệm tốt cho người dùng từ đó giúp gia tăng tỉ lệ chuyển đổi, nâng cao uy tín thương hiệu và cải thiện thứ hạng website trên công cụ tìm kiếm.",
    },
    {
      type: "warning",
      percent: 10,
      title: "SEO Website",
      icon: IconUX,
      infor:
        "SEO là quá trình giúp website dễ dàng được tìm thấy bởi người dùng khi họ tìm kiếm thông tin trên công cụ tìm kiếm. Việc đánh giá SEO website sẽ chỉ ra những điểm mạnh, điểm yếu và cơ hội cải thiện để website có thể đạt được thứ hạng cao hơn trên kết quả tìm kiếm, từ đó thu hút nhiều lượt truy cập hơn so với đối thủ.",
    },
    {
      type: "normal",
      percent: 20,
      title: "Tối ưu chuyển đổi",
      icon: IconOptimize,
      infor:
        "Tối ưu chuyển đổi là một hệ thống các yếu tố nhằm tăng tỉ lệ người dùng thực hiện hành động cụ thể trên website như điền form, mua hàng hoặc các hoạt động chuyển đổi khác.",
    },
    {
      type: "normal",
      percent: 20,
      title: "Company profile",
      logo: LogoTemp,
      infor:
        "Google Business Profile là một dịch vụ miễn phí của Google cho phép doanh nghiệp cập nhật các thông tin cơ bản, hình ảnh, thông tin về sản phẩm và dịch vụ,...Sở hữu một hồ sơ doanh nghiệp trên Google được tối ưu tốt giúp doanh nghiệp tăng độ hiển thị và tăng sự tin cậy đối với người dùng.",
    },
  ];

  return (
    <OverviewDomainAuditWrapper id="divToPrint">
      <OverviewNavbar
        onScrollto={v => {
          handleScrollto(v);
        }}
        activeValue={
          section1InView
            ? "0"
            : section2InView
              ? "1"
              : section3InView
                ? "2"
                : section4InView
                  ? "3"
                  : section5InView
                    ? "4"
                    : ""
        }
      />
      <div className="overview-content scrollbar">
        <div className="overview-introduction-wrapper common-layout">
          <div className="overview-introduction">
            <div className="introduction-content">
              <h1 className="content-title">Website Audit Report</h1>
              <p className="content-description">
                Here's the audit report our agency and others use to close
                hundreds of deals. This audit report is perfect for delivering
                value, building trust, and establishing your expertise.{" "}
                <span>
                  We spent countless hours perfecting this audit report and
                  figuring out what consistently works... So you don't have to!
                </span>
              </p>
            </div>
            <figure className="introduction-image">
              <ImageCustom
                src={ImageMain}
                alt="Icon-Main"
                width={"354px"}
                height={"419px"}
              />
            </figure>
          </div>
          <ul className="index-list">
            {indexData.map(data => (
              <li key={data.title}>
                <OverviewIndexBox
                  type={data.type}
                  percent={data.percent}
                  title={data.title}
                  icon={data.icon}
                  logo={data.logo}
                  infor={data.infor}
                />
              </li>
            ))}
          </ul>
        </div>
        <PerformSection ref={section1Ref} />
        <SecuritySection ref={section2Ref} />
        <OptimizeSection ref={section3Ref} />
        <SEOSection ref={section4Ref} />
        <CompanyProfileSection ref={section5Ref} />
      </div>
    </OverviewDomainAuditWrapper>
  );
}
