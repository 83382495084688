import React from "react";
import { CommonReportListPage } from "../../components";
import { EReportType } from "../../models/common/models.enum";
import { DomainAuditWrapper } from "./styles";

export default function DomainAudit() {
  return (
    <DomainAuditWrapper>
      <CommonReportListPage type={EReportType.DomainAudit} />
    </DomainAuditWrapper>
  );
}
