import { ROUTE_PATH } from "./common/constants/app.constant";
import { RouteObject } from "react-router";
import { Roles } from "./models/common/models.enum";
import { Navigate } from "react-router-dom";
import { INavItem } from "./models/app";

import Login from "./pages/Login";
import NotFound from "./pages/NotFound";

import DashboardLayout from "./components/dashboard/DashboardLayout";
import UnAuthLayout from "./components/dashboard/UnAuthLayout";

import Index from "./pages/MyProject";
import MyProjectReport from "./pages/MyProject/ProjectReports";
import KeywordDensity from "./pages/KeywordDensity";
import AuditPage from "./pages/Audit/AuditPage";
import KeywordGroup from "./pages/KeywordGroup";
import Material from "./pages/Material/Material";
import Outline from "./pages/Outline";
import Posts from "./pages/Outline/components/Posts";
import Post from "./pages/Outline/components/Posts/Post";
import PotentialKeyword from "./pages/PotentialKeyword";
import CompressPhoto from "./pages/CompressPhoto";

import MyProjectIcon from "./assets/images/sidebar/icon_myproject_line_grey.svg";
import MyProjectIconActive from "./assets/images/sidebar/icon_myproject_active.svg";
import DomainAuditIcon from "./assets/images/sidebar/icon_domainaudit_line_grey.svg";
import DomainAuditIconActive from "./assets/images/sidebar/icon_domainaudit_active.svg";
import DomainCompetitiveIcon from "./assets/images/sidebar/icon_domaincompetitive_line_grey.svg";
import DomainCompetitiveIconActive from "./assets/images/sidebar/icon_domaincompetitive_active.svg";
import PageAuditIcon from "./assets/images/sidebar/icon_pageaudit_line_grey.svg";
import PageAuditIconActive from "./assets/images/sidebar/icon_pageaudit_active.svg";
import PageCompetitiveIcon from "./assets/images/sidebar/icon_pagecompetitive_line_grey.svg";
import PageCompetitiveIconActive from "./assets/images/sidebar/icon_pagecompetitive_active.svg";
import OptimizeHeaderIcon from "./assets/images/sidebar/icon_optimizeheader_line_grey.svg";
import OptimizeHeaderIconActive from "./assets/images/sidebar/icon_optimizeheader_active.svg";
import KeywordDensityIcon from "./assets/images/sidebar/icon_keyworddensity_line_grey.svg";
import KeywordDensityIconActive from "./assets/images/sidebar/icon_keyworddensity_active.svg";
import PotentialKeywordIcon from "./assets/images/sidebar/icon_potentialkeyword_line_grey .svg";
import PotentialKeywordIconActive from "./assets/images/sidebar/icon_potentialkeyword_active .svg";
import KeywordGroupIcon from "./assets/images/sidebar/icon_keywordgroup_line_grey.svg";
import KeywordGroupIconActive from "./assets/images/sidebar/icon_keywordgroup_active.svg";
import CompressPhotoIcon from "./assets/images/sidebar/icon_compressphoto_line_grey.svg";
import CompressPhotoIconActive from "./assets/images/sidebar/icon_compressphoto_active.svg";
import OutlineIcon from "./assets/images/sidebar/icon_outline_line_grey.svg";
import OutlineIconActive from "./assets/images/sidebar/icon_outline_active.svg";
import IconDomainOverview from "./assets/images/common/icon_earthWitharrow_line_grey.svg";
import IconDomainOverviewActive from "./assets/images/common/icon_earthWitharrow_line_green.svg";
import IconBacklink from "./assets/images/common/icon_link_line_grey.svg";
import IconBacklinkActive from "./assets/images/common/icon_link_line_green.svg";
import IconOrganicKeyword from "./assets/images/common/icon_Ttext_line_grey.svg";
import IconOrganicKeywordActive from "./assets/images/common/icon_Ttext_fill_green.svg";

import DomainAudit from "./pages/DomainAudit";
import DomainAuditDetail from "./pages/DomainAudit/DomainAuditDetail";
import DomainAuditError from "./pages/DomainAudit/DomainAuditErrorPage";
import DomainOverview from "./pages/OverviewCheckTool/DomainOverview";
import Backlink from "./pages/OverviewCheckTool/Backlink";
import OrganicKeyword from "./pages/OverviewCheckTool/OrganicKeyword";
import BackLinkCheck from "./pages/OverviewCheckTool/Backlink/BackLinkCheck";
import DomainOverviewCheck from "./pages/OverviewCheckTool/DomainOverview/DomainOverviewCheck";
import OrganicKeywordCheck from "./pages/OverviewCheckTool/OrganicKeyword/OrganicKeywordCheck";

export const sidebarItems = (role?: Roles): INavItem[] => {
  const OTHERTOOLS: INavItem = {
    title: "",
    code: "Other tool",
    children: [
      {
        title: "Domain Overview",
        code: "domain-overview",
        href: ROUTE_PATH.DOMAIN_OVERVIEW,
        icon: { default: IconDomainOverview, active: IconDomainOverviewActive },
      },
      {
        title: "Backlink",
        code: "backlink",
        href: ROUTE_PATH.BACKLINK,
        icon: { default: IconBacklink, active: IconBacklinkActive },
      },
      {
        title: "Organic keyword",
        code: "organic-keyword",
        href: ROUTE_PATH.ORGANIC_KEYWORD,
        icon: { default: IconOrganicKeyword, active: IconOrganicKeywordActive },
      },
    ],
  };

  const MYPROJECT: INavItem = {
    title: "My Project",
    code: "My Project",
    children: [
      {
        title: "My Project",
        code: "project",
        href: ROUTE_PATH.MY_PROJECT,
        icon: { default: MyProjectIcon, active: MyProjectIconActive },
      },
    ],
  };

  const OVERVIEW: INavItem = {
    title: "Overview",
    code: "Overview",
    children: [
      {
        title: "Domain audit",
        code: "domain-audit",
        href: ROUTE_PATH.DOMAIN_AUDIT,
        icon: { default: DomainAuditIcon, active: DomainAuditIconActive },
      },
      {
        title: "Domain competitive",
        code: "domain-competitive",
        href: ROUTE_PATH.DOMAIN_COMPETITIVE,
        icon: {
          default: DomainCompetitiveIcon,
          active: DomainCompetitiveIconActive,
        },
      },
      {
        title: "Page audit",
        code: "page-audit",
        href: ROUTE_PATH.PAGE_AUDIT,
        icon: { default: PageAuditIcon, active: PageAuditIconActive },
      },
      {
        title: "Page Competitive",
        code: "page-competitive",
        href: ROUTE_PATH.PAGE_COMPETITIVE,
        icon: {
          default: PageCompetitiveIcon,
          active: PageCompetitiveIconActive,
        },
      },
    ],
  };

  const FREE_TOOLS: INavItem = {
    title: "Free Tools",
    code: "Free Tools",
    children: [
      {
        title: "Optimize Header",
        code: "optimize-header",
        href: ROUTE_PATH.OPTIMIZE_HEADER,
        icon: { default: OptimizeHeaderIcon, active: OptimizeHeaderIconActive },
      },
      {
        title: "Keyword Density",
        code: "keyword-density",
        href: ROUTE_PATH.KEYWORD_DENSITY,
        icon: { default: KeywordDensityIcon, active: KeywordDensityIconActive },
      },
      {
        title: "Potential Keyword",
        code: "potential-keyword",
        href: ROUTE_PATH.POTENTIAL_KEYWORD,
        icon: {
          default: PotentialKeywordIcon,
          active: PotentialKeywordIconActive,
        },
      },
      {
        title: "Keyword Group",
        code: "keyword-group",
        href: ROUTE_PATH.KEYWORD_GROUP,
        icon: { default: KeywordGroupIcon, active: KeywordGroupIconActive },
      },
      {
        title: "Compress Photo",
        code: "compress-photo",
        href: ROUTE_PATH.COMPRESS_PHOTO,
        icon: { default: CompressPhotoIcon, active: CompressPhotoIconActive },
      },
      {
        title: "Outline",
        code: "outline",
        href: ROUTE_PATH.OUTLINE,
        icon: { default: OutlineIcon, active: OutlineIconActive },
      },
    ],
  };

  const MATERIAL: INavItem = {
    href: ROUTE_PATH.MATERIAL,
    title: "Material",
    icon: { default: MyProjectIcon, active: MyProjectIconActive },
    code: "Material",
  };

  let navs: INavItem[] = [OTHERTOOLS, MYPROJECT, OVERVIEW, FREE_TOOLS];

  if (process.env.NODE_ENV === "development") {
    navs.push(MATERIAL);
  }

  return navs;
};

export const notInSidebarItems = (role?: Roles): INavItem[] => {
  const OUTLINES: INavItem = {
    title: "Outline",
    code: "Outline",
    children: [
      {
        title: "Posts",
        code: "Posts",
        href: ROUTE_PATH.OUTLINE_POSTS,
        icon: { default: OutlineIcon, active: OutlineIconActive },
      },
      {
        title: "Post",
        code: "Post",
        href: ROUTE_PATH.OUTLINE_POST_DETAIL,
        icon: { default: OutlineIcon, active: OutlineIconActive },
      },
    ],
  };

  let navs: INavItem[] = [OUTLINES];

  return navs;
};

const appRoutes = (role?: Roles): RouteObject[] => {
  let routes: RouteObject[] = [
    {
      path: ROUTE_PATH.INDEX,
      element: <DashboardLayout />,
      children: [
        { path: "*", element: <Navigate to={ROUTE_PATH.NOTFOUND} /> },
        {
          path: ROUTE_PATH.MY_PROJECT,
          element: <Index />,
        },
        {
          path: ROUTE_PATH.MY_PROJECT_DETAIL,
          element: <MyProjectReport />,
        },
        {
          path: ROUTE_PATH.DOMAIN_AUDIT,
          element: <DomainAudit />,
        },
        {
          path: ROUTE_PATH.DOMAIN_AUDIT_ERROR_DETAIL,
          element: <DomainAuditError />,
        },
        {
          path: ROUTE_PATH.DOMAIN_COMPETITIVE,
          element: <Index />,
        },
        {
          path: ROUTE_PATH.PAGE_AUDIT,
          element: <AuditPage />,
        },
        {
          path: ROUTE_PATH.PAGE_COMPETITIVE,
          element: <Index />,
        },
        {
          path: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
          element: <DomainAuditDetail />,
        },
        {
          path: ROUTE_PATH.DOMAIN_COMPETITIVE_DETAIL,
          element: <Index />,
        },
        {
          path: ROUTE_PATH.PAGE_AUDIT_DETAIL,
          element: <AuditPage />,
        },
        {
          path: ROUTE_PATH.PAGE_COMPETITIVE_DETAIL,
          element: <Index />,
        },
        {
          path: ROUTE_PATH.OPTIMIZE_HEADER,
          element: <Index />,
        },
        {
          path: ROUTE_PATH.KEYWORD_DENSITY,
          element: <KeywordDensity />,
        },
        {
          path: ROUTE_PATH.POTENTIAL_KEYWORD,
          element: <PotentialKeyword />,
        },
        {
          path: ROUTE_PATH.KEYWORD_GROUP,
          element: <KeywordGroup />,
        },
        {
          path: ROUTE_PATH.COMPRESS_PHOTO,
          element: <CompressPhoto />,
        },
        {
          path: ROUTE_PATH.OUTLINE,
          element: <Outline />,
        },
        {
          path: `${ROUTE_PATH.OUTLINE_POSTS}`,
          element: <Posts />,
        },
        {
          path: `${ROUTE_PATH.OUTLINE_POST_DETAIL}`,
          element: <Post />,
        },
        {
          path: `${ROUTE_PATH.DOMAIN_OVERVIEW}`,
          element: <DomainOverview />,
        },
        {
          path: `${ROUTE_PATH.DOMAIN_OVERVIEW_CHECK}`,
          element: <DomainOverviewCheck />,
        },
        {
          path: `${ROUTE_PATH.BACKLINK}`,
          element: <Backlink />,
        },
        {
          path: `${ROUTE_PATH.BACKLINK_CHECK}`,
          element: <BackLinkCheck />,
        },
        {
          path: `${ROUTE_PATH.ORGANIC_KEYWORD}`,
          element: <OrganicKeyword />,
        },
        {
          path: `${ROUTE_PATH.ORGANIC_KEYWORD_CHECK}`,
          element: <OrganicKeywordCheck />,
        },
        {
          path: ROUTE_PATH.MATERIAL,
          element: <Material />,
        },
      ],
    },
    {
      path: ROUTE_PATH.INDEX,
      element: <UnAuthLayout />,
      children: [
        {
          path: ROUTE_PATH.LOGIN,
          element: <Login />,
        },
        { path: "*", element: <Navigate to={ROUTE_PATH.LOGIN} /> },
      ],
    },
    {
      path: ROUTE_PATH.NOTFOUND,
      element: <NotFound />,
    },
  ];

  return routes;
};

export default appRoutes;
