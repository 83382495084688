import { Box, Typography } from "@mui/material";
import AuditAccordion from "../AuditAccordion";
import {
  AuditRef,
  Audits,
  StackPacks,
} from "../../../../../../../../../models/google/google.page.speed";
import {
  getAuditStatus,
  getLevelByStatus,
} from "../../../../../../../../../common/handles/common.handles";
import { EAuditErrorStatus } from "../../../../../../../../../models/common/models.enum";

interface IProps {
  groupTitle: string;
  data: AuditRef[];
  audits: Audits;
  stackPacks: StackPacks[];
  isDiagnostics?: boolean;
  filterTitle?: string;
}

export default function GroupAudit({
  groupTitle,
  data,
  audits,
  stackPacks,
  isDiagnostics,
  filterTitle,
}: IProps) {
  const sortData = data
    .filter(sort => {
      const audit = audits ? audits[sort.id] : null;
      if (!!audit) {
        const status = getAuditStatus(audit, sort.weight);
        if (
          status === EAuditErrorStatus.Warning ||
          status === EAuditErrorStatus.Error
        ) {
          return sort;
        }
      }
    })
    .sort((a, b) => {
      if (audits) {
        const auditA = audits[a.id];
        const auditB = audits[b.id];
        const statusA = getLevelByStatus(getAuditStatus(auditA, a.weight));
        const statusB = getLevelByStatus(getAuditStatus(auditB, b.weight));

        return statusB - statusA;
      }
      return b.weight - a.weight;
    });

  return (
    <Box className="group-audit-wrapper">
      {sortData.length > 0 && (
        <>
          <Box className="group-audit-title flex-between-align-center">
            <Typography variant="subtitle2">{groupTitle}</Typography>
            {/* {isDiagnostics && (
          <Box className="flex-between-align-center">
            <Typography variant="caption">{filterTitle}</Typography>

            <Box sx={{ ml: 1 }}>
              <Button color="primary">All</Button>
              <Button sx={{ ml: 1 }} color="inherit">
                FCB
              </Button>
              <Button sx={{ ml: 1 }} color="inherit">
                LCP
              </Button>
              <Button sx={{ ml: 1 }} color="inherit">
                TBT
              </Button>
              <Button sx={{ ml: 1 }} color="inherit">
                CLS
              </Button>
            </Box>
          </Box>
        )}{" "} */}
          </Box>
          <div className="audit-accordion-list">
            {sortData.map(item => {
              const audit = audits ? audits[item.id] : null;
              return audit ? (
                <AuditAccordion
                  data={audit}
                  key={item.id}
                  stackPacks={stackPacks}
                  weight={item.weight}
                />
              ) : null;
            })}
          </div>
        </>
      )}
    </Box>
  );
}
