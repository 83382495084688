import React from "react";
import { FormCheckLink } from "../../../../components";
import { CommonLayoutWrapper } from "./styles";

interface CommonLayoutProps {
  title: string;
  description: string;
  formTitle: string;
  formDescription: string;
  link: string;
}

export default function CommonLayout(props: CommonLayoutProps) {
  const { title, description, formTitle, formDescription, link } = props;

  return (
    <CommonLayoutWrapper>
      <div className="layout-header">
        <h1 className="layout-title">{title}</h1>
        <p className="layout-description">{description}</p>
      </div>
      <FormCheckLink
        title={formTitle}
        description={formDescription}
        link={link}
      />
    </CommonLayoutWrapper>
  );
}
