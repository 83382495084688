import { Button, Tooltip, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IValue } from "../../../../models/common/models.type";
import SelectCustom from "../../../../components/SelectCustom";
import { CheckToolTitlePopoverWrapper, CheckToolTitleWrapper } from "./styles";
import { ETypeLinkCheck } from "../../../../models/common/models.enum";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import InputForm from "../../../../components/controls/InputForm";
import IconSearch from "../../../../assets/images/common/icon_search_line_grey_large.svg";
import CommonHandle from "../../../../common/handles/common.handles";

interface CheckToolTitleProps {
  title: string;
  domain: string;
  placeholder?: string;
  className?: string;
  onSubmit: (value: { target: string; type: ETypeLinkCheck }) => void;
  value: { target: string | null; type: ETypeLinkCheck | null };
}

export default function CheckToolTitle(props: CheckToolTitleProps) {
  const {
    title,
    domain,
    placeholder = "Tìm kiếm",
    className = "",
    onSubmit,
    value,
  } = props;

  const matches1195 = useMediaQuery("(max-width:1195px)");

  const [openModal, setOpenModal] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (!!value.target && value.target.length > 0 && !!value.type) {
      setValue("target", value.target);
      setValue("type", value.type);
    }
  }, [value]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const rexgexPage =
    /^(https?:\/\/)?(([a-zd]([a-zd-]*[a-zd])*).)+[a-z]{2,}?(\/[-a-zd%_.~+]+)/;

  const validationSchema = Yup.object().shape({
    target: Yup.string()
      .matches(rexgexPage, "Is not in correct format")
      .required(`${"Please enter your domain"}`),
  }) as any;

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<{
    target: string;
    type: ETypeLinkCheck;
  }>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      target: "",
      type: ETypeLinkCheck.RootDomain,
    },
    resolver: yupResolver(validationSchema),
  });

  return (
    <>
      <CheckToolTitlePopoverWrapper
        id={"search-modal"}
        open={!!openModal}
        anchorEl={openModal}
        onClose={() => setOpenModal(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <p className="popover-title">Tìm kiếm</p>
        <div className="popover-input-wrapper">
          <InputForm
            placeholder={placeholder}
            required
            name={"target"}
            errors={errors}
            control={control}
            size="small"
            startIcon={IconSearch}
            classname="action-input-target"
          />
          <Controller
            name={"type"}
            render={({ field }) => (
              <SelectCustom
                options={[
                  {
                    title: "Root domain",
                    value: ETypeLinkCheck.RootDomain,
                  },
                  { title: "Exact url", value: ETypeLinkCheck.ExactUrl },
                ]}
                onChange={event => {
                  field.onChange(event.target.value);
                }}
                placeholder="Chọn loại"
                isSelectedIcon
                value={field.value}
                className="action-select-type"
              />
            )}
            control={control}
          />
        </div>
        <Button
          aria-describedby={"number-range-popover-action-submit"}
          variant="contained"
          onClick={_ => {
            handleSubmit(onSubmit)();
            setOpenModal(null);
          }}
          size="small"
          className="submit-button"
        >
          Tìm kiếm
        </Button>
      </CheckToolTitlePopoverWrapper>
      <CheckToolTitleWrapper className={`${className} flex-between-align-top`}>
        <div className="title-wrapper flex-start-align-center">
          <h1 className="title">{title}</h1>
          <Tooltip title={domain} placement="top-start">
            <div>
              <p className="domain">{CommonHandle.truncateText(domain, 36)}</p>
            </div>
          </Tooltip>
        </div>
        <div className="action-wrapper flex-end-align-start">
          {!matches1195 && (
            <>
              <InputForm
                placeholder={placeholder}
                required
                name={"target"}
                errors={errors}
                control={control}
                size="small"
                startIcon={IconSearch}
                classname="action-input-target"
              />
              <Controller
                name={"type"}
                render={({ field }) => (
                  <SelectCustom
                    options={[
                      {
                        title: "Root domain",
                        value: ETypeLinkCheck.RootDomain,
                      },
                      { title: "Exact url", value: ETypeLinkCheck.ExactUrl },
                    ]}
                    onChange={event => {
                      field.onChange(event.target.value);
                    }}
                    placeholder="Chọn loại"
                    isSelectedIcon
                    value={field.value}
                    className="action-select-type"
                  />
                )}
                control={control}
              />
            </>
          )}
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={e => {
              if (matches1195) {
                setOpenModal(e.currentTarget);
              } else {
                handleSubmit(onSubmit)();
              }
            }}
            className="search-button"
          >
            <p>Tìm kiếm</p>
          </Button>
        </div>
      </CheckToolTitleWrapper>
    </>
  );
}
