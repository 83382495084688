import { DomainOverviewWrapper } from "./styles";
import CommonLayout from "../components/CommonLayout";
import { ROUTE_PATH } from "../../../common/constants/app.constant";

export default function DomainOverview() {
  return (
    <DomainOverviewWrapper>
      <CommonLayout
        title="Domain overview"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  eiusmo"
        formTitle="Domain overview"
        formDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        link={ROUTE_PATH.DOMAIN_OVERVIEW_CHECK}
      />
    </DomainOverviewWrapper>
  );
}
