import { RangeKeyDict, Range } from "react-date-range";
import { vi } from "date-fns/locale";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePickerBox, DateRangePickerWrapper } from "./styles";
import { useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import variableStyles from "../../../theme/variable-styles";
import { ImageCustom } from "../..";
import IconArrowFull from "../../../assets/images/sidebar/icon_arrow_right_full_line_grey.svg";
import { Button } from "@mui/material";

export interface IRangeDate extends Range {}

interface IProps {
  selection: IRangeDate | null;
  maxDate?: string | number;
  minDate?: string | number;
  onSubmit: (value: IRangeDate) => void;
  onClose: () => void;
}

const DateRangePickerCustom = (props: IProps) => {
  const {
    selection,
    maxDate = Date.now(),
    minDate = "01/01/1900",
    onSubmit,
    onClose
  } = props;
  const [range, setRange] = useState<
    {
      startDate?: Date;
      endDate?: Date;
      key?: string;
    }[]
  >([
    {
      startDate: undefined,
      endDate: undefined,
      key: "selection"
    }
  ]);
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);

  useEffect(() => {
    if (!!selection?.startDate && !!selection?.endDate) {
      setRange([selection]);
      setStartDate(moment(selection.startDate) || null);
      setEndDate(moment(selection.endDate) || null);
    }
  }, [selection]);

  function handleSelect(value: RangeKeyDict) {
    setRange([{ ...value.selection }]);
    setStartDate(moment(value.selection.startDate));
    setEndDate(moment(value.selection.endDate));
  }

  return (
    <DateRangePickerWrapper>
      <div className="input-date-wrapper">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            disableOpenPicker
            onChange={(v, _) => {
              if (!!v && !isNaN(moment(v).toDate().getTime())) {
                if (
                  !moment(v).isBefore(moment(minDate)) &&
                  (!!endDate
                    ? !moment(v).isAfter(moment(endDate))
                    : !moment(v).isAfter(moment(maxDate)))
                ) {
                  setRange([
                    {
                      startDate: moment(v).toDate(),
                      endDate: range[0].endDate,
                      key: "selection"
                    }
                  ]);
                  setStartDate(moment(v));
                }
              }
            }}
            value={startDate}
            maxDate={!!endDate ? moment(endDate) : moment(maxDate)}
            minDate={moment(minDate)}
            className="date-picker"
            onError={(v, _) => {}}
          />
        </LocalizationProvider>
        <figure className="input-arrow">
          <ImageCustom
            src={IconArrowFull}
            alt="Icon-Arrow-Full"
            width={"12px"}
            height={"12px"}
          />
        </figure>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            disableOpenPicker
            onChange={(v, _) => {
              if (!!v && !isNaN(moment(v).toDate().getTime())) {
                if (
                  !moment(v).isAfter(moment(maxDate)) &&
                  (!!startDate
                    ? !moment(v).isBefore(moment(startDate))
                    : !moment(v).isBefore(moment(minDate)))
                ) {
                  setRange([
                    {
                      startDate: range[0].startDate,
                      endDate: moment(v).toDate(),
                      key: "selection"
                    }
                  ]);
                  setEndDate(moment(v));
                }
              }
            }}
            value={endDate}
            maxDate={moment(maxDate)}
            minDate={!!startDate ? moment(startDate) : moment(minDate)}
            className="date-picker"
            onError={(v, _) => {
              console.log(v);
            }}
          />
        </LocalizationProvider>
      </div>
      <DateRangePickerBox
        locale={vi}
        ranges={range}
        showDateDisplay={false}
        onChange={handleSelect}
        maxDate={new Date(maxDate)}
        minDate={new Date(minDate)}
        showMonthAndYearPickers={false}
        startDatePlaceholder="dd/mm/yyyy"
        endDatePlaceholder="dd/mm/yyyy"
        dateDisplayFormat="dd MMM yyyy"
        rangeColors={[variableStyles.GreenPrimaryColor500]}
      />
      <div className="picker-action">
        <button
          onClick={() => {
            setRange([
              {
                startDate: undefined,
                endDate: undefined,
                key: "selection"
              }
            ]);
            setStartDate(null);
            setEndDate(null);
          }}
          className="clear-date-button"
        >
          Xoá bộ lọc
        </button>
        <Button
          size="xsmall"
          variant="contained"
          className="button-submit"
          onClick={() => onSubmit && onSubmit(range[0])}
        >
          Áp dụng
        </Button>
      </div>
    </DateRangePickerWrapper>
  );
};

export default DateRangePickerCustom;
