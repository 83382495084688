import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const ReportStatusTagsWrapper = styled("div")(() => ({
  fontWeight: variableStyles.fwMedium,
  fontSize: "14px",
  lineHeight: "20px",
  position: "relative",
  paddingLeft: "14px",
  "&::before": {
    content: '""',
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    position: "absolute",
    top: "50%",
    left: "0",
    transform: "translateY(-50%)"
  },
  "&.blue": {
    color: variableStyles.blueSecondary700,
    "&::before": {
      backgroundColor: variableStyles.blueSecondary700
    }
  },
  "&.orange": {
    color: variableStyles.Warning700,
    "&::before": {
      backgroundColor: variableStyles.Warning700
    }
  },
  "&.green": {
    color: variableStyles.Success600,
    "&::before": {
      backgroundColor: variableStyles.Success600
    }
  },
  "&.grey": {
    color: variableStyles.NaturalColor500,
    "&::before": {
      backgroundColor: variableStyles.NaturalColor500
    }
  }
}));
