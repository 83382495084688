import React, { forwardRef, useEffect, useState } from "react";
import OverviewCommonCaption from "../../OverviewCommonCaption";
import OverviewCommonSubTitle from "../../OverviewCommonSubTitle";
import OverviewCommonTitle from "../../OverviewCommonTitle";
import { PerformSectionWrapper } from "./styles";
import IconChart from "../../../../../assets/images/common/icon_chart_line_green.svg";
import { Divider } from "@mui/material";
import {
  AreaChartCustom,
  BarChartCustom,
  ImageCustom,
  LineChartCustom,
  Modal,
  ProgressBar,
  TableNavDetailNoPagination,
} from "../../../../../components";
import {
  IBasePagingRes,
  IChartValue,
} from "../../../../../models/common/models.type";
import { ITableNavDetailNoPaginationHeader } from "../../../../../components/Table/TableNavDetailNoPagination";
import { DEFAULT_BASE_PAGING_RES } from "../../../../../common/constants/value.constant";
import AuditReportService from "../../../../../services/api/audit.report.service";
import {
  IGetDomainOrganicKeywordRes,
  IGetDomainOrganicSearchRes,
  IGetDomainOverviewRes,
} from "../../../../../models/audit-report";
import CommonHandle from "../../../../../common/handles/common.handles";
import {
  EIntentsPositions,
  ETypeLinkCheck,
} from "../../../../../models/common/models.enum";
import ImageNoData from "../../../../../assets/images/common/img_project_empty.png";
import { generatePath } from "react-router-dom";
import { useSelector } from "react-redux";
import { IStateApp } from "../../../../../models/redux";
import { IGetReportsRes } from "../../../../../models/report";
import { ROUTE_PATH } from "../../../../../common/constants/app.constant";
import queryString from "query-string";

const dataBarChart = [
  {
    name: "Page A",
    uv: 40,
    pv: 24,
    day: "1-01-2023",
  },
  {
    name: "Page B",
    uv: 30,
    pv: 13,
    day: "05-02-2023",
  },
  {
    name: "Page C",
    uv: 20,
    pv: 98,
    day: "05-03-2023",
  },
  {
    name: "Page D",
    uv: 27,
    pv: 39,
    day: "05-04-2024",
  },
  {
    name: "Page E",
    uv: 18,
    pv: 48,
    day: "05-05-2024",
  },
  {
    name: "Page F",
    uv: 23,
    pv: 38,
    day: "05-06-2024",
  },
  {
    name: "Page G",
    uv: 32,
    pv: 43,
    day: "05-07-2024",
  },
];

const PerformSection = forwardRef<any>(function PerformSection(props, ref) {
  const dataReport = useSelector<IStateApp, IGetReportsRes>(
    appState => appState.project?.dataReport
  );

  const [dataRes, setDataRes] = useState<IGetDomainOverviewRes | null>();
  const [dataResKeyword, setDataResKeyword] =
    useState<IGetDomainOrganicKeywordRes[]>();
  const [dataResSearch, setDataResSearch] =
    useState<IGetDomainOrganicSearchRes[]>();
  const [dataResKeywordPaging, setDataResKeywordPaging] =
    useState<IBasePagingRes<any>>();
  const [dataResSearchPaging, setDataResSearchPaging] =
    useState<IBasePagingRes<any>>();
  const [accessTrafficFilter, setAccessTrafficFilter] = useState<string>("24");
  const [keywordFilter, setKeywordFilter] = useState<string>("24");
  const [keywordChartLine, setKeywordChartLine] = useState<string[]>([
    "x0",
    "x1",
    "x2",
    "x3",
    "x4",
  ]);
  const [openModal, setOpenModal] = useState<"keyword" | "search" | null>(null);

  useEffect(() => {
    getDataofPage();
  }, []);

  const headersTable: ITableNavDetailNoPaginationHeader<any>[] = [
    {
      field: "keyword",
      title: `Từ khoá`,
      width: "120px",
      isSticky: true,
    },
    {
      field: "intents",
      title: `Intent`,
      width: "80px",
      className: "table-cell-intents-tag",
      handleItem: (item: IGetDomainOrganicKeywordRes) => {
        const arrayIntents = item.intents?.split(",");
        return (
          <div className="flex-start-align-center intents-tags">
            {arrayIntents.map(array => {
              const character = renderIntents(array);
              return (
                <p className={`${character} intents-tag`} key={array}>
                  {character}
                </p>
              );
            })}
          </div>
        );
      },
    },
    {
      field: "position",
      title: `Vị trí`,
      width: "70px",
    },
    {
      field: "searchVolume",
      title: `Volume`,
      width: "80px",
    },
    {
      field: "keywordDifficulty",
      title: `KD`,
      width: "50px",
    },
    {
      field: "cpc",
      title: `CPC`,
      width: "50px",
    },
    {
      field: "traffic",
      title: `Traffic`,
      width: "80px",
    },
    {
      field: "url",
      title: `URL`,
      width: "180px",
      className: "table-cell-link",
      isLink: true,
    },
  ];
  const headersOrganicTable: ITableNavDetailNoPaginationHeader<any>[] = [
    {
      field: "domain",
      title: `Domain`,
      width: "110px",
      isSticky: true,
      className: "table-cell-link",
      isLink: true,
    },
    {
      field: "competitorRelevance",
      title: `Competitor Relevence`,
      width: "140px",
      className: "table-cell-progress",
      handleItem: (item: any) => {
        return (
          <div>
            <p className="progress-percent">{item.competitorRelevance || 0}%</p>
            <ProgressBar
              percent={item.competitorRelevance || 0}
              className="progress-bar"
              type={"normal"}
              height={"4px"}
            />
          </div>
        );
      },
    },
    {
      field: "organicTraffic",
      title: `Organic Traffic`,
      width: "100px",
    },
    {
      field: "organicKeywords",
      title: `Organic keywords`,
      width: "120px",
    },
    {
      field: "commonKeywords",
      title: `Common keywords`,
      width: "140px",
    },
  ];

  const getDataofPage = async () => {
    await Promise.all([
      await AuditReportService.getDomainOverviewPerform({
        type: "domain_rank_history",
        domain: "string",
        displayDaily: 1,
      }),
      await AuditReportService.getDomainOrganicKeyword({
        type: "",
        domain: "",
        isOrderByAsc: true,
        pageSize: 30,
      }),
      await AuditReportService.getDomainOrganicSearch({
        type: "",
        domain: "",
        isOrderByAsc: true,
        pageSize: 30,
      }),
    ]).then(([resOverview, resKeyword, resSearch]) => {
      if (!resOverview.isError) {
        setDataRes(resOverview.data);
      }
      if (!resKeyword.isError) {
        setDataResKeyword(resKeyword.data);
      }
      if (!resSearch.isError) {
        setDataResSearch(resSearch.data);
      }
    });
  };

  const dataAccessTraffic =
    accessTrafficFilter === "1"
      ? dataRes?.dateItems
      : dataRes?.monthItems?.find(x => x.month === accessTrafficFilter)?.items;

  const dataKeyword =
    keywordFilter === "1"
      ? dataRes?.dateItems
      : dataRes?.monthItems?.find(x => x.month === keywordFilter)?.items;

  const renderTotalKeyword = (value: IChartValue) => {
    let result: number = 0;
    for (let k in value) {
      if (value.hasOwnProperty(k) && k.startsWith("x")) {
        result += Number(value[k]);
      }
    }
    return result;
  };

  const handleChangePage = (
    data: IGetDomainOrganicKeywordRes[] | IGetDomainOrganicSearchRes[],
    page: number,
    type: "keyword" | "search"
  ) => {
    const newData = {
      page: page,
      pageSize: 10,
      total: data.length,
      items: [...data]?.slice(page * 10 - 10, page * 10),
      isFull: true,
    };
    if (type === "keyword") {
      setDataResKeywordPaging(newData);
      if (openModal !== "keyword") {
        setOpenModal("keyword");
      }
    } else {
      setDataResSearchPaging(newData);
      if (openModal !== "search") {
        setOpenModal("search");
      }
    }
  };

  const renderIntents = (value: string) => {
    switch (value) {
      case EIntentsPositions.Commercial:
        return "C";
      case EIntentsPositions.Informational:
        return "I";
      case EIntentsPositions.Navigational:
        return "N";
      default:
        return "T";
    }
  };

  return (
    <>
      <Modal
        title={
          openModal === "keyword"
            ? "Từ khoá hàng đầu"
            : "Comprtitors in Organic Search (1-10)"
        }
        open={!!openModal}
        width={"744px"}
        onClose={() => setOpenModal(null)}
        textSubmit="Xem tất cả"
        onSubmit={() => {
          const path = generatePath(ROUTE_PATH.ORGANIC_KEYWORD_CHECK);
          let newParams = {};
          if (openModal === "keyword") {
            newParams = {
              tab: "organickeywords",
              target: `https://${dataReport.target}`,
              type: ETypeLinkCheck.RootDomain,
            };
          } else {
            newParams = {
              tab: "organiccompetitors",
              target: `https://${dataReport.target}`,
              type: ETypeLinkCheck.RootDomain,
            };
          }
          const paramSearch = queryString.stringify(newParams);

          const newWindow = window.open(
            `${path}?${paramSearch?.toLocaleLowerCase()}`,
            "_blank",
            "noopener,noreferrer"
          );
          if (newWindow) newWindow.opener = null;
        }}
        maxheight="calc(100vh - 88px)"
      >
        {openModal === "keyword" ? (
          <>
            {!!dataResKeywordPaging && !!dataResKeyword && (
              <TableNavDetailNoPagination
                headersTable={headersTable}
                data={dataResKeywordPaging}
                handleViewMore={() => {}}
                isFullTable
                handlePageChange={page =>
                  handleChangePage(dataResKeyword, page, "keyword")
                }
                maxHeight={"calc(100vh - 280px)"}
              />
            )}
          </>
        ) : (
          <>
            {!!dataResSearchPaging && !!dataResSearch && (
              <TableNavDetailNoPagination
                headersTable={headersOrganicTable}
                data={dataResSearchPaging}
                handleViewMore={() => {}}
                isFullTable
                handlePageChange={page =>
                  handleChangePage(dataResSearch, page, "search")
                }
                maxHeight={"calc(100vh - 280px)"}
              />
            )}
          </>
        )}
      </Modal>
      <PerformSectionWrapper
        className="common-layout"
        ref={ref}
        id={"perform-section"}
      >
        <OverviewCommonTitle
          title="Hiệu suất hoạt động"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua d tempor incididunt ut labore et dolore magna aliqua"
          className="perform-title"
        />
        <div className="organic-traffic-wrapper">
          <OverviewCommonSubTitle
            title="Lượng truy cập tự nhiên"
            description="Lượt truy cập website thông qua kết quả tìm kiếm tự nhiên và không bao gồm lượt truy cập từ những hình thức quảng cáo trả phí."
          />
          <div className="organic-traffic-index-wrapper">
            <div className="organic-traffic-index-box">
              <OverviewCommonCaption
                className="index-box-caption"
                width="420px"
                caption="Lượng truy cập"
                icon={IconChart}
                isSummary
                description={`${
                  !!dataAccessTraffic
                    ? CommonHandle.nFormatter(
                        Number(dataRes?.dateItems[0].organicTraffic),
                        1
                      )
                    : "0"
                }/tháng`}
              />
              <Divider />
              <div className="line-chart-wrapper">
                {!!dataRes?.monthItems && dataAccessTraffic ? (
                  <LineChartCustom
                    data={dataAccessTraffic}
                    listFilter={["1", "3", "6", "12", "24"]}
                    onChangeFilter={(v: string) => {
                      setAccessTrafficFilter(v);
                    }}
                    filterValue={accessTrafficFilter}
                    filterText="tháng"
                    XAxisKey="date"
                    YAxisKey="organicTraffic"
                    toolTipText="Lưu lượng"
                  />
                ) : (
                  <div className={"box-empty flex-column-center"}>
                    <ImageCustom
                      src={ImageNoData}
                      alt="Icon-NoData"
                      width={"120px"}
                      height={"120px"}
                    />
                    <p className={"text-empty"}>No data</p>
                  </div>
                )}
              </div>
            </div>
            {/* <div className="organic-traffic-index-box">
              <OverviewCommonCaption
                className="index-box-caption"
                width="420px"
                caption="Tỷ trọng lượng truy cập"
                tooltip="Tỷ trọng lượng truy cập"
                icon={IconChart}
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
              />
              <Divider />
              <div className="bar-chart-wrapper">
                <BarChartCustom
                  data={dataBarChart}
                  listKeyField={["pv", "uv"]}
                  XAxisKey="day"
                  YAxisType="number"
                  unit="%"
                  isDateXAxisKey
                />
              </div>
            </div> */}
          </div>
        </div>
        <div className="keyword-ranking-wrapper">
          <OverviewCommonSubTitle
            title="Từ khoá xếp hạng tốt nhất"
            description="Từ khóa xếp hạng tốt nhất bao gồm các từ khóa lên top và có khả năng mang lại lượng truy cập cho website của bạn. Ở đây chúng tôi thống kê danh sách các từ khóa mà website của bạn được xếp hạng trong 100 kết quả đầu tiên khi người dùng tìm kiếm từ khóa ấy trên Google."
          />
          <div className="keyword-chart-box">
            <div className="box-header">
              <OverviewCommonCaption
                className="index-box-caption"
                width="420px"
                caption="Từ khóa xếp hạng 1 - 100"
                icon={IconChart}
                description={`${
                  !!dataKeyword && dataRes?.dateItems[0]
                    ? CommonHandle.nFormatter(
                        renderTotalKeyword(dataRes?.dateItems[0]),
                        1
                      )
                    : "0"
                }`}
                isSummary
              />
            </div>
            <div className="box-chart">
              {!!dataKeyword ? (
                <AreaChartCustom
                  data={dataKeyword}
                  listKeyField={[
                    { title: "Top 3", value: "x0" },
                    { title: "Top 4-10", value: "x1" },
                    { title: "Top 11-20", value: "x2" },
                    { title: "Top 21-50", value: "x3" },
                    { title: "Top 51-100", value: "x4" },
                  ]}
                  XAxisKey={"date"}
                  onChangeFilter={(v: string) => {
                    setKeywordFilter(v);
                  }}
                  filterValue={keywordFilter}
                  filterText="tháng"
                  listFilter={["1", "3", "6", "12", "24"]}
                  onChangeLine={(v: string[]) => {
                    setKeywordChartLine(v);
                  }}
                  chartLine={keywordChartLine}
                />
              ) : (
                <div className={"box-empty flex-column-center"}>
                  <ImageCustom
                    src={ImageNoData}
                    alt="Icon-NoData"
                    width={"120px"}
                    height={"120px"}
                  />
                  <p className={"text-empty"}>No data</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="keyword-data-table-wrapper">
          {!!dataResKeyword && (
            <TableNavDetailNoPagination
              headersTable={headersTable}
              data={{
                ...DEFAULT_BASE_PAGING_RES,
                items: dataResKeyword.slice(0, 10),
              }}
              titleText="Từ khoá hàng đầu"
              handleViewMore={() => {
                handleChangePage(dataResKeyword, 1, "keyword");
              }}
            />
          )}
          {!!dataResSearch && (
            <TableNavDetailNoPagination
              headersTable={headersOrganicTable}
              data={{
                ...DEFAULT_BASE_PAGING_RES,
                items: dataResSearch.slice(0, 10),
              }}
              titleText="Comprtitors in Organic Search (1-10)"
              handleViewMore={() => {
                const path = generatePath(ROUTE_PATH.ORGANIC_KEYWORD_CHECK);
                const newParams = {
                  tab: "organiccompetitors",
                  target: `https://${dataReport.target}`,
                  type: ETypeLinkCheck.RootDomain,
                };
                const paramSearch = queryString.stringify(newParams);

                const newWindow = window.open(
                  `${path}?${paramSearch?.toLocaleLowerCase()}`,
                  "_blank",
                  "noopener,noreferrer"
                );
                if (newWindow) newWindow.opener = null;
              }}
            />
          )}
        </div>
      </PerformSectionWrapper>
    </>
  );
});
export default PerformSection;
