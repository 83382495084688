import queryString from "query-string";
import { ROUTE_PATH } from "../../../../../../../../common/constants/app.constant";
import { DEFAULT_BASE_PAGING_RES } from "../../../../../../../../common/constants/value.constant";
import CommonHandle from "../../../../../../../../common/handles/common.handles";
import { ITableHeader } from "../../../../../../../../components/CollapseHandleError";
import {
  IBasePagingReq,
  IBasePagingRes,
} from "../../../../../../../../models/common/models.type";
import { toggleErrorTableModal } from "../../../../../ModalErrorTable";

export interface IIssueCommon {
  title: string;
  key: string;
  data?: any[];
  filter?: {
    key: string;
    value: string;
    operator: string;
    type: string;
  }[];
  type?: string;
  isNavigateToLink?: boolean;
  infor?: string;
  recommendAction?: string;
  headerData?: ITableHeader<any>[];
  navigatePath?: string;
  navigateFilter?: string;
}

const dataIssueError: IIssueCommon[] = [
  {
    title: "Trang thiếu thẻ tiêu đề",
    key: "no_title",
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "checks.no_title",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "title",
      sFilter: "no_title",
    }),
  },
  {
    title: "Trang trùng lặp thẻ tiêu đề",
    key: "duplicate_title",
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "duplicate_title",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "title",
        title: `Tiêu đề`,
        className: " ",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.meta.title}</p>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "title",
      sFilter: "duplicate_title",
    }),
  },
  {
    title: "Trang có nhiều hơn 1 thẻ tiêu đề",
    key: "duplicate_title_tag",
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "checks.duplicate_title_tag",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "h1",
        title: `Số lượng tiêu đề`,
        className: " ",
        handleItem: (item: any) => {
          return (
            <p className="fit-one-line">{item.meta?.htags?.h1?.length || 0}</p>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "title",
      sFilter: "duplicate_title_tag",
    }),
  },
  {
    title: "Trang thiếu thẻ Heading 1",
    key: "no_h1_tags",
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "checks.no_h1_tags",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "h1",
      sFilter: "no_h1_tags",
    }),
  },
  {
    title: "Trang trùng lặp thẻ Meta description",
    key: "duplicate_description",
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "duplicate_description",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],

    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "description",
        title: `Meta Description`,
        className: "table-cell-link ",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.meta.description}</p>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "metadescription",
      sFilter: "duplicatemeta",
    }),
  },
  {
    title: "Trang lỗi 4xx",
    key: "is_4xx_code",
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "broken",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "checks.is_4xx_code",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],

    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Status code`,
        className: "table-cell-error-tag ",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.status_code || "404"}</p>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "statuscode",
      sFilter: ["code404", "code401", "code403"],
    }),
  },
  {
    title: "Trang lỗi 5xx",
    key: "is_5xx_code",
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "checks.is_5xx_code",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],

    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Status code`,
        className: "table-cell-error-tag  ",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.status_code || "505"}</p>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "statuscode",
      sFilter: ["code505", "code501", "code502"],
    }),
  },
  {
    title: "Trang có liên kết nội bộ tới trang 4xx",
    key: "broken_links",
    type: "page",
    isNavigateToLink: true,
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "broken_links",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],

    headerData: [
      {
        field: "url",
        title: `From URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "url",
        title: `To URL`,
        className: "table-cell-link table-cell-error-tag",
        handleItem: (item: any) => {
          return (
            <div className="table-cell-link-error">
              <button
                className="fit-one-line"
                onClick={() => {
                  toggleErrorTableModal({
                    open: true,
                    onCancel: () => { },
                    isHasFilter: true,
                    url: item.url,
                    filter: [
                      {
                        key: "type",
                        value: "anchor",
                        operator: "=",
                        type: "field",
                      },
                      {
                        key: "",
                        value: "and",
                        operator: "",
                        type: "operator",
                      },
                      {
                        key: "type",
                        value: "link",
                        operator: "=",
                        type: "field",
                      },
                      {
                        key: "",
                        value: "and",
                        operator: "",
                        type: "operator",
                      },
                      {
                        key: "page_to_status_code",
                        value: "404",
                        operator: "=",
                        type: "field",
                      },
                    ],
                    headersTable: [
                      {
                        field: "link_from",
                        title: `From URL`,
                        width: "244px",
                        className: "table-cell-link",
                        isSticky: true,
                        handleItem: (item: any) => {
                          return (
                            <a
                              className="fit-one-line"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item.link_from}
                            >
                              {item.link_from}
                            </a>
                          );
                        },
                      },
                      {
                        field: "link_to",
                        title: `To URL`,
                        width: "244px",
                        className: "table-cell-link table-cell-error-check",
                        handleItem: (item: any) => {
                          return (
                            <div className="table-cell-link-error">
                              <a
                                className="fit-one-line"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={item.link_to}
                              >
                                {item.link_to}
                              </a>
                              <div className={`cell-status-code error`}>
                                <p>{item.page_to_status_code || "4xx"}</p>
                              </div>
                            </div>
                          );
                        },
                      },
                      {
                        field: "text",
                        title: `Anchor text`,
                        width: "120px",
                      },
                      {
                        field: "dofollow",
                        title: `Follow`,
                        width: "100px",
                        className: "table-cell-link table-cell-error-check",
                        handleItem: (item: any) => {
                          return (
                            <div
                              className={`cell-status-code ${item.dofollow ? "success" : "error"}`}
                            >
                              <p>{item.dofollow ? "True" : "False"}</p>
                            </div>
                          );
                        },
                      },
                    ],
                    activeTab: "0",
                  });
                }}
              >
                Xem danh sách liên kết
              </button>
            </div>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "statuscode",
      sFilter: ["code505", "code501", "code502"],
    }),
  },
  {
    title: "Trang thiếu thẻ canonical",
    key: "",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "canocical",
      sFilter: "missingcanonical",
    }),
  },
  {
    title: "Trang lỗi thẻ canonical",
    key: "canonical_to_broken",
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "checks.canonical_to_broken",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],

    headerData: [
      {
        field: "url",
        title: `From URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "canonical",
        title: `Canonical link`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.meta.canonical}
            >
              {item.meta.canonical}
            </a>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "canocical",
      sFilter: "errorcanonical",
    }),
  },
  {
    title: "Trang có thẻ canonical trả về mã 3xx",
    key: "canonical_to_redirect",
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "checks.canonical_to_redirect",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],

    headerData: [
      {
        field: "url",
        title: `From URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "canonical",
        title: `Canonical link`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.meta.canonical}
            >
              {item.meta.canonical}
            </a>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "canocical",
      sFilter: "3xxcanonical",
    }),
  },
  {
    title: "Trang có liên kết trỏ đến phiên bản Http",
    key: "https_to_http_links",
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "checks.https_to_http_links",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `From URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `To URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <div className="table-cell-link-error">
              <button
                className="fit-one-line"
                onClick={() => {
                  toggleErrorTableModal({
                    open: true,
                    onCancel: () => { },
                    isHasFilter: true,
                    url: item.url,
                    filter: [
                      {
                        key: "type",
                        value: "anchor",
                        operator: "=",
                        type: "field",
                      },
                      {
                        key: "",
                        value: "and",
                        operator: "",
                        type: "operator",
                      },
                      {
                        key: "type",
                        value: "link",
                        operator: "=",
                        type: "field",
                      },
                      {
                        key: "",
                        value: "and",
                        operator: "",
                        type: "operator",
                      },
                      {
                        key: "page_to_scheme",
                        value: "https",
                        operator: "=",
                        type: "field",
                      },
                    ],
                    headersTable: [
                      {
                        field: "link_from",
                        title: `From URL`,
                        width: "244px",
                        className: "table-cell-link",
                        isSticky: true,
                        handleItem: (item: any) => {
                          return (
                            <a
                              className="fit-one-line"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item.link_from}
                            >
                              {item.link_from}
                            </a>
                          );
                        },
                      },
                      {
                        field: "link_to",
                        title: `To URL`,
                        width: "244px",
                        className: "table-cell-link table-cell-error-check",
                        handleItem: (item: any) => {
                          return (
                            <div className="table-cell-link-error">
                              <a
                                className="fit-one-line"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={item.link_to}
                              >
                                {item.link_to}
                              </a>
                            </div>
                          );
                        },
                      },
                      {
                        field: "text",
                        title: `Anchor text`,
                        width: "120px",
                      },
                      {
                        field: "dofollow",
                        title: `Follow`,
                        width: "100px",
                        className: "table-cell-link table-cell-error-check",
                        handleItem: (item: any) => {
                          return (
                            <div
                              className={`cell-status-code ${item.dofollow ? "success" : "error"}`}
                            >
                              <p>{item.dofollow ? "True" : "False"}</p>
                            </div>
                          );
                        },
                      },
                    ],
                    activeTab: "0",
                  });
                }}
              >
                Xem danh sách liên kết
              </button>
            </div>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "https",
      sFilter: "redirecthttp",
    }),
  },
  {
    title: "Trang có nội dung hỗn hợp",
    key: "page_to_scheme",
    type: "links",
    filter: [
      {
        key: "type",
        value: "image",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "page_to_scheme",
        value: "hntp",
        operator: "=",
        type: "field",
      },
    ],

    headerData: [
      {
        field: "url",
        title: `From URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `To URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <div className="table-cell-link-error">
              <button
                className="fit-one-line"
                onClick={() => {
                  toggleErrorTableModal({
                    open: true,
                    onCancel: () => { },
                    isHasFilter: true,
                    url: item.url,
                    filter: [
                      {
                        key: "resource_type",
                        value: "html",
                        operator: "=",
                        type: "field",
                      },
                      {
                        key: "",
                        value: "and",
                        operator: "",

                        type: "operator",
                      },
                      {
                        key: "checks.https_to_http_links",
                        value: "true",
                        operator: "=",
                        type: "field",
                      },
                    ],
                    headersTable: [
                      {
                        field: "url",
                        title: `From URL`,
                        width: "244px",
                        className: "table-cell-link",
                        isSticky: true,
                        handleItem: (item: any) => {
                          return (
                            <a
                              className="fit-one-line"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item.url}
                            >
                              {item.url}
                            </a>
                          );
                        },
                      },
                      {
                        field: "domain",
                        title: `From URL`,
                        width: "244px",
                        className: "table-cell-link table-cell-error-check",
                        handleItem: (item: any) => {
                          return (
                            <div className="table-cell-link-error">
                              <a
                                className="fit-one-line"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={item.url}
                              >
                                {item.url}
                              </a>
                              <div
                                className={`cell-status-code ${!item.status ? "error" : "success"}`}
                              >
                                <p>{item.status || "4xx"}</p>
                              </div>
                            </div>
                          );
                        },
                      },
                      {
                        field: "domain",
                        title: `Anchor text`,
                        width: "120px",
                      },
                      {
                        field: "follow",
                        title: `Follow`,
                        width: "100px",
                        className: "table-cell-link table-cell-error-check",
                        handleItem: (item: any) => {
                          return (
                            <div
                              className={`cell-status-code ${!!item.status ? "error" : "success"}`}
                            >
                              <p>{item.status || "True"}</p>
                            </div>
                          );
                        },
                      },
                    ],
                    activeTab: "0",
                  });
                }}
              >
                Xem danh sách liên kết
              </button>
            </div>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "https",
      sFilter: "redirectmix",
    }),
  },
  {
    title: "Trang sử dụng sai thẻ Hreflang",
    key: "",

    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "hreflang",
      sFilter: "wronghref",
    }),
  },
  {
    title: "Trang sử dụng ngôn ngữ hỗn hợp",
    key: "",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "hreflang",
      sFilter: "mixhref",
    }),
  },
  {
    title: "Trang có lỗi dữ liệu có cấu trúc",
    key: "",

    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
  },
  {
    title: "Trang có tốc độ tải chậm",
    key: "time_to_interactive",
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "page_timing.time_to_interactive",
        value: "3000",
        operator: ">=",
        type: "field",
      },
    ],

    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Thời gian`,
        className: "table-cell-text-tag ",
        handleItem: (item: any) => {
          return (
            <p className="fit-one-line">
              {(item.page_timing.time_to_interactive / 1000).toFixed(2)}s
            </p>
          );
        },
      },
    ],
  },
  {
    title: "Trang có kích thước quá lớn",
    key: "size_greater_than_3mb",
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "checks.size_greater_than_3mb",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],

    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Kích thước (KB)`,
        className: "table-cell-text-tag ",
        handleItem: (item: any) => {
          return (
            <p className="fit-one-line">
              {CommonHandle.formatNumberCurrency(item.size || 0)}
            </p>
          );
        },
      },
    ],
  },
  {
    title: "Trang có chứa chuỗi chuyển hướng",
    key: "redirect_chain",
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "checks.redirect_chain",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],

    headerData: [
      {
        field: "url",
        title: `From URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Redirect URL 1`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `URL 2`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "stringredirect",
    }),
  },
  {
    title: "301 redirect phiên bản www va non www",
    key: "www_redirect_status_code",
  },
  {
    title: "Có chứng chỉ HTTPs",
    key: "valid_certificate",
  },
  {
    title: "301 redirect phiên bản Http sang https",
    key: "test_https_redirect",
  },
];

const dataIssueWaring: IIssueCommon[] = [
  {
    title: "Trang có tiêu đề quá dài",
    key: "title_too_long",
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "checks.title_too_long",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "title_length",
        title: `Chiều dài tiêu đề`,
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.meta.title_length}</p>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "title",
      sFilter: "title_too_long",
    }),
  },
  {
    title: "Trang trùng lặp thẻ Heading H1",
    key: "duplicate_h1",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `H1`,
        className: " ",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.createdAt}</p>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "h1",
      sFilter: "duplicate_h1",
    }),
  },
  {
    title: "Trang có nhiều hơn 1 thẻ Heading 1",
    key: "many_h1",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Số lượng H1`,
        className: " ",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.createdAt}</p>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "h1",
      sFilter: "many_h1",
    }),
  },
  {
    title: "Trang có thẻ Heading 1 quá dài",
    key: "h1_long",

    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Độ dài H1`,
        className: " ",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.createdAt}</p>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "h1",
      sFilter: "many_h1",
    }),
  },
  {
    title: "Trang có thẻ tiêu đề và Heading 1 trùng lặp",
    key: "h1_title_duplicate",

    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Tiêu đề`,
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.createdAt}</p>;
        },
      },
      {
        field: "domain",
        title: `Heading 1`,
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.createdAt}</p>;
        },
      },
    ],
  },
  {
    title: "Trang thiếu thẻ Meta description",
    key: "no_description",
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "checks.no_description",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "metadescription",
      sFilter: "missingmeta",
    }),
  },
  {
    title: "Trang có thẻ meta description quá dài",
    key: "meta.description_length",
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "meta.description_length",
        value: "155",
        operator: ">=",
        type: "field",
      },
    ],

    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "description_length",
        title: `Độ dài thẻ Meta description`,
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.meta.description_length}</p>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "metadescription",
      sFilter: "longmeta",
    }),
  },
  {
    title: "Hình ảnh thiếu thẻ ALT",
    key: "alternative_text",
    type: "resources",
    filter: [
      {
        key: "resource_type",
        value: "image ",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "meta.alternative_text",
        value: "",
        operator: "=",
        type: "field",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "image",
      sFilter: "missingalt",
    }),
  },
  {
    title: "Hình ảnh lớn hơn 100KB",
    key: "image_size",
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "image",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "size",
        value: "102400",
        operator: ">=",
        type: "field",
      },
    ],

    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "size",
        title: `Kích thước (KB)`,
        className: "table-cell-text-tag ",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.size}</p>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "image",
      sFilter: "size100",
    }),
  },
  {
    title: "Trang có số lượng chữ thấp",
    key: "low_character_count",
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "checks.low_character_count",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],

    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "plain_text_word_count",
        title: `Words count`,
        className: " ",
        handleItem: (item: any) => {
          return (
            <p className="fit-one-line">
              {item.meta.content.plain_text_word_count}
            </p>
          );
        },
      },
    ],
  },
  {
    title: "Trang chưa khai báo loại tài liệu",
    key: "has_html_doctype",
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "checks.has_html_doctype",
        value: "false",
        operator: "=",
        type: "field",
      },
    ],

    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "has_html_doctype",
        title: `Doc type`,
        className: `align-end`,
        handleItem: (item: any) => {
          return (
            <div
              className={`${item.checks.has_html_doctype ? "table-cell-success-tag" : "table-cell-error-tag"}`}
            >
              <p className="fit-one-line">
                {item.checks.has_html_doctype ? "True" : "False"}
              </p>
            </div>
          );
        },
      },
    ],
  },
  {
    title: "Trang sử dụng thẻ Href lang sai vị trí",
    key: "",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Vị trí`,
        className: " ",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.createdAt}</p>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "hreflang",
      sFilter: "hrefwrongposition",
    }),
  },
  {
    title: "Trang có quá nhiều liên kết nội bộ đến trang khác",
    key: "internal_links_count",

    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "meta.internal_links_count",
        value: "100",
        operator: ">=",
        type: "field",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Internal link`,
        className: "",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.createdAt}</p>;
        },
      },
    ],
  },
  {
    title: "Trang chứa liên kết nội bộ tới trang 3xx",
    key: "has_links_to_redirects",
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "checks.has_links_to_redirects",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],

    headerData: [
      {
        field: "url",
        title: `From URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `To URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <div className="table-cell-link-error">
              <button
                className="fit-one-line"
                onClick={() => {
                  toggleErrorTableModal({
                    open: true,
                    onCancel: () => { },
                    isHasFilter: true,
                    url: item.url,
                    filter: [
                      {
                        key: "type",
                        value: "anchor",
                        operator: "=",
                        type: "field",
                      },
                      {
                        key: "",
                        value: "and",
                        operator: "",
                        type: "operator",
                      },
                      {
                        key: "type",
                        value: "link",
                        operator: "=",
                        type: "field",
                      },
                    ],
                    headersTable: [
                      {
                        field: "link_from",
                        title: `From URL`,
                        width: "244px",
                        className: "table-cell-link",
                        isSticky: true,
                        handleItem: (item: any) => {
                          return (
                            <a
                              className="fit-one-line"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item.link_from}
                            >
                              {item.link_from}
                            </a>
                          );
                        },
                      },
                      {
                        field: "link_to",
                        title: `To URL`,
                        width: "244px",
                        className: "table-cell-link table-cell-error-check",
                        handleItem: (item: any) => {
                          return (
                            <div className="table-cell-link-error">
                              <a
                                className="fit-one-line"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={item.link_to}
                              >
                                {item.link_to}
                              </a>
                              <div
                                className={`cell-status-code ${!item.page_to_status_code ? "error" : "success"}`}
                              >
                                <p>{item.page_to_status_code || "4xx"}</p>
                              </div>
                            </div>
                          );
                        },
                      },
                      {
                        field: "text",
                        title: `Anchor text`,
                        width: "120px",
                      },
                      {
                        field: "follow",
                        title: `Follow`,
                        width: "100px",
                        className: "table-cell-link table-cell-error-check",
                        handleItem: (item: any) => {
                          return (
                            <div
                              className={`cell-status-code ${item.dofollow ? "success" : "error"}`}
                            >
                              <p>{item.dofollow ? "True" : "False"}</p>
                            </div>
                          );
                        },
                      },
                    ],
                    activeTab: "0",
                  });
                }}
              >
                Xem danh sách liên kết
              </button>
            </div>
          );
        },
      },
    ],
  },
  {
    title: "Trang gắn thẻ Noindex đặt trong sitemap",
    key: "",

    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Status code`,
        className: "table-cell-error-tag ",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.createdAt}</p>;
        },
      },
    ],
  },
  {
    title: "Trang có 404 đặt trong sitemap",
    key: "",

    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Status code`,
        className: "table-cell-error-tag ",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.createdAt}</p>;
        },
      },
    ],
  },
  {
    title: "Trang có gắn sai thẻ canonical đặt trong sitemap",
    key: "",

    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Canonical Tag`,
        className: "table-cell-link table-cell-error-tag",
        handleItem: (item: any) => {
          return (
            <div className="table-cell-link-error">
              <a className="fit-one-line">{item.createdAt}</a>
              <p className="fit-one-line">{item.createdAt}</p>
            </div>
          );
        },
      },
    ],
  },
  {
    title: "Trang có phiên bản HTTP đặt trong sitemap.xml",
    key: "",
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "https",
      sFilter: "httpinsitemap",
    }),
  },
  {
    title: "Trang có quá nhiều liên kết ngoài",
    key: "external_links_count",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "meta.external_links_count",
        value: "100",
        operator: ">=",
        type: "field",
      },
    ],

    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `External link`,
        className: " ",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.createdAt}</p>;
        },
      },
    ],
  },
  {
    title: "Trang có liên kết ngoài bị hỏng",
    key: "",

    filter: [
      {
        key: "direction",
        value: "external",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "is_broken",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],
    type: "links",
    headerData: [
      {
        field: "url",
        title: `From URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `To URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <div className="table-cell-link-error">
              <button
                className="fit-one-line"
                onClick={() => {
                  toggleErrorTableModal({
                    open: true,
                    onCancel: () => { },
                    isHasFilter: true,
                    url: item.url,
                    filter: [
                      {
                        key: "resource_type",
                        value: "html",
                        operator: "=",
                        type: "field",
                      },
                      {
                        key: "",
                        value: "and",
                        operator: "",
                        type: "operator",
                      },
                      {
                        key: "checks.https_to_http_links",
                        value: "true",
                        operator: "=",
                        type: "field",
                      },
                    ],
                    headersTable: [
                      {
                        field: "url",
                        title: `From URL`,
                        width: "244px",
                        className: "table-cell-link",
                        isSticky: true,
                        handleItem: (item: any) => {
                          return (
                            <a
                              className="fit-one-line"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item.url}
                            >
                              {item.url}
                            </a>
                          );
                        },
                      },
                      {
                        field: "domain",
                        title: `From URL`,
                        width: "244px",
                        className: "table-cell-link table-cell-error-check",
                        handleItem: (item: any) => {
                          return (
                            <div className="table-cell-link-error">
                              <a
                                className="fit-one-line"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={item.url}
                              >
                                {item.url}
                              </a>
                              <div
                                className={`cell-status-code ${!item.status ? "error" : "success"}`}
                              >
                                <p>{item.status || "4xx"}</p>
                              </div>
                            </div>
                          );
                        },
                      },
                      {
                        field: "domain",
                        title: `Anchor text`,
                        width: "120px",
                      },
                      {
                        field: "follow",
                        title: `Follow`,
                        width: "100px",
                        className: "table-cell-link table-cell-error-check",
                        handleItem: (item: any) => {
                          return (
                            <div
                              className={`cell-status-code ${!!item.status ? "error" : "success"}`}
                            >
                              <p>{item.status || "True"}</p>
                            </div>
                          );
                        },
                      },
                    ],
                    activeTab: "1",
                  });
                }}
              >
                Xem danh sách liên kết
              </button>
            </div>
          );
        },
      },
    ],
  },
  {
    title: "Trang chuyển hướng tạm thời",
    key: "status_code",
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "status_code",
        value: "302",
        operator: "<>",
        type: "field",
      },
    ],

    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "status_code",
        title: `Status code`,
        className: "table-cell-error-tag ",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.status_code || "302"}</p>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "statuscode",
      sFilter: "code302",
    }),
  },
  {
    title: "Trang có đường dẫn chứa tham số động",
    key: "seo_friendly_url_dynamic_check",
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "checks.seo_friendly_url_dynamic_check",
        value: "false",
        operator: "=",
        type: "field",
      },
    ],

    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
  },
  {
    title: "Trang có đường dẫn chứa kí tự đặc biệt",
    key: "seo_friendly_url_characters_check",
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "checks.seo_friendly_url_characters_check",
        value: "false",
        operator: "=",
        type: "field",
      },
    ],

    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
  },
  {
    title: "Trang có đường dẫn quá dài",
    key: "seo_friendly_url_relative_length_check",
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "checks.seo_friendly_url_relative_length_check",
        value: "false",
        operator: "=",
        type: "field",
      },
    ],

    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "url_length",
        title: `URL Length`,
        className: "table-cell-text-tag ",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.url_length}</p>;
        },
      },
    ],
  },
  {
    title: "Trang chứa liên kết nội bộ đi gắn thẻ Nofollow",
    key: "nofollow",
    type: "links",
    filter: [
      {
        key: "type",
        value: "anchor",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "or",
        operator: "",
        type: "operator",
      },
      {
        key: "type",
        value: "link",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "dofollow",
        value: "false",
        operator: "=",
        type: "field",
      },
    ],

    headerData: [
      {
        field: "url",
        title: `From URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.link_from}
            >
              {item.link_from}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Danh sách`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <div className="table-cell-link-error">
              <button
                className="fit-one-line"
                onClick={() => {
                  toggleErrorTableModal({
                    open: true,
                    onCancel: () => { },
                    isHasFilter: true,
                    url: item.link_from,
                    filter: [
                      {
                        key: "type",
                        value: "anchor",
                        operator: "=",
                        type: "field",
                      },
                      {
                        key: "",
                        value: "or",
                        operator: "",
                        type: "operator",
                      },
                      {
                        key: "type",
                        value: "link",
                        operator: "=",
                        type: "field",
                      },
                      {
                        key: "",
                        value: "and",
                        operator: "",
                        type: "operator",
                      },
                      {
                        key: "dofollow",
                        value: "false",
                        operator: "=",
                        type: "field",
                      },
                    ],
                    headersTable: [
                      {
                        field: "url",
                        title: `From URL`,
                        width: "244px",
                        className: "table-cell-link",
                        isSticky: true,
                        handleItem: (item: any) => {
                          return (
                            <a
                              className="fit-one-line"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item.link_from}
                            >
                              {item.link_from}
                            </a>
                          );
                        },
                      },
                      {
                        field: "domain",
                        title: `From URL`,
                        width: "244px",
                        className: "table-cell-link table-cell-error-check",
                        handleItem: (item: any) => {
                          return (
                            <div className="table-cell-link-error">
                              <a
                                className="fit-one-line"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={item.link_to}
                              >
                                {item.url}
                              </a>
                              <div
                                className={`cell-status-code ${!item.page_to_status_code ? "error" : "success"}`}
                              >
                                <p>{item.page_to_status_code || "4xx"}</p>
                              </div>
                            </div>
                          );
                        },
                      },
                      {
                        field: "domain",
                        title: `Anchor text`,
                        width: "120px",
                      },
                      {
                        field: "follow",
                        title: `Follow`,
                        width: "100px",
                        className: "table-cell-link table-cell-error-check",
                        handleItem: (item: any) => {
                          return (
                            <div
                              className={`cell-status-code ${!!item.status ? "error" : "success"}`}
                            >
                              <p>{item.status || "True"}</p>
                            </div>
                          );
                        },
                      },
                    ],
                    activeTab: "1",
                  });
                }}
              >
                Xem danh sách liên kết
              </button>
            </div>
          );
        },
      },
    ],
  },
  {
    title: "Trang có tiêu đề quá ngắn",
    key: "title_too_short",
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "checks.title_too_short",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],
    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "title_length",
        title: `Chiều dài tiêu đề`,
        className: "",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.meta.title_length}</p>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "title",
      sFilter: "title_too_short",
    }),
  },
  {
    title: "Trang không được thu thập dữ liệu",
    key: "",

    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Indexabitity`,
        className: "table-cell-error-tag ",
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.createdAt}</p>;
        },
      },
    ],
  },
  {
    title: "Trang cần nhiều hơn 3 click để truy cập",
    key: "click_depth",
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "click_depth",
        value: "4",
        operator: ">",
        type: "field",
      },
    ],

    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "click_depth",
        title: `Lượt click`,
        handleItem: (item: any) => {
          return <p className="fit-one-line">{item.click_depth}</p>;
        },
      },
    ],
    navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    navigateFilter: queryString.stringify({
      tab: 2,
      mFilter: "clickdepth",
      sFilter: ["depth4", "depth5", "depth5+"],
    }),
  },
  {
    title: "Cài đặt file Robot.txt",
    key: "robots_txt",
  },
  {
    title: "Có XML sitemap",
    key: "sitemap",
  },
];

const dataIssueInfor: IIssueCommon[] = [
  {
    title: "Trang không có liên kết nội bộ trỏ đến",
    key: "is_orphan_page",
    type: "page",
    filter: [
      {
        key: "resource_type",
        value: "html",
        operator: "=",
        type: "field",
      },
      {
        key: "",
        value: "and",
        operator: "",
        type: "operator",
      },
      {
        key: "checks.is_orphan_page",
        value: "true",
        operator: "=",
        type: "field",
      },
    ],

    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
    ],
  },
  {
    title: "Trang có liên kết nội bộ trỏ tới với anchortext không đa dạng",
    key: "",

    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Chi tiết`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return <button className="fit-one-line">Chi tiết</button>;
        },
      },
    ],
  },
  {
    title: "Trang chỉ có duy nhất 1 liên kết nội bộ trỏ đến",
    key: "",

    headerData: [
      {
        field: "url",
        title: `URL`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return (
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              {item.url}
            </a>
          );
        },
      },
      {
        field: "domain",
        title: `Chi tiết`,
        className: "table-cell-link",
        handleItem: (item: any) => {
          return <button className="fit-one-line">Chi tiết</button>;
        },
      },
    ],
  },
  {
    title: "Có schema Organization",
    key: "",
  },
  {
    title: "Có Schema Local Business",
    key: "",
  },
];

const mapData = { dataIssueError, dataIssueInfor, dataIssueWaring };

export default mapData;
