import storage from "redux-persist/lib/storage";
import { Roles } from "../../models/common/models.enum";
import { IBreadcrumbs } from "../../models/redux/breadcrumbs";
import { IUser } from "../../models/user";

export const USER_TOKEN_KEY = "token";
export const USER_ROLE_KEY = "role";
export const BREADCRUMBS_KEY = "breadcrumbs";
export const PROJECT_ID_KEY = "project_id";
export const REPORT_ID_KEY = "report_id";
const USER_KEY = "user";

const getExpiryEpoch = (seconds: number) => {
  const t = new Date();
  t.setSeconds(t.getSeconds() + seconds);
  return t.getSeconds();
};

const storeToken = async (token: string) => {
  await storage.setItem(USER_TOKEN_KEY, token);
};
const storeRole = async (role: Roles) => {
  await storage.setItem(USER_ROLE_KEY, role);
};
const storeProjectId = async (id: string) => {
  await storage.setItem(PROJECT_ID_KEY, id);
};

const storeBreadcrumbs = async (data: IBreadcrumbs[]) => {
  await storage.setItem(BREADCRUMBS_KEY, JSON.stringify(data));
  await window.dispatchEvent(new Event("storageBreadcrumbs"));
};
const clearBreadcrumbs = async () => {
  await storage.removeItem(BREADCRUMBS_KEY);
  await window.dispatchEvent(new Event("storageBreadcrumbs"));
};

const getAccessToken = async () => {
  const tokenString = await storage.getItem(USER_TOKEN_KEY);

  return tokenString;
};

const clear = async () => {
  await storage.removeItem(USER_TOKEN_KEY);
  await storage.removeItem(USER_ROLE_KEY);
  await storage.removeItem(USER_KEY);
};

const hasValidToken = async () => {
  const tokenString = await storage.getItem(USER_TOKEN_KEY);
  return Boolean(tokenString);
};

const storeUser = async (user: IUser) => {
  await storage.setItem(USER_KEY, JSON.stringify(user));
};

const getUser = async () => {
  const user = await storage.getItem(USER_KEY);
  if (!user) {
    return null;
  }
  return JSON.parse(user) as IUser;
};

const localStorageHanlde = {
  getExpiryEpoch,
  storeToken,
  getAccessToken,
  hasValidToken,
  clear,
  storeUser,
  getUser,
  storeRole,
  storeBreadcrumbs,
  clearBreadcrumbs,
  storeProjectId,
};

export default localStorageHanlde;
