import { Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef } from "react";
import { ModalCustomWrapper } from "./styles";
import Button from "@mui/material/Button";
import { ImageCustom } from "..";
import IconClose from "../../assets/images/common/icon_close_line_black_background.svg";
import variableStyles from "../../theme/variable-styles";
import { IProject } from "../../models/project";
import SelectProject from "./SelectProject";
import { IBasePagingRes } from "../../models/common/models.type";
import GridPagination from "../Table/TableData/GridPagination";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ModalCustomProps {
  open?: boolean;
  textSubmit?: string;
  textClose?: string;
  onSubmit?: () => void;
  onClose?: () => void;
  title: string;
  description?: string;
  children: any;
  width?: string;
  disabledSubmit?: boolean;
  disabledSelectProject?: boolean;
  valueProject?: IProject;
  onChangeProject?: (project: IProject) => void;
  dataProject?: IBasePagingRes<IProject>;
  onChangeParams?: () => void;
  isLoading?: boolean;
  hasFooter?: boolean;
  maxheight?: string;
  background?: string;
  pagination?: {
    page: number;
    pageSize: number;
    total: number;
    onPageChange: (newPage: number) => void;
    text?: string;
  };
}

export default function ModalCustom(props: ModalCustomProps) {
  const {
    open = false,
    textSubmit = "Create Project",
    textClose = "Cancel",
    onSubmit,
    onClose,
    title,
    children,
    width,
    description,
    disabledSubmit,
    disabledSelectProject = false,
    valueProject,
    onChangeProject,
    dataProject,
    onChangeParams,
    isLoading = false,
    hasFooter = true,
    maxheight = "calc(100vh -100px)",
    background = variableStyles.NaturalColor100,
    pagination,
  } = props;

  const handleOnchange = (v: string | number) => {
    const selectedProject = dataProject?.items.find(item => item.id === v);
    if (!!selectedProject) {
      onChangeProject && onChangeProject(selectedProject);
    }
  };

  return (
    <ModalCustomWrapper
      open={open}
      TransitionComponent={Transition}
      width={width}
      maxheight={maxheight}
      className="scrollbar-small"
      background={background}
      onClose={() => {
        if (!onSubmit) {
          onClose && onClose();
        }
      }}
      BackdropProps={{
        style: {
          background: variableStyles.NaturalColor1000,
          opacity: ".3",
        },
      }}
    >
      <div className="scroll-keep-box">
        <div className="modal-header">
          <div className="header-content">
            <p className="modal-title fit-one-line">{title}</p>
            {!!description && (
              <p className="modal-description">{description}</p>
            )}
          </div>
          <Button
            className={"button-close"}
            size="large"
            variant="text"
            onClick={onClose}
          >
            <ImageCustom
              src={IconClose}
              alt="Icon-Close"
              width={"28px"}
              height={"28px"}
            />
          </Button>
        </div>
      </div>
      {!!valueProject && !!dataProject && (
        <div className="select-project-wrapper">
          <SelectProject
            onChange={v => handleOnchange(v.target.value)}
            value={valueProject?.projectId}
            options={dataProject?.items || []}
            disabled={disabledSelectProject}
            onGetMore={() => {
              if (onChangeParams) {
                onChangeParams();
              }
            }}
          />
        </div>
      )}
      <div className="modal-content">{children}</div>
      {!!pagination ? (
        <GridPagination
          total={pagination.total}
          page={pagination.page}
          pageSize={pagination.pageSize}
          onPageChange={pagination.onPageChange}
          text={pagination.text}
        />
      ) : (
        <>
          {!!hasFooter && (
            <div className="modal-action">
              <Button
                size="xsmall"
                variant="outlined"
                className="button-cancel"
                onClick={onClose}
              >
                {textClose}
              </Button>

              {!!onSubmit && (
                <Button
                  size="xsmall"
                  variant="contained"
                  className="button-submit"
                  onClick={onSubmit}
                  disabled={disabledSubmit || isLoading}
                >
                  {isLoading && <span className="loader"></span>}
                  <p className={`text-submit ${isLoading ? "is-loading" : ""}`}>
                    {textSubmit}
                  </p>
                </Button>
              )}
            </div>
          )}
        </>
      )}
    </ModalCustomWrapper>
  );
}
