import "react-perfect-scrollbar/dist/css/styles.css";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import reduxStore from "../src/redux/store";
import "./App.css";
import Main from "./pages/Main";
import "./i18n/i18n";
import "moment/locale/vi";
import Toast from "./components/Toast/Toast";
import ConfirmModal from "./components/ConfirmModal/ConfirmModal";
import { useEffect } from "react";
import { CountryLanguage, changeLanguage } from "./i18n/i18n";

const App = () => {
  useEffect(() => {
    const locale = localStorage.getItem("language");

    if (!locale) {
      localStorage.setItem("language", CountryLanguage.EN);
      changeLanguage(CountryLanguage.EN);
    } else {
      changeLanguage(locale as CountryLanguage);
    }
  }, []);

  return (
    <Provider store={reduxStore.store}>
      <PersistGate loading={null} persistor={reduxStore.persistor}>
        <Toast />
        <Main />
        <ConfirmModal />
      </PersistGate>
    </Provider>
  );
};

export default App;
