import { styled } from "@mui/material/styles";
import variableStyles from "../../../theme/variable-styles";

export const DomainOverviewWrapper = styled("div")(() => ({
  "& .domain-overview-content": {
    padding: "24px",
    height: "calc(100vh - 142px)",
    overflow: "auto",

    "& .content-location-filter": {
      marginBottom: "24px",
    },
    "& .indexNoPercent-list": {
      gap: "16px",
      display: "grid",
      marginBottom: "36px",
      "@media (min-width: 1260px)": {
        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
      },
      "@media (max-width: 1259.98px) and (min-width: 900px)": {
        gridTemplateColumns:
          "minmax(140px, 100%) repeat(2, minmax(140px, 100%))",
      },
      "@media (max-width: 899.98px)": {
        gridTemplateColumns:
          "minmax(140px, 100%) repeat(1, minmax(140px, 100%))",
      },
    },

    "& .content-divide-2": {
      gap: "16px",
      display: "grid",
      gridTemplateColumns: "calc(50% - 8px) calc(50% - 8px)",
      marginBottom: "36px",
      "& .divide-caption": {
        marginBottom: "24px",
      },
      "& .divide-right, & .divide-left": {
        padding: "8px",
        border: `1px solid ${variableStyles.NaturalColor300}`,
        borderRadius: "12px",
      },
      "& .left-content, & .right-content": {
        display: "flex",
        flexDirection: "column",
        gap: "40px",
      },
    },
    "& .h2-title": {
      color: variableStyles.NaturalColor1000,
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "20px",
      lineHeight: "24.2px",
      marginBottom: "16px",
    },
    "& .backlink-wrapper": {
      display: "flex",
      gap: "16px",
      "& .backlink-left": {
        width: "302px",
        flex: "0 0 auto",
        backgroundColor: variableStyles.Info50,
        padding: "16px",
        borderRadius: "12px",
        "& .left-title": {
          color: variableStyles.NaturalColor1000,
          fontWeight: variableStyles.fwMedium,
          fontSize: "14px",
          lineHeight: "16.94px",
          marginBottom: "16px",
          paddingBottom: "16px",
          borderBottom: `1px solid ${variableStyles.Info500}`,
        },
        "& .left-chart": {
          marginBottom: "24px",
        },
        "& .left-proccess-list": {
          display: "flex",
          flexDirection: "column",
          gap: "6px",
          "& li": {
            padding: "4px 12px",
            "& .proccess-text": {
              gap: "12px",
              color: variableStyles.NaturalColor900,
              fontWeight: variableStyles.fwMedium,
              fontSize: "12px",
              lineHeight: "14.52px",
              marginBottom: "6px",
            },
          },
        },
      },
    },
  },
}));
