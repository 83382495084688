import React from "react";
import CheckToolTitle from "../../components/CheckToolTitle";
import { BacklinkDetailWrapper } from "./styles";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { ROUTE_PATH } from "../../../../common/constants/app.constant";
import queryString from "query-string";
import { TabsCustom } from "../../../../components";
import { IValue } from "../../../../models/common/models.type";
import { ETypeLinkCheck } from "../../../../models/common/models.enum";
import BacklinkOverviewTab from "./components/BacklinkTabs/BacklinkOverviewTab";
import BacklinkAnchorTextTab from "./components/BacklinkTabs/BacklinkAnchorTextTab";
import BacklinkIndexPageTab from "./components/BacklinkTabs/BacklinkIndexPageTab";
import BacklinkReferringDomainTab from "./components/BacklinkTabs/BacklinkReferringDomainTab";
import BacklinkReferringIPSTab from "./components/BacklinkTabs/BacklinkReferringIPSTab";

enum EBacklinkTabs {
  Overview = "overview",
  AnchorText = "anchortext",
  ReferringDomain = "referringdomain",
  ReferringIPS = "referringips",
  IndexPage = "indexpage",
}

export interface IDataFilterBacklinkType {
  target: string | null;
  type: string | null;
  tab: string | null;
  searchLink: string | null;
  searchLinkType: string | null;
  typeLink: string | null;
  followType: string | null;
  backLinkType: string | null;
  reputationFrom: string | null;
  reputationTo: string | null;
  anchorText: string | null;
  domainSearch: string | null;
}

export default function BackLinkCheck() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const dataFilter: IDataFilterBacklinkType = {
    target: searchParams.get("target"),
    type: searchParams.get("type"),
    tab: searchParams.get("tab") || EBacklinkTabs.Overview,
    searchLink: searchParams.get("searchlink"),
    searchLinkType: searchParams.get("searchlinktype"),
    typeLink: searchParams.get("typelink"),
    followType: searchParams.get("followtype"),
    backLinkType: searchParams.get("backlinktype"),
    reputationFrom: searchParams.get("reputationfrom"),
    reputationTo: searchParams.get("reputationto"),
    anchorText: searchParams.get("anchortext"),
    domainSearch: searchParams.get("domainsearch"),
  };

  const dataTabs: IValue[] = [
    { value: EBacklinkTabs.Overview, title: "Overview" },
    { value: EBacklinkTabs.AnchorText, title: "Anchor text" },
    { value: EBacklinkTabs.ReferringDomain, title: "Referring Domain" },
    { value: EBacklinkTabs.ReferringIPS, title: "Referring IPS" },
    { value: EBacklinkTabs.IndexPage, title: "Index page" },
  ];
  return (
    <BacklinkDetailWrapper>
      <CheckToolTitle
        title="Domain overview"
        domain={dataFilter.target || ""}
        onSubmit={value => {
          const path = generatePath(ROUTE_PATH.BACKLINK_CHECK);
          const newParams = queryString.stringify({
            ...value,
            tab: dataFilter.tab,
          });
          navigate({
            pathname: `${path}`,
            search: `${newParams}`,
          });
        }}
        value={{
          target: dataFilter.target,
          type: dataFilter.type as ETypeLinkCheck,
        }}
      />
      <TabsCustom
        data={dataTabs}
        onChange={(v: EBacklinkTabs) => {
          const path = generatePath(ROUTE_PATH.BACKLINK_CHECK);
          const newParams = queryString.stringify({
            tab: v,
            target: dataFilter.target,
            type: dataFilter.type,
          });
          navigate({
            pathname: `${path}`,
            search: `${newParams}`,
          });
        }}
        selectedValue={dataFilter.tab}
      />
      {dataFilter.tab === EBacklinkTabs.Overview ? (
        <BacklinkOverviewTab
          dataFilter={dataFilter}
          onChangeParamsURL={value => {
            const path = generatePath(ROUTE_PATH.BACKLINK_CHECK);
            navigate({
              pathname: `${path}`,
              search: `${value}`,
            });
          }}
        />
      ) : dataFilter.tab === EBacklinkTabs.AnchorText ? (
        <BacklinkAnchorTextTab
          onChangeParamsURL={value => {
            const path = generatePath(ROUTE_PATH.BACKLINK_CHECK);
            navigate({
              pathname: `${path}`,
              search: `${value}`,
            });
          }}
          dataFilter={dataFilter}
        />
      ) : dataFilter.tab === EBacklinkTabs.ReferringDomain ? (
        <BacklinkReferringDomainTab
          onChangeParamsURL={value => {
            const path = generatePath(ROUTE_PATH.BACKLINK_CHECK);
            navigate({
              pathname: `${path}`,
              search: `${value}`,
            });
          }}
          dataFilter={dataFilter}
        />
      ) : dataFilter.tab === EBacklinkTabs.ReferringIPS ? (
        <BacklinkReferringIPSTab />
      ) : (
        <BacklinkIndexPageTab
          onChangeParamsURL={value => {
            const path = generatePath(ROUTE_PATH.BACKLINK_CHECK);
            navigate({
              pathname: `${path}`,
              search: `${value}`,
            });
          }}
          dataFilter={dataFilter}
        />
      )}
    </BacklinkDetailWrapper>
  );
}
