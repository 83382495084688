import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import {
  generatePath,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ROUTE_PATH } from "../../../common/constants/app.constant";
import { DEFAULT_BASE_PAGING_RES } from "../../../common/constants/value.constant";
import {
  FastFilterList,
  FilterSelectCustom,
  TableNavDetailNoPagination,
} from "../../../components";
import { IBasePagingRes, IValue } from "../../../models/common/models.type";
import { IStateApp } from "../../../models/redux";
import { IGetReportsRes } from "../../../models/report";
import AuditReportService from "../../../services/api/audit.report.service";
import { DomainAuditErrorWrapper } from "./styles";

const DEFAULT_QUERY = {
  pageIndex: 1,
  pageSize: 10,
};

export default function DomainAuditError() {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const dataReport = useSelector<IStateApp, IGetReportsRes>(
    appState => appState.project?.dataReport
  );
  const [tabActive, setTabActive] = useState<string>("0");
  const [resDataPaging, setResDataPaging] = useState<IBasePagingRes<any>>({
    ...DEFAULT_BASE_PAGING_RES,
  });
  const [resData, setResData] = useState<any[] | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dataFilter: { tab: string; follow: string; code: string } = {
    tab: searchParams.get("tab") || "",
    follow: searchParams.get("follow") || "",
    code: searchParams.get("code") || "",
  };

  useEffect(() => {
    if (!!dataFilter.tab && dataFilter.tab !== tabActive) {
      setTabActive(dataFilter.tab);
    }
  }, [dataFilter.tab]);

  useEffect(() => {
    if (!!dataReport.thirdPartyId && !!dataReport.maxCrawlPages) {
      getDataOfPage();
    }
  }, [location, dataReport]);

  const tabData: IValue[] = [
    { title: "Inlink", value: "inlink" },
    { title: "Outlink", value: "outlink" },
    { title: "Image", value: "image" },
  ];

  const filterTypeData: IValue[] = [
    { title: "Tất cả", value: "all" },
    { title: "Follow", value: "follow" },
    { title: "No Follow", value: "nofollow" },
  ];

  const filterCodeData: IValue[] = [
    { title: "Tất cả", value: "all" },
    { title: "200", value: "200" },
    { title: "301", value: "301" },
    { title: "302", value: "302" },
    { title: "404", value: "404" },
    { title: "403", value: "403" },
    { title: "502", value: "502" },
    { title: "505", value: "505" },
    { title: "501", value: "501" },
  ];

  const headersTable = [
    {
      field: "link_from",
      title: `From URL`,
      width: "244px",
      className: "table-cell-link",
      isSticky: true,
      handleItem: (item: any) => {
        return (
          <a
            className="fit-one-line"
            target="_blank"
            rel="noopener noreferrer"
            href={item.link_from}
          >
            {item.link_from}
          </a>
        );
      },
    },
    {
      field: "link_to",
      title: `To URL`,
      width: "244px",
      className: "table-cell-link table-cell-error-check",
      handleItem: (item: any) => {
        return (
          <div className="table-cell-link-error">
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.link_to}
            >
              {item.link_to}
            </a>
            <div className={`cell-status-code error`}>
              <p>{item.page_to_status_code || "4xx"}</p>
            </div>
          </div>
        );
      },
    },
    {
      field: "text",
      title: `Anchor text`,
      width: "120px",
    },
    {
      field: "dofollow",
      title: `Follow`,
      width: "100px",
      className: "table-cell-link table-cell-error-check",
      handleItem: (item: any) => {
        return (
          <div
            className={`cell-status-code ${item.dofollow ? "success" : "error"}`}
          >
            <p>{item.dofollow ? "True" : "False"}</p>
          </div>
        );
      },
    },
  ];

  const headersImageTable = [
    {
      field: "link_from",
      title: `From URL`,
      width: "244px",
      className: "table-cell-link",
      isSticky: true,
      handleItem: (item: any) => {
        return (
          <a
            className="fit-one-line"
            target="_blank"
            rel="noopener noreferrer"
            href={item.link_from}
          >
            {item.link_from}
          </a>
        );
      },
    },
    {
      field: "link_to",
      title: `To URL`,
      width: "244px",
      className: "table-cell-link table-cell-error-check",
      handleItem: (item: any) => {
        return (
          <div className="table-cell-link-error">
            <a
              className="fit-one-line"
              target="_blank"
              rel="noopener noreferrer"
              href={item.link_to}
            >
              {item.link_to}
            </a>
            <div className={`cell-status-code error`}>
              <p>{item.page_to_status_code || "4xx"}</p>
            </div>
          </div>
        );
      },
    },
    {
      field: "text",
      title: `Size ảnh`,
      width: "120px",
    },
    {
      field: "text",
      title: `Alt text`,
      width: "120px",
    },
  ];

  const getDataOfPage = async () => {
    const params = {
      id: dataReport.thirdPartyId,
      limit: dataReport.maxCrawlPages,
      filters: [
        {
          key: "type",
          value: "anchor",
          operator: "=",
          type: "field",
        },
        {
          key: "",
          value: "and",
          operator: "",
          type: "operator",
        },
        {
          key: "type",
          value: "link",
          operator: "=",
          type: "field",
        },
      ],
      statusCode: dataFilter.code,
    };

    if (dataFilter.code === "all") {
      // delete params.statusCode;
    }

    if (dataFilter.follow === "all") {
    }

    const res = await AuditReportService.getErrorsFromLink(params);
    if (!res.isError) {
      setResData(res.data.items);
      const newData = {
        page: 1,
        pageSize: 10,
        total: res.data.items.length,
        items: [...res.data.items]?.slice(1 * 10 - 10, 1 * 10),
        isFull: true,
      };
      setResDataPaging(newData);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleChangeFilter = (value: string, key: string) => {
    const queryParams: any = {
      ...DEFAULT_QUERY,
      ...dataFilter,
      pageIndex: key === "pageIndex" ? value : 1,
      [key]: value,
    };
    if (!value || value.length <= 0 || value === "all") {
      delete queryParams[key];
    }
    const removeEmptyQuery = Object.fromEntries(
      Object.entries(queryParams).filter(([_, v]) => v != null)
    );
    navigateParams(removeEmptyQuery);
  };

  const navigateParams = (value: { [k: string]: unknown }) => {
    if (!!dataReport.auditId) {
      const newParams = queryString.stringify(value);
      const path = generatePath(ROUTE_PATH.DOMAIN_AUDIT_ERROR_DETAIL, {
        id: dataReport.auditId,
      });
      navigate({
        pathname: `${path}`,
        search: `${newParams}`,
      });
    }
  };

  const handleChangePage = (page: number) => {
    if (!!resData) {
      const newData = {
        page: page,
        pageSize: 10,
        total: resData.length,
        items: [...resData]?.slice(page * 10 - 10, page * 10),
        isFull: true,
      };
      setResDataPaging(newData);
    }
  };

  return (
    <DomainAuditErrorWrapper>
      <Helmet>
        <title>Domain Audit</title>
      </Helmet>
      <div className="page-header flex-between-align-center">
        <FastFilterList
          selectedValue={tabActive}
          onChange={(v: string | null) => {
            handleChangeFilter(v || "", "tab");
          }}
          data={tabData}
        />
        <div className="page-filter flex-end-align-center">
          <FilterSelectCustom
            value={dataFilter.code || "all"}
            onChange={(v: any) => {
              handleChangeFilter(v, "code");
            }}
            data={filterCodeData}
            placeholder="to URL status code"
            name="code"
            filterText="to URL status code: "
          />
          <FilterSelectCustom
            value={dataFilter.follow || "all"}
            onChange={(v: any) => {
              handleChangeFilter(v, "follow");
            }}
            data={filterTypeData}
            placeholder="Loại"
            name="type"
            filterText="Loại: "
          />
        </div>
      </div>
      <TableNavDetailNoPagination
        data={resDataPaging}
        headersTable={
          dataFilter.tab === "image" ? headersImageTable : headersTable
        }
        handlePageChange={(page: number) => {
          handleChangePage(page);
        }}
        loading={isLoading}
        isInfiniteScroll
      />
    </DomainAuditErrorWrapper>
  );
}
