import { Fragment, useEffect, useState } from "react";
import { IBasePagingRes } from "../../models/common/models.type";
import {
  ITableHeader,
  TableData,
} from "../../components/Table/TableData/TableData";
import { Box, Button, Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import VALUE_LIST, {
  DEFAULT_BASE_PAGING_RES,
} from "../../common/constants/value.constant";
import AuditService from "../../services/api/audit.service";
import {
  AuditTaskStatus,
  IAuditTaskSearchParams,
  IGetAuditTaskStatusRes,
} from "../../models/audit";
import CommonHandle from "../../common/handles/common.handles";
import { toggleConfirmModal } from "../../components/ConfirmModal/ConfirmModal";
import SelectCustom from "../../components/SelectCustom";
import StartAuditModal from "./modal/StartAuditModal";
import { toggleMessage } from "../../components/Toast/Toast";
import palette from "../../theme/palette";
import { LinearProgressWithLabel } from "../../components/LinearWithValueLabel";
import AuditDetailModal from "./modal/AuditDetailModal";

enum DataAction {
  Cancel = "Cancel",
  ViewDetail = "ViewDetail",
}

const AuditPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [datas, setDatas] = useState<IBasePagingRes<IGetAuditTaskStatusRes>>({
    ...DEFAULT_BASE_PAGING_RES,
  });
  const [param, setParam] = useState<IAuditTaskSearchParams>({
    pageIndex: 1,
    pageSize: 10,
  });
  const [taskId, setTaskId] = useState<number | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    getData();
  }, [param]);

  async function getData() {
    setLoading(true);
    const res = await AuditService.getTasks(param);

    if (res.success) {
      setDatas(res.data);
      setLoading(false);
    }
  }

  const headersTable: ITableHeader<IGetAuditTaskStatusRes>[] = [
    {
      field: "id",
      title: "ID",
    },
    {
      field: "domainUrl",
      title: "Domain",
    },
    {
      field: "limit",
      title: "Common.Limit",
    },
    {
      field: "percentComplete",
      title: "Common.PercentComplete",
      width: 200,
      handleItem: item => {
        return <LinearProgressWithLabel value={item.percentComplete} />;
      },
    },
    {
      field: "status",
      title: "Common.Status",
      handleItem: item => {
        const color =
          item.status === AuditTaskStatus.Done
            ? palette.primary.main
            : item.status === AuditTaskStatus.Cancelled
              ? palette.warning.main
              : item.status === AuditTaskStatus.Error
                ? palette.error.main
                : palette.secondary.main;
        return (
          <Box component={"span"} color={color}>
            {VALUE_LIST.AUDIT_STATUS_OPTIONS.find(f => f.value === item.status)
              ?.title ?? "Đang chờ"}
          </Box>
        );
      },
    },
    {
      field: "createdAt",
      title: "Common.DateCreated",
      handleItem: item => {
        return CommonHandle.formatDateTime(item.createdAt);
      },
    },
    {
      field: "createdAt",
      title: "",
      width: 128,
      isHide: true,
      handleItem: item => {
        return item.status === AuditTaskStatus.Running ? (
          <Button
            size="small"
            onClick={() => handleAction(DataAction.Cancel, item)}
          >
            Cancel
          </Button>
        ) : item.status === AuditTaskStatus.Done ? (
          <Button size="small" onClick={() => setTaskId(item.id)}>
            Xem chi tiết
          </Button>
        ) : null;
      },
    },
  ];

  // const dataAction = (item: IGetAuditTaskStatusRes) => {
  //   let options: IDataActionType[] = [];

  //   if (item.status === AuditTaskStatus.Running) {
  //     options.push({ text: t("Cancel"), key: DataAction.Cancel });
  //   }
  //   if (item.status === AuditTaskStatus.Done) {
  //     options.push({ text: t("Xem chi tiết"), key: DataAction.ViewDetail });
  //   }

  //   return options;
  // };

  const handleAction = (key: string, item: IGetAuditTaskStatusRes) => {
    switch (key) {
      case DataAction.Cancel:
        toggleConfirmModal({
          title: "Cancel",
          content: `Bạn có muốn huỷ tác vụ này ${item.id}`,
          onSubmit: async () => onCancelAudit(item.id),
        });
        break;

      default:
        break;
    }
  };

  const handleChangeFilter = (
    field: keyof IAuditTaskSearchParams,
    value: any
  ) => {
    setParam(pre => {
      return {
        ...pre,
        [field]: value,
      };
    });
  };

  const startAuditDomain = async (success: boolean) => {
    if (success) {
      getData();
      closeAuditModal();
    }
  };

  const closeAuditModal = () => {
    setOpenModal(false);
  };

  const onCancelAudit = async (id: number): Promise<void> => {
    const res = await AuditService.cancelAuditData(id);

    if (res.success) {
      let newDatas = { ...datas };
      const index = newDatas.items.findIndex(f => f.id === id);

      if (index > -1) newDatas.items[index].status = AuditTaskStatus.Cancelled;

      setDatas(newDatas);
    } else {
      toggleMessage({
        type: "error",
        message: res.message ?? "",
        title: "Error Occurred",
      });
    }
  };

  const closeDetailModal = () => {
    setTaskId(null);
  };

  return (
    <Fragment>
      <Helmet>
        <title> Audit | SEO </title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={3} md={3}>
          <SelectCustom
            size="small"
            options={[
              {
                title: "Tất cả",
                value: "all",
              },
              ...VALUE_LIST.AUDIT_STATUS_OPTIONS,
            ]}
            onChange={event => {}}
            label="Lọc theo trạng thái"
            value={"all"}
          />
        </Grid>
        <Grid item xs={3} md={4}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setOpenModal(true)}
          >
            Audit domain mới
          </Button>
        </Grid>

        <Grid item xs={12}>
          <TableData
            handleChangeSearchParams={handleChangeFilter}
            data={datas}
            headersTable={headersTable}
            loading={loading}
          />
        </Grid>
      </Grid>
      <StartAuditModal
        open={openModal}
        onClose={closeAuditModal}
        submit={startAuditDomain}
      />

      <AuditDetailModal
        open={!!taskId}
        onClose={closeDetailModal}
        taskId={taskId}
      />
    </Fragment>
  );
};

export default AuditPage;
