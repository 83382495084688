import React, { useEffect, useState } from "react";
import { IPieChartValue } from "../../../../../../../../models/common/models.type";
import OverviewCommonCaption from "../../../../../OverviewCommonCaption";
import {
  TechnicalOverviewChartWrapper,
  TechnicalOverviewTabWrapper,
} from "./styles";
import IconChart from "../../../../../../../../assets/images/common/icon_chart_line_green.svg";
import {
  ImageCustom,
  LoadingIndicator,
  NoDataBox,
  PieChartDonutShape,
} from "../../../../../../../../components";
import { IIssueListData } from "./tempData";
import IconError from "../../../../../../../../assets/images/common/icon_error_fill_red_border.svg";
import IconWarning from "../../../../../../../../assets/images/common/icon_warning_fill_yellow_border.svg";
import IconSuccess from "../../../../../../../../assets/images/common/icon_success_fill_green_border.svg";
import IconInforBlue from "../../../../../../../../assets/images/common/icon_infor_fill_blue_border.svg";
import IconInfor from "../../../../../../../../assets/images/common/icon_infor_line_grey.svg";
import { Tooltip } from "@mui/material";
import {
  EAuditErrorStatus,
  ESectionOfAuditReport,
} from "../../../../../../../../models/common/models.enum";
import AuditReportService from "../../../../../../../../services/api/audit.report.service";
import { ISEOErrorSummaryRes } from "../../../../../../../../models/seo";
import { useDispatch, useSelector } from "react-redux";
import { setErrorListState } from "../../../../../../../../redux/project/project.duck";
import { IStateApp } from "../../../../../../../../models/redux";
import { IGetReportsRes } from "../../../../../../../../models/report";
import {
  IGetHeadingErrorRes,
  IGetImageErrorRes,
  IGetInformationAbilityRes,
} from "../../../../../../../../models/audit-report";
import queryString from "query-string";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../../../../../../../common/constants/app.constant";

export default function TechnicalOverviewTab(props: {
  onNavigateIssue: (key: string) => void;
  onShowScores: (data: {
    errorCount: number;
    warningCount: number;
    notesCount: number;
  }) => void;
  dataSummary: ISEOErrorSummaryRes | null;
  dataAbility: IGetInformationAbilityRes | null;
  dataHeading: IGetHeadingErrorRes | null;
  dataImage: IGetImageErrorRes[] | null;
  isLoading?: boolean;
  isLoadingAbility?: boolean;
}) {
  const {
    onNavigateIssue,
    onShowScores,
    dataSummary,
    dataAbility,
    dataHeading,
    isLoading = false,
    isLoadingAbility = false,
    dataImage,
  } = props;

  const dispatch = useDispatch();

  const tempDataIssue: {
    title?: string;
    list?: IIssueListData[];
    children?: { title?: string; list: IIssueListData[] }[];
  }[] = [
      {
        list: [
          {
            title: "Trang lỗi 4xx",
            key: "is_4xx_code",
            type:
              dataSummary?.page_metrics.checks.is_4xx_code === 0
                ? EAuditErrorStatus.Success
                : EAuditErrorStatus.Error,
            issueCount: !!dataSummary?.page_metrics.checks.is_4xx_code
              ? dataSummary?.page_metrics.checks.is_4xx_code
              : undefined,
            infor: "Lỗi 4xx xảy ra khi trên website tồn tại các trang không thể truy cập, gây cản trở việc lập chỉ mục của công cụ tìm kiếm và giảm trải nghiệm người dùng, ảnh hưởng SEO",
            recommendAction: "Kểm tra và sửa các URL bị hỏng, đảm bảo tất cả các liên kết trên web đều có thể truy cập và các trang để tối ưu hóa SEO.",
          },
          {
            title: "Trang lỗi 5xx",
            key: "is_5xx_code",
            type:
              dataSummary?.page_metrics.checks.is_5xx_code === 0
                ? EAuditErrorStatus.Success
                : EAuditErrorStatus.Error,
            issueCount: !!dataSummary?.page_metrics.checks.is_5xx_code
              ? dataSummary?.page_metrics.checks.is_5xx_code
              : undefined,
            infor: "Trang lỗi 5xx là mã trạng thái HTTP cho biết máy chủ web gặp sự cố khi xử lý yêu cầu từ trình duyệt web.",
          },
          {
            title: "301 redirect phiên bản www và non www",
            key: "www_redirect_status_code",
            type:
              dataSummary?.domain_info.www_redirect_status_code === 0
                ? EAuditErrorStatus.Success
                : EAuditErrorStatus.Error,
            infor: "301 redirect phiên bản www và non-www là cách bạn chuyển hướng vĩnh viễn một phiên bản của website sang phiên bản còn lại.",
            recommendAction: `Công cụ tìm kiếm coi "www.example.com" và "example.com" là hai website khác nhau, dẫn đến trùng lặp nội dung. Cần thực hiện 301 redirect, chọn một phiên bản làm chính và chuyển hướng phiên bản còn lại về phiên bản chính.`,
          },
          {
            title: "Trang có tốc độ tải chậm",
            key: "time_to_interactive",
            type:
              dataSummary?.page_metrics.checks.high_loading_time === 0
                ? EAuditErrorStatus.Success
                : EAuditErrorStatus.Error,
            issueCount: !!dataSummary?.page_metrics.checks.high_loading_time
              ? dataSummary?.page_metrics.checks.high_loading_time
              : undefined,
            infor: "Trang có tốc độ tải chậm gây khó chịu cho người dùng và bị xếp hạng thấp trên kết quả tìm kiếm.",
            recommendAction: "Tốc độ tải trang càng nhanh càng tốt, lý tưởng là dưới 2 giây cho máy tính để bàn và dưới 3 giây cho thiết bị di động.",
          },
          {
            title: "Trang có kích thước quá lớn",
            key: "size_greater_than_3mb",
            type:
              dataSummary?.page_metrics.checks.large_page_size === 0
                ? EAuditErrorStatus.Success
                : EAuditErrorStatus.Error,
            issueCount: !!dataSummary?.page_metrics.checks.large_page_size
              ? dataSummary?.page_metrics.checks.large_page_size
              : undefined,
            infor: "Kích thước trang web là tổng dung lượng của tất cả các thành phần trên một trang web (HTML, CSS, JavaScript, hình ảnh...). ",
            recommendAction: "Giữ kích thước trang web càng nhỏ càng tốt, lý tưởng là dưới 3MB.",
          },
          {
            title: "Trang chuyển hướng tạm thời",
            key: "status_code",
            type: EAuditErrorStatus.Success,
            issueCount: 0,
            infor: "Trang chuyển hướng tạm thời là trang web sử dụng mã trạng thái HTTP 302 hoặc 307 để chuyển hướng người dùng từ một URL này sang một URL khác tạm thời.",
            recommendAction: "Chuyển hướng 302 chỉ nên được sử dụng trong thời gian ngắn. Nếu việc chuyển hướng kéo dài, hãy cân nhắc sử dụng chuyển hướng vĩnh viễn (301).",
          },
          {
            title: "Trang chứa chuỗi chuyển hướng",
            key: "redirect_chain",
            type:
              dataSummary?.page_metrics.checks.redirect_chain === 0
                ? EAuditErrorStatus.Success
                : EAuditErrorStatus.Warning,
            issueCount: !!dataSummary?.page_metrics.checks.redirect_chain
              ? dataSummary?.page_metrics.checks.redirect_chain
              : undefined,
            infor:
              "Trang chứa chuỗi chuyển hướng (Redirect Chain) là một tình huống khi một trang web chuyển hướng đến một URL khác, và sau đó URL đó lại chuyển hướng tiếp đến một URL khác tạo thành một chuỗi. ",
            recommendAction:
              "Cách tốt nhất là chuyển hướng URL ban đầu đến URL đích cuối cùng. Nếu không thể tránh khỏi chuỗi chuyển hướng, hãy cố gắng giảm thiểu số lượng chuyển hướng xuống mức tối thiểu.",
          },
          {
            title: "Trang cần nhiều hơn 3 click để truy cập",
            key: "click_depth",
            type: EAuditErrorStatus.Success,
            issueCount: 0,
            infor:
              "Các trang cần nhiều hơn 3 click để truy cập có thể bị công cụ tìm kiếm coi là ít quan trọng và xếp hạng thấp hơn trong kết quả tìm kiếm đồng thời người dùng có thể sẽ gặp khó khăn để truy cập thông tin từ các trang này.",
            recommendAction: "Sắp xếp các mục menu theo thứ tự logic, dễ hiểu hoặc sử dụng liên kết nội bộ thông minh và breadcrumb để người dùng khám phá nội dung dễ dàng hơn.",
          },
          {
            title: "Trang có đường dẫn chứa tham số động",
            key: "seo_friendly_url_dynamic_check",
            type:
              dataSummary?.page_metrics.checks.seo_friendly_url_dynamic_check ===
                0
                ? EAuditErrorStatus.Success
                : EAuditErrorStatus.Warning,
            issueCount: !!dataSummary?.page_metrics.checks
              .seo_friendly_url_dynamic_check
              ? dataSummary?.page_metrics.checks.seo_friendly_url_dynamic_check
              : undefined,
            infor: "Trang có đường dẫn chứa tham số động là những trang có URL chứa các tham số được thay đổi tùy theo ngữ cảnh hoặc hành động của người dùng. Các tham số này thường xuất hiện sau dấu chấm hỏi (?) trong URL và có thể thay đổi dựa trên các giá trị khác nhau.",
            recommendAction: "Sử dụng thẻ canonical để chỉ định phiên bản URL chính thức cho các trang có nội dung trùng lặp do tham số động. ",
          },
          {
            title: "Trang có đường dẫn chứa kí tự đặc biệt",
            key: "seo_friendly_url_characters_check",
            type:
              dataSummary?.page_metrics.checks
                .seo_friendly_url_characters_check === 0
                ? EAuditErrorStatus.Success
                : EAuditErrorStatus.Warning,
            issueCount: !!dataSummary?.page_metrics.checks
              .seo_friendly_url_characters_check
              ? dataSummary?.page_metrics.checks.seo_friendly_url_characters_check
              : undefined,
            infor: "Trang có đường dẫn chứa ký tự đặc biệt là những trang web có URL bao gồm các ký tự không phải chữ cái hoặc số thông thường.  Những ký tự này có thể bao gồm dấu cách, dấu chấm phẩy, dấu ngoặc kép, dấu chấm than, v.v.",
            recommendAction: "Cách tốt nhất là loại bỏ hoàn toàn các ký tự đặc biệt khỏi URL. Thay vì sử dụng dấu cách, hãy sử dụng dấu gạch ngang (-).",
          },
          {
            title: "Trang có đường dẫn quá dài",
            key: "seo_friendly_url_relative_length_check",
            type:
              dataSummary?.page_metrics.checks
                .seo_friendly_url_relative_length_check === 0
                ? EAuditErrorStatus.Success
                : EAuditErrorStatus.Warning,
            issueCount: !!dataSummary?.page_metrics.checks
              .seo_friendly_url_relative_length_check
              ? dataSummary?.page_metrics.checks
                .seo_friendly_url_relative_length_check
              : undefined,
            infor: "Trang có đường dẫn quá dài là trang web có URL dài hơn mức hợp lý, có thể chứa nhiều từ khóa, tham số động hoặc các yếu tố không cần thiết. ",
            recommendAction: "Sử dụng URL ngắn gọn và dễ hiểu, tránh việc đưa quá nhiều thông tin vào URL.",
          },
          {
            title: "Trang có số lượng chữ thấp",
            key: "low_character_count",
            type:
              dataSummary?.page_metrics.checks.low_character_count === 0
                ? EAuditErrorStatus.Success
                : EAuditErrorStatus.Warning,
            issueCount: !!dataSummary?.page_metrics.checks.low_character_count
              ? dataSummary?.page_metrics.checks.low_character_count
              : undefined,
            infor: "Trang có số lượng chữ thấp là những trang web chứa rất ít nội dung văn bản, khiến trang có thể bị coi là cung cấp ít giá trị cho người đọc và bị đánh giá thấp trên trang kết quả của công cụ tìm kiếm.",
            recommendAction: "Bổ sung thêm nội dung chất lượng, cung cấp giá trị cho người đọc. Nội dung trên trang nên có ít nhất từ 300 từ và dài khoảng 1.000 - 2.000 cho nội dung blog chuyên sâu.",
          },
          {
            title: "Trang chưa khai báo loại tài liệu",
            key: "has_html_doctype",
            type:
              dataSummary?.page_metrics.checks.no_doctype === 0
                ? EAuditErrorStatus.Success
                : EAuditErrorStatus.Warning,
            issueCount: !!dataSummary?.page_metrics.checks.no_doctype
              ? dataSummary?.page_metrics.checks.no_doctype
              : undefined,
            infor: "Trang chưa khai báo loại tài liệu là trang web không khai báo Doctype ở đầu mã nguồn HTML. Điều này khiến trình duyệt không biết phiên bản HTML nào đang được sử dụng dẫn đến lỗi hiển thị hoặc hiển thị không đúng.",
            recommendAction: "Thêm thẻ <!DOCTYPE html> vào đầu mỗi trang web để đảm bảo trang web được hiển thị chính xác và tuân thủ các tiêu chuẩn web hiện đại.",
          },
          {
            title: "Trang gắn thẻ Noindex đặt trong sitemap",
            type: EAuditErrorStatus.Success,
            issueCount: 0,
            infor: `Một trang web chứa thẻ <"noindex"> trong mã HTML (thẻ thông báo cho công cụ tìm kiếm không index trang) khi được liệt kê trong Sitemap (Nơi liệt kê các trang cần được index) sẽ gây lãng phí tài nguyên lập chỉ mục.`,
            recommendAction: `Xóa các trang có thẻ "noindex" khỏi sitemap để giúp giảm thiểu sự lãng phí tài nguyên và giúp công cụ tìm kiếm hiểu rõ hơn về cấu trúc của trang web.`,
          },
          {
            title: "Trang 404 đặt trong sitemap",
            type: EAuditErrorStatus.Success,
            issueCount: 0,
            infor: "Trang 404 đặt trong sitemap",
            recommendAction: "Trang 404 đặt trong sitemap",
          },
          {
            title: "Trang gắn sai thẻ canonical đặt trong sitemap",
            type: EAuditErrorStatus.Success,
            issueCount: 0,
            infor: "Trang gắn sai thẻ canonical đặt trong sitemap",
            recommendAction: "Trang gắn sai thẻ canonical đặt trong sitemap",
          },
          {
            title: "Trang không được thu thập dữ liệu",
            type:
              dataSummary?.page_metrics.non_indexable === 0
                ? EAuditErrorStatus.Success
                : EAuditErrorStatus.Infor,
            issueCount: !!dataSummary?.page_metrics.non_indexable
              ? dataSummary?.page_metrics.non_indexable
              : undefined,
            infor: "Trang không được thu thập dữ liệu là các trang web mà công cụ tìm kiếm không thể truy cập và lấy thông tin. Các trang không được thu thập dữ liệu sẽ không xuất hiện trong trang kết quả của công cụ tìm kiếm.",
            recommendAction: `Trang không được thu thập dữ liệu có thể do bị chặn qua tệp robots.txt, thẻ "noindex", lỗi máy chủ hoặc các vấn đề khác. Kiểm tra và xử lý các nguyên nhân này, đảm bảo các trang quan trọng được thu thập dữ liệu và lập chỉ mục bởi công cụ tìm kiếm.`,
          },
        ],
      },
      {
        title: "Meta tag",
        children: [
          {
            title: "Title",
            list: [
              {
                title: "Trang Thiếu thẻ tiêu đề",
                key: "no_title",
                type:
                  dataSummary?.page_metrics.checks.no_title === 0
                    ? EAuditErrorStatus.Success
                    : EAuditErrorStatus.Error,
                issueCount: !!dataSummary?.page_metrics.checks.no_title
                  ? dataSummary?.page_metrics.checks.no_title
                  : undefined,
                infor:
                  "Trang thiếu thẻ tiêu đề (Title tag) là trang web không có thẻ <title> trong mã HTML hoặc thẻ này bị bỏ trống khiến Google hoặc các công cụ tìm kiếm khó hiểu được nội dung chính của trang.",
                recommendAction: "Thêm một thẻ tiêu đề vào mã HTML của trang web mô tả ngắn gọn và chính xác nội dung của trang.",
              },
              {
                title: "Trang Trùng lặp thẻ tiêu đề",
                key: "duplicate_title",
                type:
                  dataSummary?.page_metrics.duplicate_title === 0
                    ? EAuditErrorStatus.Success
                    : EAuditErrorStatus.Error,
                issueCount: !!dataSummary?.page_metrics.duplicate_title
                  ? dataSummary?.page_metrics.duplicate_title
                  : undefined,
                infor:
                  "Trang trùng lặp thẻ tiêu đề là khi nhiều trang trên website có cùng một thẻ tiêu đề, điều này có thể gây nhầm lẫn cho Google và công cụ tìm kiếm dẫn đến giảm thứ hạng trên trang kết quả tìm kiếm.",
                recommendAction:
                  "Đảm bảo mỗi trang trên website của bạn có một tiêu đề duy nhất, mô tả chính xác nội dung của trang đó. ",
              },
              {
                title: "Trang có nhiều hơn 1 thẻ tiêu đề",
                key: "duplicate_title_tag",
                type:
                  dataSummary?.page_metrics.checks.duplicate_title_tag === 0
                    ? EAuditErrorStatus.Success
                    : EAuditErrorStatus.Error,
                issueCount: !!dataSummary?.page_metrics.checks.duplicate_title_tag
                  ? dataSummary?.page_metrics.checks.duplicate_title_tag
                  : undefined,
                infor: "Trang có nhiều hơn 1 thẻ tiêu đề là khi một trang web chứa nhiều thẻ <title> trong mã HTML của nó. Một trang có nhiều thẻ tiêu đề sẽ khiến công cụ tìm kiếm khó hiểu dẫn đến xếp hạng thấp trên trang kết quả tìm kiếm.",
                recommendAction: "Kiểm tra và loại bỏ các thẻ tiêu đề dư thừa, đảm bảo rằng mỗi trang chỉ có một thẻ tiêu đề duy nhất và chính xác.",
              },
              {
                title: "Trang có tiêu đề quá dài",
                key: "title_too_long",
                type:
                  dataSummary?.page_metrics.checks.title_too_long === 0
                    ? EAuditErrorStatus.Success
                    : EAuditErrorStatus.Warning,
                issueCount: !!dataSummary?.page_metrics.checks.title_too_long
                  ? dataSummary?.page_metrics.checks.title_too_long
                  : undefined,
                infor:
                  "Trang có tiêu đề quá dài là khi thẻ <title> của trang vượt quá chiều dài tối ưu số lượng ký tự khuyến nghị, thường là khoảng 60 ký tự. Tiêu đề dài sẽ bị cắt ngắn trong các kết quả tìm kiếm khiến người dùng không thấy đầy đủ thông tin về trang.",
                recommendAction:
                  "Đảm bảo rằng thẻ tiêu đề của mỗi trang có chiều dài từ 50-60 ký tự để tiêu đề được hiển thị đầy đủ trong kết quả tìm kiếm.",
              },
              {
                title: "Trang có tiêu đề quá ngắn",
                key: "title_too_short",
                type:
                  dataSummary?.page_metrics.checks.title_too_short === 0
                    ? EAuditErrorStatus.Success
                    : EAuditErrorStatus.Warning,
                issueCount: !!dataSummary?.page_metrics.checks.title_too_short
                  ? dataSummary?.page_metrics.checks.title_too_short
                  : undefined,
                infor: "Trang có tiêu đề quá ngắn là khi thẻ <title> của trang chứa quá ít kí tự. Một tiêu đề quá ngắn có thể không cung cấp đầy đủ nội dung của trang đồng thời hạn chế khả năng tối ưu hiện thị trên trang kết quả tìm kiếm. ",
                recommendAction: "Đảm bảo rằng thẻ tiêu đề của mỗi trang có chiều dài từ 50-60 ký tự và nêu nội dung chính của bài viết.",
              },
            ],
          },
          {
            title: "H1",
            list: [
              {
                title: "Trang Thiếu thẻ h1",
                key: "no_title",
                type:
                  dataSummary?.page_metrics.checks.no_title === 0
                    ? EAuditErrorStatus.Success
                    : EAuditErrorStatus.Error,
                issueCount: !!dataSummary?.page_metrics.checks.no_title
                  ? dataSummary?.page_metrics.checks.no_title
                  : undefined,
                infor: "Trang Thiếu thẻ h1",
                recommendAction: "Trang Thiếu thẻ h1",
              },
              {
                title: "Trang trùng lặp thẻ Heading 1",
                key: "duplicate_h1",
                type:
                  !dataHeading?.pageHeadingDuplicate ||
                    dataHeading?.pageHeadingDuplicate?.length === 0
                    ? EAuditErrorStatus.Success
                    : EAuditErrorStatus.Warning,
                issueCount: !!dataHeading?.pageHeadingDuplicate?.length
                  ? dataHeading?.pageHeadingDuplicate?.length
                  : undefined,
                infor: "Trang trùng lặp thẻ Heading 1",
                recommendAction: "Trang trùng lặp thẻ Heading 1",
              },
              {
                title: "Trang có nhiều hơn 1 thẻ Heading 1",
                key: "many_h1",
                type:
                  !dataHeading?.pagesMoreThanHeading ||
                    dataHeading?.pagesMoreThanHeading?.length === 0
                    ? EAuditErrorStatus.Success
                    : EAuditErrorStatus.Warning,
                issueCount: !!dataHeading?.pagesMoreThanHeading?.length
                  ? dataHeading?.pagesMoreThanHeading?.length
                  : undefined,
                infor:
                  "Thẻ H1 giúp xác định chủ đề chính của trang và tạo ra một cấu trúc nội dung rõ ràng, dễ hiểu cho người dùng và các công cụ tìm kiếm. Chỉ nên có một thẻ H1 duy nhất cho mỗi trang. Việc có nhiều thẻ H1 có thể làm cho cấu trúc nội dung trở nên lộn xộn và khó hiểu.",
                recommendAction: (
                  <ul>
                    <li>
                      Xác định thẻ H1 chính: Chọn một thẻ H1 duy nhất để đại diện
                      cho nội dung chính của trang.
                    </li>
                    <li>
                      Sử dụng các thẻ H2, H3, v.v. để đánh dấu các tiêu đề phụ và
                      tạo ra một cấu trúc nội dung phân cấp.
                    </li>
                  </ul>
                ),
              },
              {
                title: "Trang có thẻ Heading 1 quá dài",
                key: "h1_long",
                type:
                  !dataHeading?.pageHeadingToLong ||
                    dataHeading?.pageHeadingToLong?.length === 0
                    ? EAuditErrorStatus.Success
                    : EAuditErrorStatus.Warning,
                issueCount: !!dataHeading?.pageHeadingToLong?.length
                  ? dataHeading?.pageHeadingToLong?.length
                  : undefined,
                infor: "Trang có thẻ Heading 1 quá dài",
                recommendAction: "Trang có thẻ Heading 1 quá dài",
              },
              {
                title: "Trang có thẻ  tiêu đề và Heading 1 trùng lặp",
                key: "h1_title_duplicate",
                type:
                  !dataHeading?.pageHeadingAndTileDuplicate ||
                    dataHeading?.pageHeadingAndTileDuplicate?.length === 0
                    ? EAuditErrorStatus.Success
                    : EAuditErrorStatus.Warning,
                issueCount: !!dataHeading?.pageHeadingAndTileDuplicate?.length
                  ? dataHeading?.pageHeadingAndTileDuplicate?.length
                  : undefined,
                infor: "Trang có thẻ  tiêu đề và Heading 1 trùng lặp",
                recommendAction: "Trang có thẻ  tiêu đề và Heading 1 trùng lặp",
              },
            ],
          },
          {
            title: "Meta Description",
            list: [
              {
                title: "Trang Trùng lặp thẻ Meta description",
                key: "duplicate_description",
                type:
                  dataSummary?.page_metrics.duplicate_description === 0
                    ? EAuditErrorStatus.Success
                    : EAuditErrorStatus.Error,
                issueCount: !!dataSummary?.page_metrics.duplicate_description
                  ? dataSummary?.page_metrics.duplicate_description
                  : undefined,
                infor: "Khi website có nhiều trang với các thẻ meta description giống nhau sẽ khiến công cụ tìm kiếm gặp khó khăn trong việc xác định nội dung chính của từng trang.",
                recommendAction: "Mỗi trang trên website cần có một thẻ meta description riêng biệt và phản ánh chính xác nội dung của trang đó. Đảm bảo  mỗi mô tả đều nêu rõ chủ đề của trang và chứa từ khóa mục tiêu mong muốn xếp hạng nếu có.",
              },
              {
                title: "Trang thiếu thẻ Meta description",
                key: "no_description",
                type:
                  dataSummary?.page_metrics.checks.no_description === 0
                    ? EAuditErrorStatus.Success
                    : EAuditErrorStatus.Warning,
                issueCount: !!dataSummary?.page_metrics.checks.no_description
                  ? dataSummary?.page_metrics.checks.no_description
                  : undefined,
                infor: "Khi trang thiếu thẻ Meta description, công cụ tìm kiếm sẽ trích xuất từ nội dung trang một đoạn văn bản ngẫu nhiên phù hợp với từ khóa tìm kiếm. Tuy nhiên, đoạn văn này có thể không rõ ràng hoặc không hấp dẫn.",
                recommendAction: "Bổ sung meta description chứa từ khóa chính và được viết hấp dẫn, lôi cuốn để thu hút người dùng click vào.",
              },
              {
                title: "Trang có thẻ meta description quá dài",
                key: "meta.description_length",
                // type: EAuditErrorStatus.Warning,
                type: EAuditErrorStatus.Success,
                issueCount: 0,
                infor: "Thẻ meta description quá dài là khi meta description vượt quá độ dài mà các công cụ tìm kiếm như Google có thể hiển thị đầy đủ trong kết quả tìm kiếm.",
                recommendAction: "Đảm bảo độ dài meta description chỉ trong khoảng từ 150-160 ký tự.",
              },
            ],
          },
          {
            title: "Canonical",
            list: [
              {
                title: "Trang thiếu thẻ canonical",
                // type: EAuditErrorStatus.Error,
                type: EAuditErrorStatus.Success,
                issueCount: 0,
                infor: "Trang thiếu thẻ canonical",
                recommendAction: "Trang thiếu thẻ canonical",
              },
              {
                title: "Trang lỗi thẻ canonical",
                key: "canonical_to_broken",
                type:
                  dataSummary?.page_metrics.checks.canonical_to_broken === 0
                    ? EAuditErrorStatus.Success
                    : EAuditErrorStatus.Error,
                issueCount: !!dataSummary?.page_metrics.checks.canonical_to_broken
                  ? dataSummary?.page_metrics.checks.canonical_to_broken
                  : undefined,
                infor: "Trang lỗi thẻ canonical xảy ra khi thẻ canonical trỏ đến một trang bị lỗi (mã 4xx hoặc 5xx). Điều này gây khó khăn cho công cụ tìm kiếm trong việc thu thập dữ liệu và xếp hạng trang đích mong muốn. ",
                recommendAction: "Đảm bảo thẻ canonical trỏ đến URL chính xác và hoạt động bình thường (mã trả về 200).",
              },
              {
                title: "Trang có thẻ canonical trả về mã 3xx",
                key: "canonical_to_redirect",
                type:
                  dataSummary?.page_metrics.checks.canonical_to_redirect === 0
                    ? EAuditErrorStatus.Success
                    : EAuditErrorStatus.Error,
                issueCount: !!dataSummary?.page_metrics.checks
                  .canonical_to_redirect
                  ? dataSummary?.page_metrics.checks.canonical_to_redirect
                  : undefined,
                infor: "Trang có thẻ canonical trả về mã 3xx khiến các công cụ tìm kiếm bị chuyển hướng không cần thiết. Điều này làm lãng phí ngân sách thu thập dữ liệu và giảm khả năng xếp hạng trang.",
                recommendAction: "Đảm bảo thẻ canonical trỏ trực tiếp đến URL cuối cùng không có chuyển hướng (URL trả về mã 200).",
              },
            ],
          },
        ],
      },
      {
        title: "Sitemap",
        children: [
          {
            list: [
              {
                title: "Có XML sitemap",
                key: "sitemap",
                type: dataSummary?.domain_info.checks.sitemap
                  ? EAuditErrorStatus.Success
                  : EAuditErrorStatus.Error,
                infor: "XML sitemap là một tệp tin định dạng XML chứa danh sách các URL của website, giúp các công cụ tìm kiếm như Google dễ dàng khám phá, hiểu cấu trúc và lập chỉ mục (index) tất cả các trang trên website.",
                recommendAction: "Tạo và cập nhật sitemap đầy đủ, gửi sitemap lên Google Search Console để cải thiện khả năng lập chỉ mục và tăng khả năng hiển thị của trang web.",
              },
              {
                title: "XML sitemap được bỏ vào Robot.txt",
                type: EAuditErrorStatus.Success,
                infor: "Đặt XML sitemap vào robots.txt là một cách giúp công cụ tìm kiếm dễ dàng tìm thấy và index website của bạn.",
                recommendAction: "Để thêm XML sitemap vào robots.txt, bạn chỉ cần sử dụng lệnh Sitemap: <URL của sitemap> trong tệp robots.txt",
              },
            ],
          },
        ],
      },
      {
        title: "Robot",
        children: [
          {
            list: [
              {
                title: "Cài đặt file Robot.txt",
                key: "robots_txt",
                type: dataSummary?.domain_info.checks.robots_txt
                  ? EAuditErrorStatus.Success
                  : EAuditErrorStatus.Error,
                infor: "Cài đặt file robots.txt giúp tiết kiệm ngân sách thu thập dữ liệu của công cụ tìm kiếm, tăng khả năng lập chỉ mục các trang quan trọng và bảo vệ các trang không công khai khỏi bị lập chỉ mục.",
                recommendAction: "Tạo và tải tệp robots.txt lên thư mục gốc của website, trong đó thêm các chỉ thị như User-agent, Disallow, Allow, và Sitemap để chỉ rõ các quy tắc mà bạn muốn áp dụng cho các công cụ tìm kiếm.",
              },
            ],
          },
        ],
      },
      {
        title: "Schema",
        children: [
          {
            list: [
              {
                title: "Trang có lỗi dữ liệu có cấu trúc",
                // type: EAuditErrorStatus.Error,
                type: EAuditErrorStatus.Success,
                issueCount: 0,
                infor: "Trang có lỗi dữ liệu có cấu trúc",
                recommendAction: "Trang có lỗi dữ liệu có cấu trúc",
              },
              {
                title: "Có schema Organization",
                // type: EAuditErrorStatus.Infor,
                type: EAuditErrorStatus.Success,
                issueCount: 0,
                infor: "Có schema Organization",
                recommendAction: "Có schema Organization",
              },
              {
                title: "Có Schema Local Business",
                // type: EAuditErrorStatus.Infor,
                type: EAuditErrorStatus.Success,
                issueCount: 0,
                infor: "Có Schema Local Business",
                recommendAction: "Có Schema Local Business",
              },
            ],
          },
        ],
      },
      {
        title: "SEO International",
        children: [
          {
            list: [
              {
                title: "Trang sử dụng sai thẻ Hreflang",
                // type: EAuditErrorStatus.Error,
                type: EAuditErrorStatus.Success,
                issueCount: 0,
                infor: "Trang sử dụng sai thẻ Hreflang",
                recommendAction: "Trang sử dụng sai thẻ Hreflang",
              },
              {
                title: "Trang sử dụng ngôn ngữ hỗn hợp",
                // type: EAuditErrorStatus.Error,
                type: EAuditErrorStatus.Success,
                issueCount: 0,
                infor: "Trang sử dụng ngôn ngữ hỗn hợp",
                recommendAction: "Trang sử dụng ngôn ngữ hỗn hợp",
              },
              {
                title: "Trang sử dụng thẻ Href lang sai vị trí",
                // type: EAuditErrorStatus.Warning,
                type: EAuditErrorStatus.Success,
                issueCount: 0,
                infor: "Trang sử dụng thẻ Href lang sai vị trí",
                recommendAction: "Trang sử dụng thẻ Href lang sai vị trí",
              },
            ],
          },
        ],
      },
      {
        title: "Chứng chỉ https",
        children: [
          {
            list: [
              {
                title: "Có chứng chỉ HTTPs",
                key: "valid_certificate",
                type: dataSummary?.domain_info.ssl_info.valid_certificate
                  ? EAuditErrorStatus.Success
                  : EAuditErrorStatus.Error,
                infor: "Có chứng chỉ HTTPs",
                recommendAction: "Có chứng chỉ HTTPs",
              },
              {
                title:
                  "301 redirect phiên bản Http sang https Có chứng chỉ HTTPs ",
                key: "test_https_redirect",
                type: dataSummary?.domain_info.checks.test_https_redirect
                  ? EAuditErrorStatus.Success
                  : EAuditErrorStatus.Error,
                infor:
                  "Chuyển hướng 301 từ HTTP sang HTTPS giúp chuyển đổi website của bạn sang một kết nối an toàn và bảo mật hơn.",
                recommendAction:
                  "Để thực hiện chuyển hướng 301 từ HTTP sang HTTPS, bạn cần phải cài đặt chứng chỉ SSL/TLS trên website và cấu hình máy chủ của bạn để thực hiện chuyển hướng.",
              },
              {
                title: "Trang có nội dung hỗn hợp",
                key: "page_to_scheme",
                // type: EAuditErrorStatus.Error,
                type: EAuditErrorStatus.Success,
                issueCount: 0,
                infor: "Trang có nội dung hỗn hợp là trang web được tải qua kết nối HTTPS bảo mật nhưng lại chứa một số thành phần được tải qua kết nối HTTP không bảo mật.",
                recommendAction: "Đảm bảo tất cả các thành phần trên trang web được tải qua HTTPS để bảo vệ thông tin người dùng và duy trì uy tín cho website.",
              },
              {
                title: "Trang có liên kết trỏ đến phiên bản Http",
                key: "https_to_http_links",
                type:
                  dataSummary?.page_metrics.checks.https_to_http_links === 0
                    ? EAuditErrorStatus.Success
                    : EAuditErrorStatus.Error,
                issueCount: !!dataSummary?.page_metrics.checks.https_to_http_links
                  ? dataSummary?.page_metrics.checks.https_to_http_links
                  : undefined,
                infor: "Trang có liên kết trỏ đến phiên bản HTTP là khi trên website của bạn có các liên kết (URL) trỏ đến các trang sử dụng giao thức HTTP thay vì HTTPS.",
                recommendAction: "Tìm và thay thế các liên kết HTTP trong nội dung trang web của bạn (bao gồm cả các liên kết trong HTML, CSS, JavaScript) bằng liên kết HTTPS.",
              },
              {
                title: "Trang có phiên bản HTTP đặt trong sitemap.xml",
                // type: EAuditErrorStatus.Warning,
                type: EAuditErrorStatus.Success,
                issueCount: 0,
                infor: "Trang có phiên bản HTTP được đặt trong sitemap.xml",
                recommendAction:
                  "Trang có phiên bản HTTP được đặt trong sitemap.xml",
              },
            ],
          },
        ],
      },
      {
        title: "Internal link",
        children: [
          {
            list: [
              {
                title: "Trang có liên kết nội bộ tới trang 4xx",
                key: "broken_links",
                type:
                  dataSummary?.page_metrics.broken_links === 0
                    ? EAuditErrorStatus.Success
                    : EAuditErrorStatus.Error,
                issueCount: !!dataSummary?.page_metrics.broken_links
                  ? dataSummary?.page_metrics.broken_links
                  : undefined,
                infor: `Khi trang có liên kết nội bộ tới trang 4xx (trang lỗi) có thể khiến người dùng thất vọng do không tìm được nội dung mong muốn. Đồng thời các công cụ tìm kiếm đánh giá thấp chất lượng của các trang web chứa liên kết 4xx, ảnh hưởng xấu đến thứ hạng trang web.`,
                recommendAction:
                  "Xem xét và sửa chữa hoặc loại bỏ các liên kết hỏng, đảm bảo mọi liên kết nội bộ dẫn đến trang hoạt động để cải thiện trải nghiệm và hiệu suất SEO.",
              },
              {
                title: "Trang chứa liên kết nội bộ tới trang 3xx",
                key: "has_links_to_redirects",
                type:
                  dataSummary?.page_metrics.checks.has_links_to_redirects === 0
                    ? EAuditErrorStatus.Success
                    : EAuditErrorStatus.Warning,
                issueCount: !!dataSummary?.page_metrics.checks
                  .has_links_to_redirects
                  ? dataSummary?.page_metrics.checks.has_links_to_redirects
                  : undefined,
                infor: "Trang chứa liên kết nội bộ tới trang 3xx",
                recommendAction: "Trang chứa liên kết nội bộ tới trang 3xx",
              },
              {
                title: "Trang chứa liên kết nội bộ đi gắn thẻ Nofollow",
                key: "nofollow",
                // type: EAuditErrorStatus.Warning,
                type: EAuditErrorStatus.Success,
                issueCount: 0,
                infor: "Khi một trang chứa các liên kết nội bộ nhưng được gắn thẻ Nofollow nghĩa là công cụ tìm kiếm sẽ không truyền sức mạnh từ trang đó đến trang đích của liên kết.",
                recommendAction: "Chỉ sử dụng thẻ nofollow khi liên kết đến các trang không quan trọng (ví dụ: trang đăng nhập, trang điều khoản sử dụng) hoặc các trang không mong muốn công cụ tìm kiếm thu thập dữ liệu.",
              },
              {
                title: "Trang có quá nhiều liên kết nội bộ đến trang khác",
                // type: EAuditErrorStatus.Warning,
                type: EAuditErrorStatus.Success,
                issueCount: 0,
                infor: "Trang có quá nhiều liên kết nội bộ đến trang khác là khi một trang web chứa quá nhiều liên kết trỏ đến các trang khác trên cùng một website.",
                recommendAction:
                  "Giới hạn số lượng liên kết nội bộ trong mỗi trang không quá 100",
              },
              {
                title: "Trang chỉ có duy nhất 1 liên kết nội bộ trỏ đến",
                // type: EAuditErrorStatus.Infor,
                type: EAuditErrorStatus.Success,
                issueCount: 0,
                infor: "Trang chỉ có duy nhất 1 liên kết nội bộ trỏ đến",
                recommendAction:
                  "Trang chỉ có duy nhất 1 liên kết nội bộ trỏ đến",
              },
              {
                title:
                  "Trang có liên kết nội bộ trỏ tới với anchortext không đa dạng",
                // type: EAuditErrorStatus.Infor,
                type: EAuditErrorStatus.Success,
                issueCount: 0,
                infor:
                  "Trang có liên kết nội bộ trỏ tới với anchortext không đa dạng",
                recommendAction:
                  "Trang có liên kết nội bộ trỏ tới với anchortext không đa dạng",
              },
              {
                title: "Trang không có liên kết nội bộ trỏ đến",
                key: "is_orphan_page",
                type:
                  dataSummary?.page_metrics.checks.is_orphan_page === 0
                    ? EAuditErrorStatus.Success
                    : EAuditErrorStatus.Warning,
                issueCount: !!dataSummary?.page_metrics.checks.is_orphan_page
                  ? dataSummary?.page_metrics.checks.is_orphan_page
                  : undefined,
                infor: "Trang không có liên kết nội bộ trỏ đến là khi một trang không có bất kỳ liên kết nào từ các trang khác trên website trỏ đến nó. Điều này làm giảm khả năng thu thập dữ liệu trên trang cũng như giảm thứ hạng SEO của trang.",
                recommendAction: "Thêm các liên kết nội bộ từ các trang liên quan khác để tăng cường cấu trúc liên kết, cải thiện khả năng lập chỉ mục và tối ưu hóa thứ hạng SEO.",
              },
            ],
          },
        ],
      },
      {
        title: "Hình ảnh",
        children: [
          {
            list: [
              {
                title: "Hình ảnh thiếu thẻ ALT",
                key: "alternative_text",
                type:
                  !dataImage || dataImage?.length === 0
                    ? EAuditErrorStatus.Success
                    : EAuditErrorStatus.Warning,
                issueCount:
                  !!dataImage && dataImage?.length > 0
                    ? dataImage?.length
                    : undefined,
                infor: "Thẻ Alt (Alternative text) là văn bản mô tả nội dung hình ảnh trên trang web. Hình ảnh thiếu thẻ ALT là các ảnh có thẻ ALT text để trống, điều này khiến công cụ tìm kiếm khó hiểu nội dung hình ảnh, dẫn đến giảm khả năng tiếp cận và tối ưu SEO.",
                recommendAction: "Bổ sung thẻ ALT riêng và mô tả ngắn gọn, chính xác nội dung của từng hình ảnh. Thêm từ khóa chính vào thẻ Alt của ảnh có nội dung thích hợp giúp tối ưu điểm SEO.",
              },
              {
                title: "Hình ảnh lớn hơn 100kb",
                key: "image_size",
                // type: EAuditErrorStatus.Warning,
                type: EAuditErrorStatus.Success,
                issueCount: 0,
                infor: "Hình ảnh có kích thước lớn (trên 100KB) có thể làm tăng thời gian tải trang, đặc biệt nếu trang web có nhiều hình ảnh tương tự.",
                recommendAction: "Nén hình ảnh bằng cách sử dụng các công cụ trực tuyến như TinyPNG, JPEG-Optimizer, hoặc ImageOptim (trên Mac) để giảm kích thước mà không ảnh hưởng đến chất lượng hình ảnh.",
              },
            ],
          },
        ],
      },
    ];

  let errorCount = 0;
  let warningCount = 0;
  let notesCount = 0;

  const errorList = tempDataIssue.map(issue => {
    const keyList: string[] = [];

    if (!!issue.children && issue.children.length > 0) {
      issue.children?.map(child => {
        child.list?.map(itemChild => {
          if (itemChild.type !== EAuditErrorStatus.Success) {
            if (!!itemChild?.key) {
              keyList.push(itemChild.key);
            }
            if (itemChild.type === EAuditErrorStatus.Error) {
              errorCount = errorCount + (itemChild.issueCount || 0);
            } else if (itemChild.type === EAuditErrorStatus.Warning) {
              warningCount = warningCount + (itemChild.issueCount || 0);
            } else if (itemChild.type === EAuditErrorStatus.Infor) {
              notesCount = notesCount + (itemChild.issueCount || 0);
            }
          }
        });
      });
    } else {
      issue.list?.map(item => {
        if (item.type !== EAuditErrorStatus.Success) {
          if (!!item?.key) {
            keyList.push(item.key);
          }
          if (item.type === EAuditErrorStatus.Error) {
            errorCount = errorCount + (item.issueCount || 0);
          } else if (item.type === EAuditErrorStatus.Warning) {
            warningCount = warningCount + (item.issueCount || 0);
          } else if (item.type === EAuditErrorStatus.Infor) {
            notesCount = notesCount + (item.issueCount || 0);
          }
        }
      });
    }

    return keyList;
  });

  useEffect(() => {
    dispatch(setErrorListState(errorList.flat(1)));
  }, [errorList]);

  useEffect(() => {
    onShowScores({ errorCount, warningCount, notesCount });
  }, [errorCount, warningCount, notesCount]);

  const chartData: TechnicalOverviewChartBoxProps[] = [
    {
      title: "Khả năng lập chỉ mục",
      description:
        "Là khả năng Robot của các công cụ tìm kiếm thu thập, và xử lý thông tin trên website.",
      dataChart: !!dataAbility?.pagesIndexAbility
        ? [
          {
            name: "Index",
            value: dataAbility?.pagesIndexAbility.indexTotalPages || 0,
          },
          {
            name: "No Index",
            value: dataAbility?.pagesIndexAbility.nonIndexTotalPages || 0,
          },
        ]
        : null,
    },
    {
      title: "Page crawl depth",
      description:
        "Đề cập đến số lượng click chuột cần thiết từ trang chủ của một website để đến được một trang cụ thể.",
      infor: "Page crawl depth càng thấp càng tốt, lý tưởng là trong khoảng từ 0 đến 3.",
      dataChart: !!dataAbility?.crawlDepth?.pagesCrawlDepth
        ? [
          {
            name: "1 click",
            value: dataAbility?.crawlDepth?.pagesCrawlDepth?.firstClicks || 0,
          },
          {
            name: "2 click",
            value:
              dataAbility?.crawlDepth?.pagesCrawlDepth?.secondClicks || 0,
          },
          {
            name: "3 click",
            value: dataAbility?.crawlDepth?.pagesCrawlDepth?.thirdClick || 0,
          },
          {
            name: "4 click",
            value: dataAbility?.crawlDepth?.pagesCrawlDepth?.fourthClick || 0,
          },
          {
            name: ">= 5 click",
            value: dataAbility?.crawlDepth?.pagesCrawlDepth?.fifthClick || 0,
          },
        ]
        : null,
      dataNavigate: [
        {
          keyFilter: "1 click",
          navigateFilter: queryString.stringify({
            tab: "2",
            mFilter: "clickdepth",
            sFilter: "depth1",
          }),
        },
        {
          keyFilter: "2 click",
          navigateFilter: queryString.stringify({
            tab: "2",
            mFilter: "clickdepth",
            sFilter: "depth2",
          }),
        },
        {
          keyFilter: "3 click",
          navigateFilter: queryString.stringify({
            tab: "2",
            mFilter: "clickdepth",
            sFilter: "depth3",
          }),
        },
        {
          keyFilter: "4 click",
          navigateFilter: queryString.stringify({
            tab: "2",
            mFilter: "clickdepth",
            sFilter: "depth4",
          }),
        },
        {
          keyFilter: ">= 5 click",
          navigateFilter: queryString.stringify({
            tab: "2",
            mFilter: "clickdepth",
            sFilter: "depth5+",
          }),
        },
      ],
    },
    {
      title: "HTTPS Status code",
      description:
        "Một mã số gồm ba chữ số mà máy chủ web gửi về trình duyệt web để phản hồi lại yêu cầu của trình duyệt, cho biết trạng thái của yêu cầu đó.",
      dataChart: !!dataAbility?.crawlDepth?.pagesStatusCodes
        ? [
          {
            name: "2xx",
            value:
              dataAbility?.crawlDepth?.pagesStatusCodes.statusCode2X || 0,
          },
          {
            name: "3xx",
            value:
              dataAbility?.crawlDepth?.pagesStatusCodes.statusCode3X || 0,
          },
          {
            name: "4xx",
            value:
              dataAbility?.crawlDepth?.pagesStatusCodes.statusCode4X || 0,
          },
          {
            name: "5xx",
            value:
              dataAbility?.crawlDepth?.pagesStatusCodes.statusCode5X || 0,
          },
        ]
        : null,
      dataNavigate: [
        {
          keyFilter: "2xx",
          navigateFilter: queryString.stringify({
            tab: "2",
            mFilter: "statuscode",
            sFilter: "code200",
          }),
        },
        {
          keyFilter: "3xx",
          navigateFilter: queryString.stringify({
            tab: "2",
            mFilter: "statuscode",
            sFilter: ["code301", "code302"],
          }),
        },
        {
          keyFilter: "4xx",
          navigateFilter: queryString.stringify({
            tab: "2",
            mFilter: "statuscode",
            sFilter: ["code401", "code403", "code404"],
          }),
        },
        {
          keyFilter: "5xx",
          navigateFilter: queryString.stringify({
            tab: "2",
            mFilter: "statuscode",
            sFilter: ["code501", "code502", "code505"],
          }),
        },
      ],
    },
  ];

  return (
    <TechnicalOverviewTabWrapper>
      <ul className="chart-list">
        {chartData.map(temp => (
          <li key={temp.title}>
            <TechnicalOverviewChartBox
              title={temp.title}
              description={temp.description}
              infor={temp.infor}
              dataChart={temp.dataChart}
              isLoading={isLoadingAbility}
              dataNavigate={temp.dataNavigate}
            />
          </li>
        ))}
      </ul>
      <div className="overview-list">
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            {!!dataSummary ? (
              <TechnicalOverviewIssueBox
                data={tempDataIssue}
                onClickIssue={(key: string) => {
                  if (!!key) {
                    onNavigateIssue(key);
                  }
                }}
              />
            ) : (
              <div className="overview-nodata flex-column-center">
                <NoDataBox title="No data" hasImage />
              </div>
            )}
          </>
        )}
      </div>
    </TechnicalOverviewTabWrapper>
  );
}

interface TechnicalOverviewBoxProps extends TechnicalOverviewChartBoxProps {
  isLoading: boolean;
}

interface TechnicalOverviewChartBoxProps {
  title: string;
  description: string;
  infor?: string;
  dataChart: IPieChartValue[] | null;
  dataNavigate?: { keyFilter: string; navigateFilter: string }[];
}

const TechnicalOverviewChartBox = (props: TechnicalOverviewBoxProps) => {
  const { title, description, infor, dataChart, isLoading, dataNavigate } = props;

  const dataReport = useSelector<IStateApp, IGetReportsRes>(
    appState => appState.project?.dataReport
  );
  return (
    <TechnicalOverviewChartWrapper>
      <OverviewCommonCaption
        className="overview-chart-caption"
        width="420px"
        caption={title}
        icon={IconChart}
        tooltip={infor}
        description={description}
      />
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          {!!dataChart ? (
            <div className="chart-wrapper">
              <PieChartDonutShape
                data={dataChart}
                onClickNavigate={v => {
                  if (!!dataNavigate && dataNavigate.length > 0) {
                    const path = generatePath(ROUTE_PATH.DOMAIN_AUDIT_DETAIL, {
                      id: dataReport.auditId,
                    });
                    const result = dataNavigate.find(d => d.keyFilter === v);
                    if (!!result) {
                      const newWindow = window.open(
                        `${path}?${result.navigateFilter?.toLocaleLowerCase()}`,
                        "_blank",
                        "noopener,noreferrer"
                      );
                      if (newWindow) newWindow.opener = null;
                    }
                  }
                }}
              />
            </div>
          ) : (
            <div className="overview-nodata flex-column-center">
              <NoDataBox title="No data" hasImage />
            </div>
          )}
        </>
      )}
    </TechnicalOverviewChartWrapper>
  );
};

const TechnicalOverviewIssueBox = (props: {
  data: {
    title?: string;
    list?: IIssueListData[];
    children?: { title?: string; list: IIssueListData[] }[];
  }[];
  onClickIssue: (key: string) => void;
}) => {
  const { data, onClickIssue } = props;
  return (
    <>
      {data.map((item, idx) => (
        <div
          key={idx}
          className={`issue-list-wrapper ${!!item.title ? "list-have-title" : ""}`}
        >
          {item.title && <p className="issue-title">{item.title}</p>}
          {!!item.list && item.list.length > 0 && (
            <ul className="issue-list">
              {item.list.map(issue => (
                <li key={issue.title} className="flex-between-align-center">
                  <div className="issue-content flex-start-align-center">
                    <ImageCustom
                      src={
                        issue.type === EAuditErrorStatus.Error
                          ? IconError
                          : issue.type === EAuditErrorStatus.Warning
                            ? IconWarning
                            : issue.type === EAuditErrorStatus.Success
                              ? IconSuccess
                              : IconInforBlue
                      }
                      alt="Icon-Issue"
                      width={"22px"}
                      height={"22px"}
                      className="content-type-icon"
                    />
                    <p className="issue-subtitle fit-one-line">{issue.title}</p>
                    <Tooltip title={issue.infor} placement="top-start">
                      <div>
                        <ImageCustom
                          src={IconInfor}
                          alt="Icon-Infor"
                          width={"16px"}
                          height={"16px"}
                        />
                      </div>
                    </Tooltip>
                  </div>
                  <div
                    className={`issue-action ${!issue.key ? "no-key" : ""}  flex-end-align-center`}
                  >
                    {!!issue.issueCount && (
                      <button
                        onClick={() => {
                          if (!!issue.key) {
                            onClickIssue(issue.key);
                          }
                        }}
                      >
                        {issue.issueCount} vấn đề
                      </button>
                    )}
                    {!!issue.recommendAction && <Tooltip
                      title={issue.recommendAction}
                      placement="top-start"
                    >
                      <p className="action-recommend">Recommend action</p>
                    </Tooltip>}

                  </div>
                </li>
              ))}
            </ul>
          )}
          {!!item.children && item.children.length > 0 && (
            <>
              {item.children.map((item2, idx2) => (
                <div className="issue-child-list" key={idx2}>
                  <ul className="issue-list">
                    {!!item2.title && (
                      <p className="issue-detail-title">{item2.title}</p>
                    )}

                    {item2.list.map(item2 => (
                      <li
                        key={item2.title}
                        className="flex-between-align-center"
                      >
                        <div className="issue-content flex-start-align-center">
                          <ImageCustom
                            src={
                              item2.type === EAuditErrorStatus.Error
                                ? IconError
                                : item2.type === EAuditErrorStatus.Warning
                                  ? IconWarning
                                  : item2.type === EAuditErrorStatus.Success
                                    ? IconSuccess
                                    : IconInforBlue
                            }
                            alt="Icon-Issue"
                            width={"22px"}
                            height={"22px"}
                            className="content-type-icon"
                          />
                          <p className="issue-subtitle fit-one-line">
                            {item2.title}
                          </p>
                          <Tooltip title={item2.infor} placement="top-start">
                            <div>
                              <ImageCustom
                                src={IconInfor}
                                alt="Icon-Infor"
                                width={"16px"}
                                height={"16px"}
                              />
                            </div>
                          </Tooltip>
                        </div>
                        <div className="issue-action  flex-end-align-center">
                          {!!item2.issueCount && (
                            <button
                              onClick={() => {
                                if (!!item2.key) {
                                  onClickIssue(item2.key);
                                }
                              }}
                            >
                              {item2.issueCount} vấn đề
                            </button>
                          )}
                          <Tooltip
                            title={item2.recommendAction}
                            placement="top-start"
                          >
                            <p className="action-recommend">Recommend action</p>
                          </Tooltip>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </>
          )}
        </div>
      ))}
    </>
  );
};
