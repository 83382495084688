import { styled } from "@mui/material/styles";
import variableStyles from "../../../../../theme/variable-styles";

export const SecuritySectionWrapper = styled("div")(() => ({
  "& .security-title": {
    padding: "60px 40px 24px",
  },
  "& .security-wrapper": {
    padding: "20px 40px 40px",
  },
  "& .security-index-wrapper": {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    background:
      "linear-gradient(180deg, rgba(239, 250, 245, 0) 0%, #EFFAF5 100%)",
    borderRadius: 12,
    padding: "30px",
    "& .security-index-box": {
      backgroundColor: variableStyles.NaturalColor0,
      border: `1px solid ${variableStyles.NaturalColor200}`,
      borderRadius: "6px",
      paddingBottom: "47px",
      boxShadow:
        "0px 39px 67.8px 0px #1956580A,0px 21px 37px -15px #1956580F,0px 1px 1px 0px #00000014",
      "& .index-box-caption": {},
      "& .MuiDivider-root": {
        borderColor: variableStyles.NaturalColor200,
      },
    },
  },
  "& .performance-on-strategy-wrapper": {
    padding: "40px 37px",
    position: "relative",
    background: variableStyles.NaturalColor50,
    borderWidth: " 1px 0px 1px 0px",
    borderStyle: "solid",
    borderColor: variableStyles.NaturalColor200,
    "& .createdat": {
      position: "absolute",
      top: 35,
      right: 40,
      border: `1px solid ${variableStyles.NaturalColor100}`,
      background: variableStyles.NaturalColor100,
      borderRadius: "8px",
      padding: "4px 10px",
    },
  },
  "& .error-crawl-page-wrapper": {
    padding: '40px',
    display: 'flex',
    flexDirection: "column",
    gap: '40px',
    "& .box-wrapper": {
      "& .box-header": {
        gap: '24px',
        marginBottom: "8px",
        "& .header-status": {
          borderRadius: "100px",
          display: "flex",
          gap: "6px",
          alignItems: "center",
          padding: "4px 4px 4px 12px",
          backgroundColor: variableStyles.NaturalColor100,
          border: `1px solid ${variableStyles.NaturalColor200}`,
          "& .status-text": {
            color: variableStyles.NaturalColor1000,
            fontWeight: variableStyles.fwMedium,
            fontSize: "12px",
            lineHeight: "12px",
          },
          "& .status-content": {
            padding: "3.5px 8px",
            borderRadius: "100px",
            color: variableStyles.NaturalColor0,
            fontWeight: variableStyles.fwSemiBold,
            fontSize: "14px",
            lineHeight: "16.94px",
          },
          "&.passed": {
            "& .status-content": {
              backgroundColor: variableStyles.Success500,
            },
          },
          "&.failed": {
            "& .status-content": {
              backgroundColor: variableStyles.Error500,
            },
          },
        }
      },
      "& .box-description": {
        color: variableStyles.NaturalColor1000,
        fontWeight: variableStyles.fwRegular,
        fontSize: "12px",
        lineHeight: "18px",
        padding: "8px 12px",
        backgroundColor: variableStyles.Info50,
        borderRadius: "8px",

      },
      "& .list-box": {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px', marginTop: '16px'
      },
      "& .crawl-link-list": {
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
        margin: '0 12px 12px',
        padding: "8.5px 12px",
        backgroundColor: variableStyles.NaturalColor100,
        border: `1px solid ${variableStyles.NaturalColor200}`,
        borderRadius: "8px",
        "& a": {
          color: variableStyles.blueSecondary700,
          fontWeight: variableStyles.fwMedium,
          fontSize: "11px",
          lineHeight: "16.5px",
          "&:hover": {
            textDecoration: 'underline',
          }
        },
        "& .crawl-text": {
          color: variableStyles.NaturalColor1000,
          fontWeight: variableStyles.fwMedium,
          fontSize: "11px",
          lineHeight: "16.5px",
        }
      }
    }
  }
}));
