import { Collapse, Typography } from "@mui/material";
import AuditAccordion from "../AuditAccordion";
import {
  AuditRef,
  Audits,
  StackPacks,
} from "../../../../../../../../../models/google/google.page.speed";
import {
  getAuditStatus,
  getLevelByStatus,
} from "../../../../../../../../../common/handles/common.handles";
import { ImageCustom } from "../../../../../../../../../components";
import IconArrowDown from "../../../../../../../../../assets/images/common/icon_arrow_down_line_black.svg";

interface IProps {
  groupTitle: string;
  onShow: () => void;
  isShow: boolean;
  data: AuditRef[];
  audits: Audits;
  stackPacks: StackPacks[];
}

export default function OtherGroupAudit({
  groupTitle,
  isShow,
  onShow,
  data,
  audits,
  stackPacks,
}: IProps) {
  const sortData = data.sort((a, b) => {
    if (audits) {
      const auditA = audits[a.id];
      const auditB = audits[b.id];
      const statusA = getLevelByStatus(getAuditStatus(auditA, a.weight));
      const statusB = getLevelByStatus(getAuditStatus(auditB, b.weight));

      return statusB - statusA;
    }

    return b.weight - a.weight;
  });

  return (
    <div className="group-audit-wrapper group-audit-other-wrapper">
      <div
        className="group-audit-title flex-between-align-center"
        onClick={onShow}
      >
        <Typography variant="subtitle2">{groupTitle}</Typography>
        <ImageCustom
          src={IconArrowDown}
          alt="Icon-Arrow-Down"
          width={"22px"}
          height={"22px"}
          className={`header-arrow ${isShow ? "active" : ""}`}
        />
      </div>
      <Collapse in={isShow}>
        <div className="audit-accordion-list">
          {sortData.map(item => {
            const audit = audits ? audits[item.id] : null;

            return audit ? (
              <AuditAccordion
                data={audit}
                key={item.id}
                stackPacks={stackPacks}
                weight={item.weight}
              />
            ) : null;
          })}
        </div>
      </Collapse>
    </div>
  );
}
