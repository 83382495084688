import React, { forwardRef } from "react";
import { EAuditErrorStatus } from "../../../../../models/common/models.enum";
import ClassifyTabs from "../../ClassifyTabs";
import OverviewCommonTitle from "../../OverviewCommonTitle";
import { OptimizeSectionWrapper } from "./styles";

const dataTabsTemp: {
  typeError: EAuditErrorStatus;
  title: string;
  dataArray?: React.ReactNode;
  recommendAction: any;
  infor?: string;
}[] = [
  {
    typeError: EAuditErrorStatus.Success,
    title: "Form liên hệ",
    infor:
      "Biểu mẫu là nơi cho phép người dùng nhập các thông tin cần thiết nhằm thực hiện các hoạt động khảo sát, liên hệ tư vấn, nhận thông tin hoặc đặt hàng trên website.",
    recommendAction: (
      <ul>
        <li>
          Chỉ yêu cầu người dùng điền vào những trường thông tin thực sự cần
          thiết.
        </li>
        <li>
          Hiển thị thông báo lỗi rõ ràng và cụ thể khi người dùng nhập sai dữ
          liệu.
        </li>
        <li>
          Các trường nhập liệu phải đủ lớn để người dùng dễ dàng chạm vào trên
          thiết bị di động.
        </li>
      </ul>
    ),
  },
  {
    typeError: EAuditErrorStatus.Success,
    title: "Số điện thoại",
    infor:
      "Việc có số điện thoại trên website là một yếu tố quan trọng giúp khách hàng dễ dàng liên hệ khi cần tư vấn, giải đáp thắc mắc, hoặc liên hệ đặt hàng một cách nhanh chóng, từ đó tăng tỷ lệ chuyển đổi và xây dựng hình ảnh chuyên nghiệp cho doanh nghiệp.",
    recommendAction: (
      <ul>
        <li>
          Số điện thoại cần được thiết kế nổi bật giúp thu hút sự chú ý của
          khách hàng.
        </li>
        <li>
          Đặt số điện thoại ở vị trí dễ thấy nhất như phần đầu trang hoặc vị trí
          góc dưới bên phải màn hình.
        </li>
        <li>
          Số điện thoại phải chính xác và luôn hoạt động để khách hàng dễ dàng
          liên hệ.
        </li>
      </ul>
    ),
  },
  {
    typeError: EAuditErrorStatus.Success,
    title: "Lời kêu gọi hành động",
    infor:
      "Lời kêu gọi hành động giúp thúc đẩy người dùng thực hiện một hành động cụ thể trên website như liên hệ tư vấn, đặt lịch hẹn hoặc mua hàng,...Ngoài ra việc đo số lượng người bấm vào các nút kêu gọi hành động cũng giúp đánh giá hiệu quả chiến lược Marketing của doang nghiệp.",
    recommendAction: (
      <ul>
        <li>
          Cần tạo sự cấp bách: Sử dụng những từ như "Hạn chót", "Chỉ còn vài
          ngày", "Số lượng có hạn".Màu sắc nổi bật: Sử dụng màu sắc tương phản
          để thu hút sự chú ý.Kích thước phù hợp: Nút CTA phải đủ lớn để người
          dùng dễ dàng nhấp vào trên Sử dụng những từ như "Hạn chót", "Chỉ còn
          vài ngày", "Số lượng có hạn".
        </li>
        <li>
          Màu sắc nổi bật: Sử dụng màu sắc tương phản để thu hút sự chú ý.
        </li>
        <li>
          Kích thước phù hợp: Nút CTA phải đủ lớn để người dùng dễ dàng nhấp vào
          trên thiết bị di động.
        </li>
      </ul>
    ),
  },
  {
    typeError: EAuditErrorStatus.Error,
    title: "Chức năng trò chuyện trực tuyến",
    infor:
      "Thông qua trò chuyện trực tuyến, bộ phận chăm sóc khách hàng có thể tương tác, cung cấp thêm thông tin, giải đáp các thắc mắc khách hàng một cách nhanh chóng, kịp thời từ đó thúc đẩy tỉ lệ chuyển đổi.",
    recommendAction: (
      <ul>
        <li>
          Nút chatbot nên được đặt ở vị trí dễ nhìn thấy trên trang web, chẳng
          hạn như góc dưới bên phải.
        </li>
        <li>
          Chatbot cần trả lời các câu hỏi của khách hàng một cách nhanh chóng và
          chính xác. Với các câu hỏi mang tính cá nhân hóa, chatbot cần có khả
          năng chuyển cuộc trò chuyện đến nhân viên hỗ trợ.
        </li>
      </ul>
    ),
  },
  {
    typeError: EAuditErrorStatus.Success,
    title: "Nút chia sẻ mạng xã hội",
    infor:
      "Khi người dùng chia sẻ nội dung website lên các nền tảng mạng xã hội, điều đó đồng nghĩa với việc doanh nghiệp đang mở rộng tầm ảnh hưởng của mình và thu hút thêm nhiều khách hàng tiềm năng biết đến sản phẩm, dịch vụ và thương hiệu của mình.",
    recommendAction: (
      <ul>
        <li>Thiết kế nút chia sẻ nổi bật.</li>
        <li>
          Đặt nút tại các vị trí thuận tiện, dễ thấy như cuối bài hoặc bên cạnh
          tiêu đề bài viết.
        </li>
        <li>
          Kết hợp tạo các nội dung hấp dẫn, thú vị sẽ giúp tăng tỉ lệ các nội
          dung được chia sẻm tương tác.
        </li>
      </ul>
    ),
  },
  {
    typeError: EAuditErrorStatus.Error,
    title: "Review khách hàng",
    infor:
      "Review của khách hàng thực tế giúp tăng độ tin cậy của sản phẩm/dịch vụ. Khi thấy nhiều người khác hài lòng và đánh giá cao, khách hàng tiềm năng sẽ cảm thấy yên tâm hơn khi quyết định mua hàng.",
    recommendAction: (
      <ul>
        <li>
          Đặt phần review khách hàng tại cuối các trang quan trọng như trang chủ
          và các trang sản phẩm/ dịch vụ.
        </li>
        <li>
          Cung cấp thông tin người review một cách rõ ràng, minh bạch: tên, ảnh
          đại diện, chức vụ/ vai trò (đơn vị, công ty), nội dung review.
        </li>
      </ul>
    ),
  },
];

const OptimizeSection = forwardRef<any>(function (props, ref) {
  return (
    <OptimizeSectionWrapper
      className="common-layout"
      ref={ref}
      id={"optimize-section"}
    >
      <OverviewCommonTitle
        title="Tối ưu chuyển đổi"
        description="Tối ưu hóa chuyển đổi việc cải thiện các yếu tố trên trang web nhằm tăng tỷ lệ người dùng thực hiện hành động mong muốn như mua hàng, đăng ký, điền vào form, nhấp vào một liên kết cụ thể, v.v."
        className="optimize-title"
        scores={80}
      />
      <ClassifyTabs data={dataTabsTemp} onChangeTab={() => {}} />
    </OptimizeSectionWrapper>
  );
});
export default OptimizeSection;
