import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ControlForm } from "..";
import InputForm from "../controls/InputForm";
import { FormCheckLinkWrapper } from "./styles";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SelectCustom from "../SelectCustom";
import { Button } from "@mui/material";
import { ETypeLinkCheck } from "../../models/common/models.enum";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../common/constants/app.constant";
import queryString from "query-string";

interface FormCheckLinkProps {
  title: string;
  description: string;
  link: string;
}

export default function FormCheckLink(props: FormCheckLinkProps) {
  const { title, description, link } = props;
  const navigate = useNavigate();
  const [typeLink, setTypeLink] = useState<ETypeLinkCheck>(
    ETypeLinkCheck.RootDomain
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const rexgexPage =
    /^(https?:\/\/)?(([a-zd]([a-zd-]*[a-zd])*).)+[a-z]{2,}?(\/[-a-zd%_.~+]+)/;

  const validationDomainCompetitiveSchema = Yup.object().shape({
    target: Yup.string()
      .matches(rexgexPage, "Is not in correct format")
      .required(`${"Please enter your domain"}`),
  }) as any;

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      target: "",
    },
    resolver: yupResolver(validationDomainCompetitiveSchema),
  });

  const onSubmit = async (value: any) => {
    const path = generatePath(link);
    const newParams = queryString.stringify({ ...value, type: typeLink });
    navigate({
      pathname: `${path}`,
      search: `${newParams}`,
    });
  };

  return (
    <FormCheckLinkWrapper>
      <p className="form-check-title">{title}</p>
      <p className="form-check-description">{description}</p>
      <div className="form-wrapper">
        <div className="input-wrapper flex-start-align-top">
          <ControlForm
            title="Nhập đường dẫn"
            isRequired
            classname="control-form-link"
          >
            <InputForm
              placeholder="https://..."
              required
              name={"target"}
              errors={errors}
              control={control}
              size="small"
              isPasteLink
            />
          </ControlForm>
          <ControlForm title="Loại" isRequired classname="control-form-type">
            <SelectCustom
              options={[
                { title: "Root domain", value: ETypeLinkCheck.RootDomain },
                { title: "Exact url", value: ETypeLinkCheck.ExactUrl },
              ]}
              onChange={event => {
                setTypeLink(event.target.value);
              }}
              placeholder="Chọn loại"
              isSelectedIcon
              value={typeLink}
            />
          </ControlForm>
        </div>
        <Button
          size="xsmall"
          variant="contained"
          className="button-submit"
          onClick={handleSubmit(onSubmit)}
        >
          {isLoading && <span className="loader"></span>}
          <p className={`text-submit ${isLoading ? "is-loading" : ""}`}>
            Kiểm tra
          </p>
        </Button>
      </div>
    </FormCheckLinkWrapper>
  );
}
