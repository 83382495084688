import { useState, useEffect, useRef } from "react";
import { Button } from "@mui/material";
import {
  ControlForm,
  ImageCustom,
  LoadingIndicator,
  Modal,
  NoSearchDataBox,
  TableDataGrey,
} from "../../components";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputForm from "../../components/controls/InputForm";
import { Helmet } from "react-helmet";
import Title from "../../components/Title";
import { OutlineWrapper } from "./styles";
import { ITableHeader } from "../../components/Table/TableData/TableData";
import IconAdd from "../../assets/images/common/icon_plus_line_white.svg";
import IconFile from "../../assets/images/common/icon_file_fill_green.png";
import OutlineService from "../../services/api/outline.service";
import {
  IBasePagingReq,
  IBasePagingRes,
} from "../../models/common/models.type";
import { IGetOutlineProjectsRes } from "../../models/outline";
import { DEFAULT_BASE_PAGING_RES } from "../../common/constants/value.constant";
import CommonHandle from "../../common/handles/common.handles";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../common/constants/app.constant";
import { toggleMessage } from "../../components/Toast/Toast";
import { toggleConfirmModal } from "../../components/ConfirmModal/ConfirmModal";
import IconSearch from "../../assets/images/common/icon_search_line_blue.svg";
import InputSearch from "../../components/controls/InputSearch";

const Outline = () => {
  const timerOut = useRef<number>();
  const navigate = useNavigate();

  const [openCreatePJ, setOpenCreatePJ] = useState<boolean>(false);
  const [dataEditPJ, setDataEditPJ] = useState<{
    name: string;
    id: string;
  } | null>(null);
  const [params, setParams] = useState<IBasePagingReq>({
    pageIndex: 1,
    pageSize: 10,
  });
  const [listProjects, setListProjects] = useState<
    IBasePagingRes<IGetOutlineProjectsRes>
  >({ ...DEFAULT_BASE_PAGING_RES });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [inputSearch, setInputSearch] = useState<string>("");

  useEffect(() => {
    getProjectsList();
  }, [params]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`${"Please enter project name"}`),
  }) as any;

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      name: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const onChangeSearch = (e: any) => {
    const value = e?.target?.value;
    if (timerOut.current) {
      clearTimeout(timerOut.current);
    }
    timerOut.current = window.setTimeout(() => {
      setParams(_ => ({ pageIndex: 1, pageSize: 10, keyword: value }));
    }, 700);
  };

  const headersTable: ITableHeader<IGetOutlineProjectsRes>[] = [
    {
      field: "name",
      title: `Tên dự án`,
      width: "200px",
      handleItem: item => {
        return (
          <p className="table-name-project">
            <ImageCustom
              src={IconFile}
              alt="Icon-File"
              width={"20px"}
              height={"20px"}
            />
            <span className="fit-one-line">{item.name}</span>
          </p>
        );
      },
    },
    {
      field: "createdAt",
      title: `Ngày tạo`,
      width: "200px",
      handleItem: item => {
        return CommonHandle.formatDate(item.createdAt);
      },
    },
    {
      field: "numberOfPosts",
      title: `Số lượng bài viết`,
      width: "200px",
    },
  ];

  const getProjectsList = async () => {
    setIsLoading(true);
    const res = await OutlineService.getListProject(params);
    if (res.success) {
      setListProjects(res.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const onSubmit = async (value: { name: string }) => {
    if (!dataEditPJ) {
      const res = await OutlineService.createProject(value);
      if (res.success) {
        toggleMessage({
          type: "success",
          message: "Tạo thành công tên dự án",
          title: "Tạo sửa thành công",
        });
        getProjectsList();
        handleCloseCreatePJ();
      } else {
        toggleMessage({
          type: "error",
          message: res.message ?? "",
          title: "Error Occurred",
        });
      }
    } else if (!!dataEditPJ) {
      const res = await OutlineService.updateProject({
        name: value.name,
        id: dataEditPJ.id,
      });
      if (res.success) {
        toggleMessage({
          type: "success",
          message: "Chỉnh sửa thành công tên dự án",
          title: "Chỉnh sửa thành công",
        });
        getProjectsList();
        handleCloseCreatePJ();
      } else {
        toggleMessage({
          type: "error",
          message: res.message ?? "",
          title: "Error Occurred",
        });
      }
    }
  };

  const asyncFuncDelete: (id: string) => Promise<void> = async (id: string) => {
    const res = await OutlineService.deleteProject(id);
    if (res.success) {
      toggleMessage({
        type: "success",
        message: "Xoá thành công dự án",
        title: "Xoá thành công",
      });
      getProjectsList();
    } else {
      toggleMessage({
        type: "error",
        message: "Xoá dự án không thành công",
        title: "Xoá không thành công",
      });
    }
  };

  const handleCloseCreatePJ = () => {
    reset();
    setOpenCreatePJ(false);
  };

  const clearSearch = () => {
    setParams(prev => ({
      ...prev,
      pageIndex: 1,
      pageSize: 10,
      keyword: "",
    }));
    setInputSearch("");
  };

  return (
    <>
      <Modal
        title={!!dataEditPJ ? "Update Project" : "Create new Project"}
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  eiusmo"
        open={openCreatePJ}
        onSubmit={handleSubmit(onSubmit)}
        onClose={handleCloseCreatePJ}
        textSubmit={!!dataEditPJ ? "Update Project" : "Create Project"}
      >
        <form style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <ControlForm
            title="Name"
            infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
            classname="brand-form"
          >
            <InputForm
              placeholder="Your project name"
              required
              name="name"
              errors={errors}
              control={control}
              size="small"
            />
          </ControlForm>
        </form>
      </Modal>
      <OutlineWrapper>
        <Helmet>
          <title>Outline</title>
        </Helmet>
        <div className="outline-header">
          <Title normalTitle="Danh sách dự án" />
          <div className="header-action">
            <InputSearch
              placeholder={"Tìm kiếm"}
              onChange={e => {
                onChangeSearch(e);
                setInputSearch(e.target.value);
              }}
              size={"medium"}
              width={"198px"}
              typeStyle="outlined"
            />
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={_ => setOpenCreatePJ(true)}
              className="add-button"
            >
              <ImageCustom
                src={IconAdd}
                alt="Icon-Add"
                width={"18px"}
                height={"18px"}
              />
              <p>Tạo dự án</p>
            </Button>
          </div>
        </div>
        {isLoading ? (
          <LoadingIndicator className="loading-indicator" />
        ) : (
          <>
            {listProjects.items.length > 0 ? (
              <TableDataGrey
                data={listProjects}
                headersTable={headersTable}
                handleChangeSearchParams={(v: any) => {}}
                handleDeleteData={(id: string) => {
                  toggleConfirmModal({
                    title: `Xoá dự án`,
                    content: `Bạn có chắc chắc muốn xoá dự án này không?`,
                    onSubmit: () => asyncFuncDelete(id.toString()),
                    submitText: "Xoá",
                    type: "warning-red",
                  });
                }}
                handleEditData={(id: string) => {
                  const dataAction = listProjects.items.find(
                    item => item.id.toString() === id
                  );
                  if (!!dataAction) {
                    setDataEditPJ({
                      name: dataAction.name,
                      id: dataAction.id.toString(),
                    });
                    setValue("name", dataAction.name);
                    setOpenCreatePJ(true);
                  }
                }}
                handleOpenDetail={(id: string) => {
                  const path = generatePath(ROUTE_PATH.OUTLINE_POSTS, {
                    project_id: id,
                  });
                  navigate(path);
                }}
              />
            ) : (
              <NoSearchDataBox
                inputSearch={inputSearch}
                onClearSearch={clearSearch}
                openCreateModal={() => setOpenCreatePJ(true)}
                openText="Tạo dự án"
              />
            )}
          </>
        )}
      </OutlineWrapper>
    </>
  );
};

export default Outline;
