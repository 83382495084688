import React from "react";
import { ROUTE_PATH } from "../../../common/constants/app.constant";
import CommonLayout from "../components/CommonLayout";

export default function Backlink() {
  return (
    <div>
      <CommonLayout
        title="Backlink"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  eiusmo"
        formTitle="Backlink"
        formDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        link={ROUTE_PATH.BACKLINK_CHECK}
      />
    </div>
  );
}
