import React, { useState } from "react";
import { CollapseHandleError, ImageCustom } from "../../../../components";
import { ClassifyTabsWrapper } from "./styles";
import IconCheck from "../../../../assets/images/common/icon_check_circle_fill_green.svg";
import IconNotCheck from "../../../../assets/images/common/icon_close_circle_fill_red.svg";
import IconInfor from "../../../../assets/images/common/icon_infor_line_grey.svg";
import { Tooltip } from "@mui/material";
import { EAuditErrorStatus } from "../../../../models/common/models.enum";

interface ClassifyTabsProps {
  data?: {
    typeError: EAuditErrorStatus;
    title: string;
    dataArray?: React.ReactNode;
    recommendAction: any;
    infor?: string;
  }[];
  onChangeTab: (value: number) => void;
}

const tabsData = [
  { title: "Tất cả", value: 0 },
  { title: "High", value: 1 },
  { title: "Low", value: 2 },
];

export default function ClassifyTabs(props: ClassifyTabsProps) {
  const { data, onChangeTab } = props;
  const [activeTab, setActiveTab] = useState<number>(0);

  return (
    <ClassifyTabsWrapper>
      <ul className="tabs-header flex-start-align-center">
        {tabsData.map(tab => (
          <li
            key={tab.value}
            className={`${activeTab === tab.value ? "active" : ""}`}
          >
            <button
              onClick={() => {
                setActiveTab(tab.value);
                onChangeTab(tab.value);
              }}
            >
              {tab.title}
            </button>
          </li>
        ))}
      </ul>
      {!!data && data.length > 0 && (
        <ul className="tab-content-wrapper">
          {data
            .filter(itemData => {
              if (activeTab === 1) {
                if (itemData.typeError === EAuditErrorStatus.Success) {
                  return itemData;
                }
              } else if (activeTab === 2) {
                if (itemData.typeError === EAuditErrorStatus.Error) {
                  return itemData;
                }
              } else {
                return itemData;
              }
            })
            .map(item => (
              <CollapseHandleError
                typeError={item.typeError}
                title={item.title}
                type={"crawl"}
                dataArray={item.dataArray}
                infor={item.infor}
                recommendAction={item.recommendAction}
              />
            ))}
        </ul>
      )}
    </ClassifyTabsWrapper>
  );
}
