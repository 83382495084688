import { styled } from "@mui/material/styles";
import variableStyles from "../../../../theme/variable-styles";

export const ClassifyTabsWrapper = styled("div")(({}) => ({
  "& .tabs-header": {
    gap: "18px",
    borderBottom: `1px solid ${variableStyles.NaturalColor200}`,
    "& li": {
      "& button": {
        color: variableStyles.NaturalColor600,
        fontWeight: variableStyles.fwMedium,
        fontSize: "12px",
        lineHeight: "12px",
        backgroundColor: "transparent",
        padding: "12px 8px",
        position: "relative",
        transition: "all .2s linear",
        overflow: "hidden",
        marginBottom: "-1px",
        "&:hover": {
          color: variableStyles.NaturalColor1000,
        },
        "&::after": {
          content: '""',
          width: "100%",
          height: "2px",
          backgroundColor: variableStyles.blueSecondary600,
          position: "absolute",
          bottom: "0",
          left: "-100%",
          transition: "all .2s linear",
        },
      },
      "&.active": {
        "& button": {
          color: variableStyles.NaturalColor1000,
          "&::after": {
            left: "0",
          },
        },
      },
    },
  },
  "& .tab-content-wrapper": {
    paddingTop: "12px",
    overflow: "auto",
    minWidth: "400px",
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    "& li": {
      gap: "12px",
      padding: "16px 12px",
      backgroundColor: variableStyles.NaturalColor0,
      "&:hover": { backgroundColor: variableStyles.NaturalColor100 },
      "& .content-box": {
        gap: "12px",
        "& .content-title-box": {
          gap: "4px",
          "& .content-title": {
            color: variableStyles.NaturalColor1000,
            fontWeight: variableStyles.fwMedium,
            fontSize: "12px",
            lineHeight: "18px",
          },
        },
        "& .content-detail-button": {
          color: variableStyles.blueSecondary800,
          fontWeight: variableStyles.fwRegular,
          fontSize: "12px",
          lineHeight: "20px",
          backgroundColor: "transparent",
        },
      },
      "& .action-box": {
        gap: "24px",
        flex: "0 0 auto",
        "& .action-recommend": {
          color: variableStyles.blueSecondary800,
          fontWeight: variableStyles.fwRegular,
          fontSize: "12px",
          lineHeight: "20px",
          backgroundColor: "transparent",
        },
        "& .action-tab-status": {
          fontWeight: variableStyles.fwMedium,
          fontSize: "10px",
          lineHeight: "15px",
          borderRadius: "100px",
          padding: "4px 8px",
          border: "1px solid",
          backgroundColor: variableStyles.NaturalColor0,
          "&.high": { color: variableStyles.Success500 },
          "&.low": { color: variableStyles.Error500 },
        },
      },
    },
  },
}));
