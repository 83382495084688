import { Popover } from "@mui/material";
import { styled } from "@mui/material/styles";
import variableStyles from "../../../../../../../theme/variable-styles";

export const GoogleMyBusinessProfileWrapper = styled("div")(() => ({
  background:
    "linear-gradient(0deg, rgba(121, 226, 249, 0.2), rgba(121, 226, 249, 0.2)),linear-gradient(0deg, rgba(241, 245, 246, 0.3), rgba(241, 245, 246, 0.3))",
  padding: "40px",
  "& .profile-title": {
    backgroundColor: variableStyles.NaturalColor0,
    width: "max-content",
    borderRadius: "12px",
    height: "45px",
    gap: "14px",
    padding: "6px  12px",
    marginBottom: "16px",
    "& p": {
      color: variableStyles.NaturalColor1000,
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "20px",
      lineHeight: "30px",
    },
  },
  "& .profile-description": {
    color: variableStyles.NaturalColor950,
    fontWeight: variableStyles.fwRegular,
    fontSize: "12px",
    lineHeight: "18px",
    marginBottom: "18px",
    maxWidth: "520px",
  },
  "& .profile-analysis-wrapper": {
    "& .analysis-tabs": {
      gap: "18px",
      borderBottom: `1px solid ${variableStyles.NaturalColor200}`,
      marginBottom: "16px",
      "& li": {
        "& button": {
          backgroundColor: "transparent",
          color: variableStyles.NaturalColor600,
          fontWeight: variableStyles.fwMedium,
          fontSize: "12px",
          lineHeight: "12px",
          padding: "12px 8px",
          position: "relative",
          overflow: "hidden",
          marginBottom: "-1px",
          transition: "all .2s linear",
          "&::after": {
            content: '""',
            width: "100%",
            height: "2px",
            backgroundColor: variableStyles.blueSecondary600,
            position: "absolute",
            bottom: "0",
            left: "-100%",
            transition: "all .2s linear",
          },
        },
        "&.active": {
          "& button": {
            color: variableStyles.NaturalColor1000,
            "&::after": {
              left: "0",
            },
          },
        },
      },
    },
    "& .analysis-list": {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
      "& li.list-item": {
        backgroundColor: variableStyles.NaturalColor0,
        border: `1px solid ${variableStyles.NaturalColor100}`,
        borderRadius: "12px",
        padding: "20px",
        boxShadow: "0px 2px 3.5px 0px #0000000A",
        "& .analysis-header": {
          marginBottom: "20px",
          "& .header-infor": {
            "& .infor-title": {
              color: variableStyles.blueSecondary900,
              fontWeight: variableStyles.fwSemiBold,
              fontSize: "14px",
              lineHeight: "16px",
            },
            "& p.infor-description": {
              color: variableStyles.NaturalColor950,
              fontWeight: variableStyles.fwMedium,
              fontSize: "12px",
              lineHeight: "15px",
              marginTop: "8px",
            },
            "& ul.infor-description": {
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              marginTop: "8px",
              "& p": {
                color: variableStyles.NaturalColor950,
                fontWeight: variableStyles.fwMedium,
                fontSize: "12px",
                lineHeight: "12px",
              },
              "& li": {
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                "& p": {
                  color: variableStyles.NaturalColor1000,
                  fontSize: "12px",
                  lineHeight: "14.06px",
                  "&.date-text": {
                    fontWeight: variableStyles.fwMedium,
                    textTransform: "capitalize",
                  },
                  "&.time-text": { fontWeight: variableStyles.fwRegular },
                },
              },
            },
          },
          "& .header-status": {
            borderRadius: "100px",
            display: "flex",
            gap: "6px",
            alignItems: "center",
            padding: "4px 4px 4px 12px",
            backgroundColor: variableStyles.NaturalColor100,
            border: `1px solid ${variableStyles.NaturalColor200}`,
            "& .status-text": {
              color: variableStyles.NaturalColor1000,
              fontWeight: variableStyles.fwMedium,
              fontSize: "12px",
              lineHeight: "12px",
            },
            "& .status-content": {
              padding: "3.5px 8px",
              borderRadius: "100px",
              color: variableStyles.NaturalColor0,
              fontWeight: variableStyles.fwSemiBold,
              fontSize: "14px",
              lineHeight: "16.94px",
            },
            "&.passed": {
              "& .status-content": {
                backgroundColor: variableStyles.Success500,
              },
            },
            "&.failed": {
              "& .status-content": {
                backgroundColor: variableStyles.Error500,
              },
            },
          },
        },
        "& .analysis-comment": {
          backgroundColor: variableStyles.NaturalColor100,
          padding: "12px",
          borderRadius: "12px",
          border: `1px solid ${variableStyles.NaturalColor200}`,
          "& .comment-title": {
            color: variableStyles.NaturalColor1000,
            fontWeight: variableStyles.fwSemiBold,
            fontSize: "13px",
            lineHeight: "19.5px",
            marginBottom: "6px",
          },
          "& .comment-detail": {
            color: variableStyles.NaturalColor950,
            fontWeight: variableStyles.fwRegular,
            fontSize: "12px",
            lineHeight: "18px",
          },
        },
      },
    },
  },
}));
