import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const IndexNoPercentWrapper = styled("div")(() => ({
  textAlign: "center",
  border: `1px solid ${variableStyles.NaturalColor300}`,
  borderRadius: "6px",
  boxShadow: "0px 0px 18.3px 0px #0000000A",
  height: "110px",
  display: "flex",
  flexDirection: "column",
  "& .box-header": {
    color: variableStyles.NaturalColor1000,
    fontWeight: variableStyles.fwSemiBold,
    fontSize: "12px",
    lineHeight: "14px",
    gap: "4px",
    backgroundColor: variableStyles.NaturalColor100,
    padding: "12px",
    borderBottom: `1px solid ${variableStyles.NaturalColor300}`,
    borderRadius: "6px 6px 0 0",
  },
  "& .box-number": {
    flex: "1 1 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: variableStyles.Info800,
    fontWeight: variableStyles.fwSemiBold,
    fontSize: "24px",
    lineHeight: "25.55px",
  },
}));
